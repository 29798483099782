import type { CustomRouteObjectUnion } from '@/types/router';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { Link, useMatches } from 'react-router-dom';

export const BreadcrumbNav = () => {
  const { t } = useIntl();

  const matches = useMatches() as (ReturnType<typeof useMatches>[number] & {
    handle?: CustomRouteObjectUnion['handle'];
  })[];

  const breadcrumbs = matches
    .filter((match) => Boolean(match.handle?.breadcrumb))
    .map((match, index, filteredMatches) => {
      const label =
        (typeof match.handle?.breadcrumb === 'function' ? match.handle.breadcrumb(match) : match.handle?.breadcrumb) ||
        '';

      const isLastItem = index === filteredMatches.length - 1;
      const nextMatch = filteredMatches[index + 1];
      const showSeparator = !isLastItem && nextMatch && nextMatch.pathname !== match.pathname;

      return (
        <BreadcrumbItem key={match.id}>
          {!isLastItem ? (
            <BreadcrumbLink asChild>
              <Link to={match.pathname}>{t(label)}</Link>
            </BreadcrumbLink>
          ) : (
            <BreadcrumbPage>{t(label)}</BreadcrumbPage>
          )}
          {showSeparator && <BreadcrumbSeparator />}
        </BreadcrumbItem>
      );
    });

  return (
    <Breadcrumb className="hidden md:flex">
      <BreadcrumbList>{breadcrumbs}</BreadcrumbList>
    </Breadcrumb>
  );
};
