import type { DetailsFormValues } from '@/routes/application/juh/products/CreateProduct';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import type { Control } from 'react-hook-form';

interface ProductDetailsCardProps {
  control: Control<DetailsFormValues>;
}

export function ProductDetailsCard({ control }: ProductDetailsCardProps) {
  const { t } = useIntl();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('productDetails')}</CardTitle>
        <CardDescription>{t('productDetailsDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <FormField
            control={control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('productName')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('description')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
