import { zodResolver } from '@hookform/resolvers/zod';
import type { Invite, User } from '@johanniter-offshore/types';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { Globe } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface UserFormProps {
  user?: User | (Invite & { isInvite: true });
  onSubmit: (data: UserFormValues) => Promise<void>;
  allowedRoles: ('customer-admin' | 'customer-employee' | 'juh-admin' | 'juh-employee')[];
}

export type UserFormValues = z.infer<typeof userFormSchema>;

const userFormSchema = z.object({
  email: z.string().email(),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  role: z.enum(['customer-admin', 'customer-employee', 'juh-admin', 'juh-employee'], {
    errorMap: () => ({ message: 'invalidRole' }),
  }),
  language: z.enum(['de-DE', 'en-US']),
});

export const UserForm = ({ user, onSubmit, allowedRoles }: UserFormProps) => {
  const { t } = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const defaultEmail =
    import.meta.env.DEV && !user
      ? import.meta.env.VITE_DEV_EMAIL?.replace('{{val}}', Math.random().toString(36).substring(7)) || ''
      : '';
  const defaultFirstName = import.meta.env.DEV && !user ? 'Dev' : '';
  const defaultLastName = import.meta.env.DEV && !user ? 'User' : '';

  const form = useForm<UserFormValues>({
    resolver: zodResolver(userFormSchema),
    defaultValues: {
      email: user?.email ?? defaultEmail,
      firstName: user?.firstName ?? defaultFirstName,
      lastName: user?.lastName ?? defaultLastName,
      role: user?.role ?? allowedRoles[0],
      language: user?.language ?? 'de-DE',
    },
  });

  useEffect(() => {
    return () => {
      form.clearErrors();
    };
  }, [form]);

  const handleSubmit = async (data: UserFormValues) => {
    setIsLoading(true);
    form.clearErrors();
    try {
      await onSubmit(data);
    } catch (err) {
      if (err && typeof err === 'object' && 'name' in err && err.name === 'PayloadApiError') {
        const payloadError = err as { response?: { errors?: Array<{ message: string }> } };
        if (payloadError.response?.errors?.some((e) => e.message === 'EmailAlreadyUsed')) {
          form.setError('email', { type: 'manual', message: t('emailAlreadyUsed') });
        } else if (payloadError.response?.errors?.some((e) => e.message === 'LastCustomerAdminRoleChange')) {
          form.setError('role', { type: 'manual', message: t('lastCustomerAdminRoleChange') });
        } else if (payloadError.response?.errors?.some((e) => e.message === 'LastJuhAdminRoleChange')) {
          form.setError('role', { type: 'manual', message: t('lastJuhAdminRoleChange') });
        } else {
          form.setError('root', { type: 'manual', message: t('unknownError') });
        }
      } else {
        form.setError('root', { type: 'manual', message: t('unknownError') });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('email')}</FormLabel>
              <FormControl>
                <Input placeholder={t('email')} {...field} disabled={!!user} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('firstName')}</FormLabel>
              <FormControl>
                <Input placeholder={t('firstName')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('lastName')}</FormLabel>
              <FormControl>
                <Input placeholder={t('lastName')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {(!user || ('isInvite' in user && user.isInvite)) && (
          <FormField
            control={form.control}
            name="language"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('language')}</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <div className="flex items-center gap-3">
                        <Globe className="size-4 " />
                        <SelectValue placeholder={t('selectLanguage')} />
                      </div>
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="de-DE">{t('german')}</SelectItem>
                    <SelectItem value="en-US">{t('english')}</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('role')}</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t('selectRole')} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {allowedRoles.map((role) => (
                    <SelectItem key={role} value={role}>
                      {t(`portalRoles.${role}`)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        {form.formState.errors.root && <FormMessage>{form.formState.errors.root.message}</FormMessage>}
        <Button type="submit" className="mt-6" disabled={isLoading}>
          {isLoading ? (user ? t('saving') : t('sending')) : user ? t('save') : t('sendInvitation')}
        </Button>
      </form>
    </Form>
  );
};
