import { FormatedInterval, IntervalInput } from '@/components/shared/format/Intervals';
import type { ProductInspections, ProductInspectionsFormValues } from '@/routes/application/juh/products/CreateProduct';
import { productInspectionsSchema } from '@/routes/application/juh/products/CreateProduct';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ScrollArea,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Textarea,
  cn,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { ArrowDown, ArrowUp, Edit, Plus, Trash } from 'lucide-react';
import { useState } from 'react';
import { type Control, useFieldArray, useForm } from 'react-hook-form';

interface ProductInspectionsCardProps {
  control: Control<ProductInspectionsFormValues>;
}

export function ProductInspectionsCard({ control }: ProductInspectionsCardProps) {
  const { t } = useIntl();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'productInspections',
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const inspectionForm = useForm<ProductInspections>({
    resolver: zodResolver(productInspectionsSchema),
    defaultValues: {
      name: '',
      interval: '',
      checklist: [{ item: '', order: 0 }],
      id: null,
    },
  });

  const {
    fields: checklistFields,
    append: appendChecklist,
    remove: removeChecklist,
    move: moveChecklist,
  } = useFieldArray({
    control: inspectionForm.control,
    name: 'checklist',
  });

  const handleEditInspection = (index: number) => {
    setEditingIndex(index);
    inspectionForm.reset({
      ...fields[index],
      checklist: fields[index].checklist?.map((item) => ({ ...item, id: item.id || null })) || [],
    });
    setIsDrawerOpen(true);
  };

  const handleAddInspection = () => {
    setEditingIndex(null);
    inspectionForm.reset({ name: '', interval: '', checklist: [{ item: '', order: 0 }], id: null });
    setIsDrawerOpen(true);
  };

  const onSubmitInspection = (data: ProductInspections) => {
    if (editingIndex !== null) {
      update(editingIndex, data);
    } else {
      append(data);
    }
    setIsDrawerOpen(false);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('productInspections')}</CardTitle>
        <CardDescription>{t('productInspectionsDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-1/3">{t('inspectionName')}</TableHead>
              <TableHead>{t('inspectionInterval')}</TableHead>
              <TableHead className="w-[120px]">{t('actions')}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell>{field.name}</TableCell>
                <TableCell>
                  <FormatedInterval interval={field.interval} prefixFormat="every" />
                </TableCell>
                <TableCell>
                  <Button type="button" variant="ghost" size="icon" onClick={() => handleEditInspection(index)}>
                    <Edit className="size-4" />
                  </Button>
                  <Button type="button" variant="ghost" size="icon" onClick={() => remove(index)}>
                    <Trash className="size-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="flex">
          <Button
            variant="ghost"
            size="icon"
            onClick={handleAddInspection}
            type="button"
            className={cn('w-full', fields.length <= 0 && 'mt-2')}
            aria-label={t('addInspection')}
          >
            <Plus className="size-4" />
          </Button>
        </div>

        <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen} direction="right" dismissible={false}>
          <DrawerContent className="left-auto right-0 top-0 mt-0 h-screen w-full rounded-none lg:w-2/5 lg:min-w-[460px]">
            <ScrollArea className="h-screen">
              <DrawerHeader>
                <DrawerTitle>{editingIndex !== null ? t('editInspection') : t('addInspection')}</DrawerTitle>
                <DrawerDescription>{t('inspectionFormDescription')}</DrawerDescription>
              </DrawerHeader>
              <Form {...inspectionForm}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    inspectionForm.handleSubmit(onSubmitInspection)(e);
                  }}
                >
                  <div className="space-y-4 p-4">
                    <FormField
                      control={inspectionForm.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('inspectionName')}</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={inspectionForm.control}
                      name="interval"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('inspectionInterval')}</FormLabel>
                          <FormControl>
                            <IntervalInput value={field.value} onChange={(value) => field.onChange(value)} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <div>
                      <FormLabel>{t('checklist')}</FormLabel>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>{t('move')}</TableHead>
                            <TableHead className="w-full">{t('checklistItem')}</TableHead>
                            <TableHead>{t('actions')}</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {checklistFields.map((item, index) => (
                            <TableRow key={item.id}>
                              <TableCell>
                                <Button
                                  type="button"
                                  variant="ghost"
                                  size="icon"
                                  onClick={() => moveChecklist(index, Math.max(0, index - 1))}
                                  disabled={index === 0}
                                >
                                  <ArrowUp className="size-4" />
                                </Button>
                                <Button
                                  type="button"
                                  variant="ghost"
                                  size="icon"
                                  onClick={() => moveChecklist(index, Math.min(checklistFields.length - 1, index + 1))}
                                  disabled={index === checklistFields.length - 1}
                                >
                                  <ArrowDown className="size-4" />
                                </Button>
                              </TableCell>
                              <TableCell>
                                <FormField
                                  control={inspectionForm.control}
                                  name={`checklist.${index}.item`}
                                  render={({ field }) => (
                                    <FormItem>
                                      <FormControl>
                                        <Textarea rows={4} {...field} />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <Button
                                  type="button"
                                  variant="ghost"
                                  size="icon"
                                  onClick={() => removeChecklist(index)}
                                >
                                  <Trash className="size-4" />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <div className="flex">
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => appendChecklist({ item: '', order: checklistFields.length })}
                          type="button"
                          className={cn('w-full', checklistFields.length <= 0 && 'mt-2')}
                          aria-label={t('addChecklistItem')}
                        >
                          <Plus className="size-4" />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <DrawerFooter>
                    <Button type="submit">{editingIndex !== null ? t('updateInspection') : t('addInspection')}</Button>
                    <DrawerClose asChild>
                      <Button variant="outline">{t('cancel')}</Button>
                    </DrawerClose>
                  </DrawerFooter>
                </form>
              </Form>
            </ScrollArea>
          </DrawerContent>
        </Drawer>
      </CardContent>
    </Card>
  );
}
