import type { ContainerAndChildProductsFormValues } from '@/routes/application/juh/products/CreateProduct';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Checkbox,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import type { Control } from 'react-hook-form';

interface ContainerProductCardProps {
  control: Control<ContainerAndChildProductsFormValues>;
  disabled?: boolean;
}

export function ContainerProductCard({ control, disabled }: ContainerProductCardProps) {
  const { t } = useIntl();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('containerProduct')}</CardTitle>
        <CardDescription>{t('containerProductDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <FormField
          control={control}
          name="isContainer"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
              <FormControl>
                <Checkbox disabled={disabled} checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>{t('isContainer')}</FormLabel>
                <FormDescription>{t('isContainerDescription')}</FormDescription>
              </div>
            </FormItem>
          )}
        />
      </CardContent>
    </Card>
  );
}
