import type { Station } from '@johanniter-offshore/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { payloadClient } from '.';
import type { Where } from './types';

export const useStations = (params?: {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof Station | `-${keyof Station}`;
  disabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['stations', params],
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'stations',
        ...params,
      });
      return response;
    },
  });
};

export const useStation = (id: string, params?: { disabled?: boolean }) => {
  return useQuery({
    queryKey: ['station', id, params],
    enabled: !params?.disabled && !!id,
    queryFn: async () => {
      const response = await payloadClient.findById({
        collection: 'stations',
        id,
      });
      return response;
    },
  });
};

export const useCreateStation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Partial<Station>) => {
      const response = await payloadClient.create({
        collection: 'stations',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stations'] });
    },
  });
};

export const useUpdateStation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<Station> }) => {
      const response = await payloadClient.updateById({
        collection: 'stations',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stations'] });
    },
  });
};

export const useDeleteStation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await payloadClient.deleteById({
        collection: 'stations',
        id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stations'] });
    },
  });
};
