import { useArticle } from '@/api/articles';
import { useProduct } from '@/api/products';
import { SearchCombobox } from '@/components/juh/inputs/SearchCombobox';
import type { DetailsFormValues } from '@/routes/application/juh/articles/CreateArticle';
import type { Article } from '@johanniter-offshore/types';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import type { Control } from 'react-hook-form';

interface ArticleDetailsCardProps {
  control: Control<DetailsFormValues>;
  disabled?: boolean;
  article?: Article;
}

export function ArticleDetailsCard({ control, disabled, article }: ArticleDetailsCardProps) {
  const { t } = useIntl();

  const { data: parentArticle } = useArticle(article?.parent as string, { disabled: !article?.parent });
  const { data: parentProduct } = useProduct(parentArticle?.product as string, { disabled: !parentArticle });

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('articleDetails')}</CardTitle>
        <CardDescription>{t('articleDetailsDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <FormField
            control={control}
            name="product"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>{t('product')}</FormLabel>
                <FormControl>
                  <SearchCombobox
                    disabled={disabled}
                    value={field.value || undefined}
                    onSelect={(value) => field.onChange(value)}
                    collectionKey="products"
                    searchKey="name"
                    texts={{
                      selectItemMsg: t('selectProduct'),
                      searchPlaceholder: t('searchProduct'),
                    }}
                    clearValue={''}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {article?.parent && (
            <FormItem className="flex flex-col">
              <FormLabel>{t('parentArticle')}</FormLabel>
              <FormControl>
                <Input disabled={true} value={parentProduct?.name || ''} />
              </FormControl>
            </FormItem>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
