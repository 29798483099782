import { useUploadMedia } from '@/api/media';
import { useProductCategories } from '@/api/productCategories';
import { useProduct, useUpdateProduct } from '@/api/products';
import { ChildProductsCard } from '@/components/juh/products/ChildProductsCard';
import { ContainerProductCard } from '@/components/juh/products/ContainerProductCard';
import { ProductCategoryCard } from '@/components/juh/products/ProductCategoryCard';
import { ProductDetailsCard } from '@/components/juh/products/ProductDetailsCard';
import { ProductFilesCard } from '@/components/juh/products/ProductFilesCard';
import { ProductInspectionsCard } from '@/components/juh/products/ProductInspectionsCard';
import {
  MetadataBuilder,
  formFieldsToJsonSchema,
  handleMetadataValidationErrors,
  jsonSchemaToFormFields,
} from '@/components/shared/metadata/MetadataBuilder';
import { useAuth } from '@/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import type { JsonSchemaType, Product } from '@johanniter-offshore/types';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Form,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@johanniter-offshore/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from '@tiny-intl/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import {
  type CategoryFormValues,
  type ContainerAndChildProductsFormValues,
  type DetailsFormValues,
  type MetadataFormValues,
  type ProductFilesFormValues,
  type ProductInspectionsFormValues,
  categorySchema,
  containerAndChildProductsSchema,
  detailsSchema,
  metadataSchema,
  productFilesSchema,
  productInpsectionsFormSchema,
} from './CreateProduct';

function SubmitButton({ isLoading }: { isLoading: boolean }) {
  const { t } = useIntl();
  return (
    <Button type="submit" className="mt-4" disabled={isLoading}>
      {isLoading ? t('saving') : t('updateProduct')}
    </Button>
  );
}

export function EditProduct() {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const updateProduct = useUpdateProduct();
  const { data: categoriesData } = useProductCategories({ limit: 0 });
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const uploadMedia = useUploadMedia();

  const isJuhAdmin = currentUser?.role === 'juh-admin';

  const detailsForm = useForm<DetailsFormValues>({
    resolver: zodResolver(detailsSchema),
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const categoryForm = useForm<CategoryFormValues>({
    resolver: zodResolver(categorySchema),
    defaultValues: {
      productCategory: null,
    },
  });

  const productInspectionsForm = useForm<ProductInspectionsFormValues>({
    resolver: zodResolver(productInpsectionsFormSchema),
    defaultValues: {
      productInspections: [],
    },
  });

  const metadataForm = useForm<MetadataFormValues>({
    resolver: zodResolver(metadataSchema),
    defaultValues: {
      metadata: [],
    },
  });

  const containerAndChildProductsForm = useForm<ContainerAndChildProductsFormValues>({
    resolver: zodResolver(containerAndChildProductsSchema),
    defaultValues: {
      isContainer: false,
      childProducts: [],
    },
  });

  const productFilesForm = useForm<ProductFilesFormValues>({
    resolver: zodResolver(productFilesSchema),
    defaultValues: {
      files: [],
    },
  });

  const { data: productData } = useProduct(id!, { disabled: !id });

  useEffect(() => {
    if (productData) {
      detailsForm.reset({
        name: productData.name,
        description: productData.description || '',
      });
      categoryForm.reset({
        productCategory: (productData.productCategory as string) || null,
      });
      productInspectionsForm.reset({
        productInspections: productData.productInspections || [],
      });
      metadataForm.reset({
        metadata: jsonSchemaToFormFields(productData.metadata as JsonSchemaType) || [],
      });
      containerAndChildProductsForm.reset({
        isContainer: productData.isContainer || false,
        childProducts:
          productData.childProducts?.map((product) => ({
            product: product.product as string,
            quantity: product.quantity,
            order: product.order || undefined,
          })) || [],
      });
      productFilesForm.reset({
        files:
          productData.productFiles?.map((file) => ({
            name: file?.name || '',
            description: file.description || '',
            id: file.id || '',
            file: file.file as string,
          })) || [],
      });
    }
  }, [
    productData,
    detailsForm,
    categoryForm,
    productInspectionsForm,
    metadataForm,
    containerAndChildProductsForm,
    productFilesForm,
  ]);

  const onSubmit = async (
    data: Partial<
      DetailsFormValues &
        CategoryFormValues &
        ProductInspectionsFormValues &
        MetadataFormValues &
        ContainerAndChildProductsFormValues &
        ProductFilesFormValues
    >,
  ) => {
    setIsSubmitting(true);
    const payload: Partial<Product> = { ...data };
    let jsonSchema: JsonSchemaType | undefined = undefined;

    if (data.metadata) {
      jsonSchema = formFieldsToJsonSchema(data.metadata || []);
      payload.metadata = jsonSchema;
    }

    if (data.childProducts) {
      payload.childProducts = data.childProducts
        .filter((product) => product.product && product.quantity)
        .map((product, index) => ({ ...product, order: index }));
    }

    try {
      if (data.files) {
        const existingFiles = productData?.productFiles || [];
        const newFiles = data.files.filter((file) => file.file instanceof File);
        const updatedFiles = data.files.filter((file) => typeof file.id === 'string');

        const uploadedFiles = await Promise.all(
          newFiles.map(async (fileData) => {
            if (!fileData.file || !(fileData.file instanceof File)) {
              throw new Error('File is missing');
            }
            const uploadedFile = await uploadMedia.mutateAsync({
              file: fileData.file,
              data: {},
            });
            return { file: uploadedFile.doc.id, name: fileData.name, description: fileData.description };
          }),
        );

        payload.productFiles = [
          ...existingFiles
            .filter((file) => updatedFiles.some((updatedFile) => updatedFile.id === file.file))
            .map((file) => ({ ...file, file: file.file as string })),
          ...updatedFiles.map((file) => ({ ...file, file: file.file as string })),
          ...uploadedFiles,
        ];
      }

      await updateProduct.mutateAsync({ id: id!, data: payload });
      toast.success(t('productUpdatedDescription'));
      queryClient.invalidateQueries({ queryKey: ['products'] });
    } catch (error) {
      if (error && typeof error === 'object' && 'name' in error && error.name === 'PayloadApiError') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const payloadError = error as { response?: { errors?: Array<{ message: string; data?: any }> } };
        const circularReferenceError = payloadError.response?.errors?.find(
          (e) => e.message === 'CircularReferenceError',
        );
        const maxDepthError = payloadError.response?.errors?.find((e) => e.message === 'MaxProductDepthExceeded');
        const metadataUpdateValidationFailed = payloadError.response?.errors?.find(
          (e) => e.message === 'MetadataUpdateValidationFailed',
        );
        const categoryChangeRequiredFieldsError = payloadError.response?.errors?.find(
          (e) => e.message === 'CategoryChangeRequiredFieldsError',
        );
        if (circularReferenceError || maxDepthError) {
          const errorType = circularReferenceError ? 'circularReference' : 'maxDepth';
          toast.error(t(`${errorType}ErrorTitle`), {
            description: t(`${errorType}ErrorDescription`),
            duration: 25000,
            closeButton: true,
            dismissible: true,
          });

          const errorData = circularReferenceError?.data || maxDepthError?.data;
          const childProductId = errorData?.product;
          const index = data.childProducts?.findIndex((p) => p.product === childProductId);
          if (index !== undefined && index !== -1) {
            containerAndChildProductsForm.setError(`childProducts.${index}.product`, {
              type: 'manual',
              message: t(`${errorType}ErrorDescription`),
            });
          }
          return;
        }
        if (jsonSchema && data.metadata !== undefined && metadataUpdateValidationFailed) {
          handleMetadataValidationErrors(error, data.metadata, jsonSchema, metadataForm, t);
          return;
        }
        if (categoryChangeRequiredFieldsError) {
          categoryForm.setError('productCategory', {
            type: 'manual',
            message: t('categoryChangeRequiredFieldsErrorDescription'),
          });
          return;
        }
      }
      toast.error(t('errorUpdatingProductDescription'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="grid gap-6 md:grid-cols-1">
      <div className="mx-auto w-full max-w-[59rem] p-4 sm:px-6">
        <h1 className="mb-6 text-xl font-semibold tracking-tight">{t('editProduct')}</h1>

        <Tabs defaultValue="details">
          <TabsList>
            <TabsTrigger value="details">{t('productDetails')}</TabsTrigger>
            <TabsTrigger value="category">{t('productCategory')}</TabsTrigger>
            <TabsTrigger value="inspections">{t('productInspections')}</TabsTrigger>
            <TabsTrigger value="metadata">{t('metadata')}</TabsTrigger>
            <TabsTrigger value="containerAndChildProducts">{t('containerProduct')}</TabsTrigger>
            <TabsTrigger value="files">{t('productFiles')}</TabsTrigger>
          </TabsList>

          <TabsContent value="details">
            <Form {...detailsForm}>
              <form onSubmit={detailsForm.handleSubmit((data) => onSubmit(data))}>
                <ProductDetailsCard control={detailsForm.control} />
                <SubmitButton isLoading={isSubmitting} />
              </form>
            </Form>
          </TabsContent>

          <TabsContent value="category">
            <Form {...categoryForm}>
              <form onSubmit={categoryForm.handleSubmit((data) => onSubmit(data))}>
                <ProductCategoryCard
                  control={categoryForm.control}
                  setValue={categoryForm.setValue}
                  categoriesData={categoriesData}
                />
                <SubmitButton isLoading={isSubmitting} />
              </form>
            </Form>
          </TabsContent>

          <TabsContent value="inspections">
            <Form {...productInspectionsForm}>
              <form onSubmit={productInspectionsForm.handleSubmit((data) => onSubmit(data))}>
                <ProductInspectionsCard control={productInspectionsForm.control} />
                <SubmitButton isLoading={isSubmitting} />
              </form>
            </Form>
          </TabsContent>

          <TabsContent value="metadata">
            <Form {...metadataForm}>
              <form onSubmit={metadataForm.handleSubmit((data) => onSubmit(data))}>
                <Card>
                  <CardHeader>
                    <CardTitle>{t('metadata')}</CardTitle>
                    <CardDescription>{t('productMetadataDescription')}</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <MetadataBuilder disabled={!isJuhAdmin} />
                  </CardContent>
                </Card>
                <SubmitButton isLoading={isSubmitting} />
              </form>
            </Form>
          </TabsContent>

          <TabsContent value="containerAndChildProducts">
            <Form {...containerAndChildProductsForm}>
              <form onSubmit={containerAndChildProductsForm.handleSubmit((data) => onSubmit(data))}>
                <ContainerProductCard
                  disabled={!!productData?.articleCount}
                  control={containerAndChildProductsForm.control}
                />
                {containerAndChildProductsForm.watch('isContainer') && (
                  <ChildProductsCard
                    disabled={!!productData?.articleCount}
                    control={containerAndChildProductsForm.control}
                    currentProductId={id}
                  />
                )}
                <SubmitButton isLoading={isSubmitting} />
              </form>
            </Form>
          </TabsContent>

          <TabsContent value="files">
            <Form {...productFilesForm}>
              <form onSubmit={productFilesForm.handleSubmit((data) => onSubmit(data))}>
                <ProductFilesCard />
                <SubmitButton isLoading={isSubmitting} />
              </form>
            </Form>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
