import { DateTime, Duration } from 'luxon';

interface NextTestDateProps {
  lastTestDate?: string | null;
  testInterval?: string | null;
}

const calculateNextTestDate = (lastTestDate: string, testInterval: string): DateTime | null => {
  const lastTest = DateTime.fromISO(lastTestDate);
  const [value, unit] = testInterval.split(':');
  const unitMap: Record<string, keyof Duration> = {
    d: 'days',
    w: 'weeks',
    m: 'months',
    y: 'years',
  };
  const durationUnit = unitMap[unit as keyof typeof unitMap];
  if (!durationUnit) return null;
  const duration = Duration.fromObject({ [durationUnit]: parseInt(value) });
  return lastTest.plus(duration);
};

export function NextTestDate({ lastTestDate, testInterval }: NextTestDateProps) {
  if (!lastTestDate || !testInterval) return <div>-</div>;

  const nextTestDate = calculateNextTestDate(lastTestDate, testInterval);

  return <div>{nextTestDate ? nextTestDate.toFormat('dd.MM.yyyy') : '-'}</div>;
}
