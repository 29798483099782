import { useArticles } from '@/api/articles';
import { useStation } from '@/api/stations';
import { FormatedInterval } from '@/components/shared/format/Intervals';
import type { Article, Product } from '@johanniter-offshore/types';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Checkbox,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  cn,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { AlertCircle, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const ArticleTable = ({
  childProduct,
  requiredCount,
  autoSelect,
  filterByStation,
  stationId,
  parentArticle,
}: {
  childProduct: Product;
  requiredCount: number;
  autoSelect: boolean;
  filterByStation: boolean;
  stationId: string | null;
  parentArticle?: Article;
}) => {
  const { t } = useIntl();
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const selectedArticles = watch(childProduct.id) || [];

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: Math.max(10, requiredCount),
  });

  const whereClause = useMemo(() => {
    const base = {
      product: {
        equals: childProduct.id,
      },
      and: [
        {
          or: [{ parent: { exists: false } }, ...(parentArticle ? [{ parent: { equals: parentArticle.id } }] : [])],
        },
        {
          or: [
            { organization: { exists: false } },
            ...(parentArticle?.organization
              ? [{ organization: { equals: parentArticle.organization as string } }]
              : []),
          ],
        },
      ],
    };
    if (filterByStation && stationId) {
      return {
        ...base,
        station: {
          equals: stationId,
        },
      };
    }
    return base;
  }, [childProduct.id, filterByStation, stationId, parentArticle]);

  const { data: articlesData, isLoading } = useArticles({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    where: whereClause,
    sort: parentArticle ? 'parent' : undefined,
  });

  const articles = useMemo(() => articlesData?.docs || [], [articlesData?.docs]);
  const totalPages = articlesData?.totalPages || 0;
  const totalArticles = articlesData?.totalDocs || 0;

  const isSelectionComplete = selectedArticles.length === requiredCount;
  const isNotEnoughArticles = totalArticles < requiredCount;

  const handleSelectArticle = (articleId: string) => {
    if (!autoSelect) {
      const newSelectedArticles = selectedArticles.includes(articleId)
        ? selectedArticles.filter((id: string) => id !== articleId)
        : [...selectedArticles, articleId];
      setValue(childProduct.id, newSelectedArticles);
    }
  };

  useEffect(() => {
    if (filterByStation && stationId) {
      setValue(
        childProduct.id,
        selectedArticles.filter((articleId: string) =>
          articles.some((article) => article.id === articleId && article.station === stationId),
        ),
      );
    }

    if (autoSelect && articles.length > 0 && selectedArticles.length < requiredCount) {
      const availableArticles = articles.filter((article) => !selectedArticles.includes(article.id));
      const articlesToSelect = availableArticles.slice(0, requiredCount - selectedArticles.length);
      setValue(childProduct.id, [...selectedArticles, ...articlesToSelect.map((article) => article.id)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelect, articles, requiredCount, filterByStation, stationId, childProduct.id]);

  const StationCell = ({ stationId }: { stationId: string }) => {
    const { data: station, isLoading } = useStation(stationId);
    if (isLoading) return <Skeleton className="h-4 w-24" />;
    return <div>{station?.name || '-'}</div>;
  };

  const DataTablePagination = () => {
    return (
      <div className="flex w-full items-center justify-between">
        <div>
          <p
            className={cn(
              'text-sm font-medium',
              selectedArticles.length === requiredCount ? 'text-muted-foreground' : 'text-foreground',
            )}
          >
            {t('selectedCount')}: {selectedArticles.length} / {requiredCount}
          </p>
        </div>
        <div className="flex items-center space-x-6 lg:space-x-8">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium">{t('rowsPerPage')}</p>
            <Select
              value={`${pagination.pageSize}`}
              onValueChange={(value) => {
                setPagination((prev) => ({ ...prev, pageSize: Number(value) }));
              }}
            >
              <SelectTrigger className="h-8 w-[70px]">
                <SelectValue placeholder={pagination.pageSize} />
              </SelectTrigger>
              <SelectContent side="top">
                {[...new Set([requiredCount < 10 ? 10 : requiredCount, 20, 30, 40, 50])]
                  .sort((a, b) => a - b)
                  .filter((pageSize) => pageSize >= requiredCount)
                  .map((pageSize) => (
                    <SelectItem key={pageSize} value={`${pageSize}`}>
                      {pageSize}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            {t('page')} {pagination.pageIndex + 1} {t('of')} {totalPages}
          </div>

          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              className="hidden size-8 p-0 lg:flex"
              onClick={() => setPagination((prev) => ({ ...prev, pageIndex: 0 }))}
              disabled={pagination.pageIndex === 0}
            >
              <span className="sr-only">{t('goToFirstPage')}</span>
              <ChevronsLeft className="size-4" />
            </Button>
            <Button
              variant="outline"
              className="size-8 p-0"
              onClick={() => setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex - 1 }))}
              disabled={pagination.pageIndex === 0}
            >
              <span className="sr-only">{t('goToPreviousPage')}</span>
              <ChevronLeft className="size-4" />
            </Button>
            <Button
              variant="outline"
              className="size-8 p-0"
              onClick={() => setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex + 1 }))}
              disabled={pagination.pageIndex === totalPages - 1}
            >
              <span className="sr-only">{t('goToNextPage')}</span>
              <ChevronRight className="size-4" />
            </Button>
            <Button
              variant="outline"
              className="hidden size-8 p-0 lg:flex"
              onClick={() => setPagination((prev) => ({ ...prev, pageIndex: totalPages - 1 }))}
              disabled={pagination.pageIndex === totalPages - 1}
            >
              <span className="sr-only">{t('goToLastPage')}</span>
              <ChevronsRight className="size-4" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{childProduct.name}</CardTitle>
      </CardHeader>

      <CardContent>
        {!isLoading && isNotEnoughArticles && (
          <Alert variant="destructive" className="mb-4">
            <AlertCircle className="size-4" />
            <AlertTitle>{t('notEnoughArticles')}</AlertTitle>
            <AlertDescription>
              {t('notEnoughArticlesDescription', { available: totalArticles, required: requiredCount })}
            </AlertDescription>
          </Alert>
        )}
        {errors[childProduct.id] && (
          <Alert variant="destructive" className="mb-4">
            <AlertCircle className="size-4" />
            <AlertTitle>{t('selectionError')}</AlertTitle>
            <AlertDescription>{errors[childProduct.id]?.message as string}</AlertDescription>
          </Alert>
        )}
        <div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[32px]"></TableHead>
                <TableHead>{t('name')}</TableHead>
                <TableHead>{t('expiryDate')}</TableHead>
                <TableHead>{t('testInterval')}</TableHead>
                <TableHead>{t('lastTestDate')}</TableHead>
                <TableHead>{t('station')}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={6} className="h-24 text-center">
                    {t('loading')}
                  </TableCell>
                </TableRow>
              ) : articles.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} className="h-24 text-center">
                    {t('noResults')}
                  </TableCell>
                </TableRow>
              ) : (
                articles.map((article: Article) => (
                  <TableRow key={article.id}>
                    <TableCell>
                      <Checkbox
                        className="m-2"
                        checked={selectedArticles.includes(article.id)}
                        onCheckedChange={() => handleSelectArticle(article.id)}
                        disabled={autoSelect || (isSelectionComplete && !selectedArticles.includes(article.id))}
                      />
                    </TableCell>
                    <TableCell className="max-w-[200px] truncate">{childProduct.name}</TableCell>
                    <TableCell>
                      {article.expiryDate ? DateTime.fromISO(article.expiryDate).toFormat('dd.MM.yyyy') : '-'}
                    </TableCell>
                    <TableCell>
                      {article.testInterval ? (
                        <FormatedInterval interval={article.testInterval} prefixFormat="every" />
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell>
                      {article.lastTestDate ? DateTime.fromISO(article.lastTestDate).toFormat('dd.MM.yyyy') : '-'}
                    </TableCell>
                    <TableCell>
                      {article.station ? <StationCell stationId={article.station as string} /> : '-'}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>
      </CardContent>
      <CardFooter>
        <DataTablePagination />
      </CardFooter>
    </Card>
  );
};

export const ArticleChildren = ({
  childProductsWithCount,
  stationId,
  parentArticle,
}: {
  childProductsWithCount: {
    product: Product;
    requiredCount: number;
  }[];
  stationId: string | null;
  parentArticle?: Article;
}) => {
  const { t } = useIntl();
  const [autoSelect, setAutoSelect] = useState(true);
  const [filterByStation, setFilterByStation] = useState(false);

  useEffect(() => {
    if (!stationId) {
      setFilterByStation(false);
    }
  }, [stationId]);

  return (
    <div className="flex flex-col gap-3">
      <Card>
        <CardHeader>
          <CardTitle>{t('articleChildren')}</CardTitle>
          <CardDescription>{t('articleChildrenDescription')}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
              <Checkbox checked={autoSelect} onCheckedChange={(checked) => setAutoSelect(checked as boolean)} />
              <div className="space-y-1 leading-none">
                <span className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                  {t('autoSelect')}
                </span>
                <p className="text-muted-foreground text-sm">{t('autoSelectDescription')}</p>
              </div>
            </div>
            <div
              className={cn(
                'flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4',
                !stationId && 'text-muted-foreground cursor-not-allowed',
              )}
            >
              <Checkbox
                checked={filterByStation}
                onCheckedChange={(checked) => setFilterByStation(checked as boolean)}
                disabled={!stationId}
              />
              <div className="space-y-1 leading-none">
                <span className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                  {t('filterByStation')}
                </span>
                <p className="text-muted-foreground text-sm">{t('filterByStationDescription')}</p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      {childProductsWithCount.map(({ product: childProduct, requiredCount }) => (
        <ArticleTable
          key={childProduct.id}
          childProduct={childProduct}
          requiredCount={requiredCount}
          autoSelect={autoSelect}
          filterByStation={filterByStation}
          stationId={stationId}
          parentArticle={parentArticle}
        />
      ))}
    </div>
  );
};
