import type { Article } from '@johanniter-offshore/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { payloadClient } from '.';
import type { Where } from './types';

export const useArticles = (params?: {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof Article | `-${keyof Article}`;
  disabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['articles', params],
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'articles',
        ...params,
      });
      return response;
    },
  });
};

export const useArticle = (id: string, params?: { disabled?: boolean }) => {
  return useQuery({
    queryKey: ['article', id],
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.findById({
        collection: 'articles',
        id,
      });
      return response;
    },
  });
};

export const useCreateArticle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<Article>) => {
      const response = await payloadClient.create({
        collection: 'articles',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['articles'] });
    },
  });
};

export const useUpdateArticle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<Article> }) => {
      const response = await payloadClient.updateById({
        collection: 'articles',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['articles'] });
    },
  });
};

export const useDeleteArticle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const response = await payloadClient.deleteById({
        collection: 'articles',
        id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['articles'] });
    },
  });
};
