import { useAuth } from '@/utils/hooks/useAuth';
import type { Organization } from '@johanniter-offshore/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { payloadClient } from '.';

export const useCurrentUserOrganization = () => {
  const { user } = useAuth();

  return useQuery({
    queryKey: ['currentUserOrganization'],
    queryFn: async () => {
      if (!user?.organization) return null;

      const organizationId = typeof user.organization === 'string' ? user.organization : user.organization.id;

      const response = await payloadClient.findById({
        collection: 'organizations',
        id: organizationId,
      });
      return response;
    },
    enabled: !!user?.organization,
  });
};

export const useOrganization = (id: string) => {
  return useQuery({
    queryKey: ['organization', id],
    queryFn: async () => {
      const response = await payloadClient.findById({
        collection: 'organizations',
        id,
      });
      return response;
    },
    enabled: !!id,
  });
};

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<Organization> }) => {
      const response = await payloadClient.updateById({
        collection: 'organizations',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentUserOrganization'] });
    },
  });
};
