import i18next from 'i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';

i18next.init({
  supportedLngs: ['en-US', 'de-DE'],
  fallbackLng: 'de-DE',
  resources: {
    'en-US': {
      zod: {
        errors: {
          invalid_type: 'Field is required',
          invalid_type_received_undefined: 'Required',
          invalid_literal: 'Invalid literal value, expected {{expected}}',
          unrecognized_keys: 'Unrecognized key(s) in object: {{- keys}}',
          invalid_union: 'Invalid input',
          invalid_union_discriminator: 'Invalid discriminator value. Expected {{- options}}',
          invalid_enum_value: 'Invalid enum value. Expected {{- options}}, received {{received}}',
          invalid_arguments: 'Invalid function arguments',
          invalid_return_type: 'Invalid function return type',
          invalid_date: 'Invalid date',
          custom: 'Invalid input',
          invalid_intersection_types: 'Intersection results could not be merged',
          not_multiple_of: 'Number must be a multiple of {{multipleOf}}',
          not_finite: 'Number must be finite',
          invalid_string: {
            email: 'Invalid {{validation}}',
            url: 'Invalid {{validation}}',
            uuid: 'Invalid {{validation}}',
            cuid: 'Invalid {{validation}}',
            regex: 'Invalid',
            datetime: 'Invalid {{validation}}',
            startsWith: 'Invalid input: must start with "{{startsWith}}"',
            endsWith: 'Invalid input: must end with "{{endsWith}}"',
          },
          too_small: {
            array: {
              exact: 'Array must contain exactly {{minimum}} element(s)',
              inclusive: 'Array must contain at least {{minimum}} element(s)',
              not_inclusive: 'Array must contain more than {{minimum}} element(s)',
            },
            string: {
              exact: 'Field must contain exactly {{minimum}} character(s)',
              inclusive: 'Field must contain at least {{minimum}} character(s)',
              not_inclusive: 'Field must contain over {{minimum}} character(s)',
            },
            number: {
              exact: 'Number must be exactly {{minimum}}',
              inclusive: 'Number must be greater than or equal to {{minimum}}',
              not_inclusive: 'Number must be greater than {{minimum}}',
            },
            set: {
              exact: 'Invalid input',
              inclusive: 'Invalid input',
              not_inclusive: 'Invalid input',
            },
            date: {
              exact: 'Date must be exactly {{- minimum, datetime}}',
              inclusive: 'Date must be greater than or equal to {{- minimum, datetime}}',
              not_inclusive: 'Date must be greater than {{- minimum, datetime}}',
            },
          },
          too_big: {
            array: {
              exact: 'List must contain exactly {{maximum}} element(s)',
              inclusive: 'List must contain at most {{maximum}} element(s)',
              not_inclusive: 'List must contain less than {{maximum}} element(s)',
            },
            string: {
              exact: 'Field must contain exactly {{maximum}} character(s)',
              inclusive: 'Field must contain at most {{maximum}} character(s)',
              not_inclusive: 'Field must contain under {{maximum}} character(s)',
            },
            number: {
              exact: 'Number must be exactly {{maximum}}',
              inclusive: 'Number must be less than or equal to {{maximum}}',
              not_inclusive: 'Number must be less than {{maximum}}',
            },
            set: {
              exact: 'Invalid input',
              inclusive: 'Invalid input',
              not_inclusive: 'Invalid input',
            },
            date: {
              exact: 'Date must be exactly {{- maximum, datetime}}',
              inclusive: 'Date must be smaller than or equal to {{- maximum, datetime}}',
              not_inclusive: 'Date must be smaller than {{- maximum, datetime}}',
            },
          },
          validations: {
            email: 'Email address',
            url: 'URL',
            uuid: 'UUID',
            cuid: 'CUID',
            regex: 'Regex',
            datetime: 'Date and time value',
          },
          types: {
            function: 'Function',
            number: 'Number',
            string: 'Text',
            nan: 'no number',
            integer: 'Integer',
            float: 'Float',
            boolean: 'Boolean',
            date: 'Date',
            bigint: 'BigInt',
            undefined: 'Undefined',
            symbol: 'Symbol',
            null: 'Null',
            array: 'List',
            object: 'Object',
            unknown: 'Unknown',
            promise: 'Promise',
            void: 'Void',
            never: 'Never',
            map: 'Map',
            set: 'Set',
          },
        },
      },
    },
    'de-DE': {
      zod: {
        errors: {
          invalid_type: 'Feld ist erforderlich',
          invalid_type_received_undefined: 'Darf nicht leer sein',
          invalid_literal: 'Ungültiger Literalwert, {{expected}} erwartet',
          unrecognized_keys: 'Unbekannte Schlüssel im Objekt: {{- keys}}',
          invalid_union: 'Ungültige Eingabe',
          invalid_union_discriminator: 'Ungültiger Diskriminatorwert, {{- options}} erwartet',
          invalid_enum_value: 'Ungültiger Enum-Wert. {{- options}} erwartet, {{received}} erhalten',
          invalid_arguments: 'Ungültige Funktionsargumente',
          invalid_return_type: 'Ungültiger Funktionsrückgabewert',
          invalid_date: 'Ungültiges Datum',
          custom: 'Ungültige Eingabe',
          invalid_intersection_types: 'Schnittmengenergebnisse konnten nicht zusammengeführt werden',
          not_multiple_of: 'Zahl muss ein Vielfaches von {{multipleOf}} sein',
          not_finite: 'Zahl muss endlich sein',
          invalid_string: {
            email: 'Ungültige {{validation}}',
            url: 'Ungültige {{validation}}',
            uuid: 'Ungültige {{validation}}',
            cuid: 'Ungültige {{validation}}',
            regex: 'Ungültig',
            datetime: 'Ungültiger {{validation}}',
            startsWith: 'Ungültige Eingabe: muss mit "{{startsWith}}" beginnen',
            endsWith: 'Ungültige Eingabe: muss mit "{{endsWith}}" enden',
          },
          too_small: {
            array: {
              exact: 'Liste muss genau {{minimum}} Element(e) enthalten',
              inclusive: 'Liste muss mindestens {{minimum}} Element(e) enthalten',
              not_inclusive: 'Liste muss mehr als {{minimum}} Element(e) enthalten',
            },
            string: {
              exact: 'Feld muss genau {{minimum}} Zeichen enthalten',
              inclusive: 'Feld muss mindestens {{minimum}} Zeichen enthalten',
              not_inclusive: 'Feld muss mehr als {{minimum}} Zeichen enthalten',
            },
            number: {
              exact: 'Zahl muss genau {{minimum}} sein',
              inclusive: 'Zahl muss größer oder gleich {{minimum}} sein',
              not_inclusive: 'Zahl muss größer als {{minimum}} sein',
            },
            set: {
              exact: 'Ungültige Eingabe',
              inclusive: 'Ungültige Eingabe',
              not_inclusive: 'Ungültige Eingabe',
            },
            date: {
              exact: 'Datum muss genau {{- minimum, datetime}} sein',
              inclusive: 'Datum muss größer oder gleich {{- minimum, datetime}} sein',
              not_inclusive: 'Datum muss größer als {{- minimum, datetime}} sein',
            },
          },
          too_big: {
            array: {
              exact: 'Array muss genau {{maximum}} Element(e) enthalten',
              inclusive: 'Array darf höchstens {{maximum}} Element(e) enthalten',
              not_inclusive: 'Array muss weniger als {{maximum}} Element(e) enthalten',
            },
            string: {
              exact: 'Feld muss genau {{maximum}} Zeichen enthalten',
              inclusive: 'Feld darf höchstens {{maximum}} Zeichen enthalten',
              not_inclusive: 'Feld muss weniger als {{maximum}} Zeichen enthalten',
            },
            number: {
              exact: 'Zahl muss genau {{maximum}} sein',
              inclusive: 'Zahl muss kleiner oder gleich {{maximum}} sein',
              not_inclusive: 'Zahl muss kleiner als {{maximum}} sein',
            },
            set: {
              exact: 'Ungültige Eingabe',
              inclusive: 'Ungültige Eingabe',
              not_inclusive: 'Ungültige Eingabe',
            },
            date: {
              exact: 'Datum muss genau {{- maximum, datetime}} sein',
              inclusive: 'Datum muss kleiner oder gleich {{- maximum, datetime}} sein',
              not_inclusive: 'Datum muss kleiner als {{- maximum, datetime}} sein',
            },
          },
        },
        validations: {
          email: 'E-Mail-Adresse',
          url: 'URL',
          uuid: 'UUID',
          cuid: 'CUID',
          regex: 'Regex',
          datetime: 'Datums- und Uhrzeitwert',
        },
        types: {
          function: 'Funktion',
          number: 'Zahl',
          string: 'Text',
          nan: 'Keine Zahl',
          integer: 'Ganzzahl',
          float: 'Gleitkommazahl',
          boolean: 'Boolean',
          date: 'Datum',
          bigint: 'Bigint',
          undefined: 'Undefined',
          symbol: 'Symbol',
          null: 'Nullwert',
          array: 'Liste',
          object: 'Objekt',
          unknown: 'Unknown',
          promise: 'Promise',
          void: 'Void',
          never: 'Never',
          map: 'Map',
          set: 'Set',
        },
      },
    },
  },
});
z.setErrorMap(zodI18nMap);

export default z;
export { i18next };
