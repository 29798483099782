import type { Config } from '@johanniter-offshore/types';

import { PayloadApiClient } from './client';

const payloadClient = new PayloadApiClient<Config>({
  apiURL: import.meta.env.VITE_BACKEND_URL || 'http://localhost:3000/api',
  fetcher: (url, init) => {
    return fetch(url, {
      ...(init ?? {}),
    });
  },
});

export { payloadClient };
export * from './client';
export type * from './types';

export * from './users';
export * from './invites';
