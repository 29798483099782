import type { Where } from '@/api';
import { payloadClient } from '@/api';
import type { Config } from '@johanniter-offshore/types';
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  cn,
} from '@johanniter-offshore/ui';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useIntl } from '@tiny-intl/react';
import type { LucideIcon } from 'lucide-react';
import { Check, ChevronsUpDown, X } from 'lucide-react';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'use-debounce';

export type ComboBoxItemType = {
  value: string;
  label: string;
};

type SearchComboboxProps<T extends keyof Config['collections']> = {
  value?: string;
  onSelect: (value: string | undefined | null) => void;
  className?: string;
  unselect?: boolean;
  unselectMsg?: string;
  collectionKey: T;
  searchKey: keyof Config['collections'][T];
  excludeIds?: string[];
  error?: string;
  autoFocus?: boolean;
  texts?: {
    selectItemMsg?: string;
    searchPlaceholder?: string;
    noResultsMsg?: string;
    searchingMsg?: string;
    errorMsg?: string;
    loadMoreMsg?: string;
  };
  icon?: LucideIcon;
  xs?: boolean;
  customWhere?: Where;
  clearValue?: null | undefined | '';
  disabled?: boolean;
};

const popOverStyles = {
  width: 'var(--radix-popover-trigger-width)',
};

const ITEMS_PER_PAGE = 10;

export function SearchCombobox<T extends keyof Config['collections']>({
  value,
  onSelect,
  className,
  unselect = false,
  collectionKey,
  searchKey,
  excludeIds = [],
  error,
  autoFocus = false,
  texts,
  icon: Icon,
  xs = false,
  customWhere,
  clearValue = null,
  disabled = false,
}: SearchComboboxProps<T>) {
  const { t } = useIntl();

  texts = {
    selectItemMsg: t('searchCombobox.selectItemMsg'),
    searchPlaceholder: t('searchCombobox.searchPlaceholder'),
    noResultsMsg: t('searchCombobox.noResultsMsg'),
    searchingMsg: t('searchCombobox.searchingMsg'),
    errorMsg: t('searchCombobox.errorMsg'),
    loadMoreMsg: t('searchCombobox.loadMoreMsg'),
    ...texts,
  };

  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const { ref: loadMoreRef, inView } = useInView();

  const { data: selectedItem } = useInfiniteQuery({
    queryKey: [collectionKey, 'byId', value],
    queryFn: async () => {
      if (!value) return null;
      const item = await payloadClient.findById({
        collection: collectionKey,
        id: value,
      });
      return {
        value: item.id,
        label: item[searchKey] as string,
      };
    },
    enabled: !!value,
    getNextPageParam: () => undefined,
    initialPageParam: 1,
  });

  const {
    data: searchResults,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [collectionKey, searchKey, debouncedSearchQuery, excludeIds, customWhere],
    queryFn: async ({ pageParam }) => {
      const baseWhere: Where = {
        and: [
          ...(debouncedSearchQuery
            ? [
                {
                  [searchKey]: {
                    like: debouncedSearchQuery,
                  },
                },
              ]
            : []),
          {
            id: {
              not_in: excludeIds,
            },
          },
        ],
      };

      const finalWhere = customWhere ? { and: [baseWhere, customWhere] } : baseWhere;

      const response = await payloadClient.find({
        collection: collectionKey,
        where: finalWhere,
        limit: ITEMS_PER_PAGE,
        page: pageParam,
      });
      return {
        items: response.docs.map((item) => ({
          value: item.id,
          label: item[searchKey] as string,
        })),
        nextPage: response.hasNextPage ? pageParam + 1 : undefined,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
    enabled: open,
    initialPageParam: 1,
  });

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const handleSelectResult = (selectedValue: string) => {
    onSelect(selectedValue);
    setOpen(false);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus && !disabled) {
      setOpen(true);
      inputRef.current?.focus();
    }
  }, [autoFocus, disabled]);

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    onSelect(clearValue);
  };

  const flattenedResults = searchResults?.pages.flatMap((page) => page.items) || [];

  return (
    <Popover open={open && !disabled} onOpenChange={(isOpen) => !disabled && setOpen(isOpen)}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn('justify-between px-3', className, error && 'border-red-500', xs && 'h-8')}
          disabled={disabled}
        >
          {selectedItem ? (
            <>
              {selectedItem.pages[0]?.label}
              <X
                onClick={handleClear}
                className={cn('ml-2 shrink-0 opacity-50 hover:opacity-100', xs ? 'size-3.5' : 'size-4')}
              />
            </>
          ) : (
            <>
              <div className="flex items-center gap-2">
                {Icon && <Icon className={cn('shrink-0 opacity-50', xs ? 'size-3.5' : 'size-4')} />}
                <span className="text-muted-foreground">{texts?.selectItemMsg}</span>
              </div>

              <ChevronsUpDown className={cn('ml-2 shrink-0 opacity-50', xs ? 'size-3.5' : 'size-4')} />
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent style={popOverStyles} className="p-0">
        <Command shouldFilter={false}>
          <CommandInput
            ref={inputRef}
            placeholder={texts?.searchPlaceholder}
            value={searchQuery}
            onValueChange={setSearchQuery}
          />
          <ScrollArea className="max-h-[300px] overflow-auto">
            <CommandList>
              {isLoading ? (
                <CommandEmpty>{texts?.searchingMsg}</CommandEmpty>
              ) : isError ? (
                <CommandEmpty>{texts?.errorMsg}</CommandEmpty>
              ) : (
                <CommandEmpty>{texts?.noResultsMsg}</CommandEmpty>
              )}
              <CommandGroup>
                {unselect && (
                  <CommandItem value="" onSelect={() => handleSelectResult('')}>
                    <Check className={cn('mr-2 h-4 w-4', value === '' ? 'opacity-100' : 'opacity-0')} />
                    {t('searchCombobox.unselectMsg')}
                  </CommandItem>
                )}
                {flattenedResults.map((item) => (
                  <CommandItem key={item.value} value={item.label} onSelect={() => handleSelectResult(item.value)}>
                    <Check
                      className={cn('mr-2 h-4 w-4 shrink-0', value === item.value ? 'opacity-100' : 'opacity-0')}
                    />
                    <span className="min-w-0 truncate">{item.label}</span>
                  </CommandItem>
                ))}
                {hasNextPage && (
                  <CommandItem ref={loadMoreRef} disabled>
                    {isFetchingNextPage ? 'Loading more...' : texts?.loadMoreMsg || 'Load more'}
                  </CommandItem>
                )}
              </CommandGroup>
            </CommandList>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
