import type { RouteProp } from '@/types/routeProp';
import { Backpack, FileBox, Package2, Settings, Tags } from 'lucide-react';

export const customerRoutes: RouteProp[] = [
  { to: '/c/my-backpacks', icon: Backpack, label: 'myBackpacks' },
  { to: '/c/settings', icon: Settings, label: 'settings' },
] as const;

export const juhRoutes: RouteProp[] = [
  { to: '/juh/articles', icon: Package2, label: 'articles' },
  { to: '/juh/products', icon: FileBox, label: 'products' },
  { to: '/juh/product-categories', icon: Tags, label: 'productCategories' },
  { to: '/juh/settings', icon: Settings, label: 'settings' },
] as const;
