import { useProductCategories } from '@/api/productCategories';
import { useDeleteProduct, useProducts } from '@/api/products';
import { FormatedInterval } from '@/components/shared/format/Intervals';
import { SearchField } from '@/components/shared/input/SearchField';
import { HeaderPortal } from '@/components/shared/layout/Header';
import { DataTablePagination } from '@/components/shared/table/DataTableNavigation';
import { useAuth } from '@/utils';
import { useDebounce } from '@/utils/hooks/useDebounce';
import type { Product } from '@johanniter-offshore/types';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tabs,
  TabsList,
  TabsTrigger,
} from '@johanniter-offshore/ui';
import type { ColumnDef, ColumnFiltersState, Row, SortingState, VisibilityState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useIntl } from '@tiny-intl/react';
import { MoreHorizontal, PlusCircle } from 'lucide-react';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const ProductCategoryCell = ({ row }: { row: Row<Product> }) => {
  const { data: categories } = useProductCategories({ limit: 0 });
  const categoryId = row.getValue('productCategory') as string;
  const category = categories?.docs.find((cat) => cat.id === categoryId);
  return <div>{category?.name || '-'}</div>;
};

const ActionCell = ({ product, onDelete }: { product: Product; onDelete: (product: Product) => void }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useIntl();
  const isJuhAdmin = user?.role === 'juh-admin';
  const isDeleteDisabled = (product.articleCount ?? 0) > 0;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="size-8 p-0">
          <span className="sr-only">{t('openMenu')}</span>
          <MoreHorizontal className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('actions')}</DropdownMenuLabel>
        <DropdownMenuItem onClick={() => navigate(`/juh/products/${product.id}`)}>{t('edit')}</DropdownMenuItem>
        <DropdownMenuItem disabled={!isJuhAdmin || isDeleteDisabled} onClick={() => onDelete(product)}>
          {t('delete')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const ProductsOverview = () => {
  const { t } = useIntl();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'all' | 'container' | 'single'>('all');

  const columns: ColumnDef<Product>[] = [
    {
      accessorKey: 'name',
      header: t('name'),
      cell: ({ row }) => <div>{row.getValue('name')}</div>,
    },
    {
      accessorKey: 'productCategory',
      header: t('category'),
      cell: ({ row }) => <ProductCategoryCell row={row} />,
    },
    {
      accessorKey: 'testInterval',
      header: t('testInterval'),
      cell: ({ row }) => {
        const testInterval = row.getValue('testInterval') as string;
        if (!testInterval) return <div>-</div>;

        return <FormatedInterval interval={testInterval} prefixFormat="every" />;
      },
    },
    {
      accessorKey: 'isContainer',
      header: t('isContainer'),
      cell: ({ row }) => {
        const isContainer = row.getValue('isContainer') as boolean;
        return <div>{isContainer ? t('yes') : t('no')}</div>;
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => <ActionCell product={row.original} onDelete={setProductToDelete} />,
    },
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const handleSearchChange = useCallback((value: string) => {
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, pageIndex: 0 })); // Reset to first page on search
  }, []);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: productsData, isLoading } = useProducts({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    where: {
      ...(activeTab === 'all' ? {} : { isContainer: { equals: activeTab === 'container' } }),
      ...(debouncedSearchTerm
        ? { or: [{ name: { like: debouncedSearchTerm } }, { 'productCategory.name': { like: debouncedSearchTerm } }] }
        : {}),
    },
  });

  const products = productsData?.docs || [];
  const totalPages = productsData?.totalPages || 0;

  const table = useReactTable({
    data: products,
    columns,
    pageCount: totalPages,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const deleteProduct = useDeleteProduct();
  const [productToDelete, setProductToDelete] = useState<Product | null>(null);

  const confirmDeleteProduct = async () => {
    if (productToDelete) {
      try {
        await deleteProduct.mutateAsync(productToDelete.id);
        toast.success(t('productDeletedSuccessfully'));
        setProductToDelete(null);
      } catch (error) {
        toast.error(t('errorDeletingProduct'));
      }
    }
  };

  return (
    <>
      <HeaderPortal>
        <SearchField searchTerm={searchTerm} setSearchTerm={handleSearchChange} />
      </HeaderPortal>
      <div>
        <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value as 'all' | 'container' | 'single')}>
          <div className="flex items-center">
            <TabsList>
              <TabsTrigger value="all">{t('allProducts')}</TabsTrigger>
              <TabsTrigger value="container">{t('containerProducts')}</TabsTrigger>
              <TabsTrigger value="single">{t('singleProducts')}</TabsTrigger>
            </TabsList>
            <div className="ml-auto flex items-center gap-2">
              <Button size="sm" className="h-8 gap-1" onClick={() => navigate('/juh/products/new')}>
                <PlusCircle className="size-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('addProduct')}</span>
              </Button>
            </div>
          </div>
        </Tabs>
        <Card className="mt-2">
          <CardHeader>
            <CardTitle>{t('products')}</CardTitle>
            <CardDescription>{t('manageProducts')}</CardDescription>
          </CardHeader>
          <CardContent>
            <div>
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableHead key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHead>
                      ))}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={columns.length} className="h-24 text-center">
                        {t('loading')}
                      </TableCell>
                    </TableRow>
                  ) : table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} className="h-24 text-center">
                        {t('noResults')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </CardContent>
          <CardFooter>
            <DataTablePagination table={table} />
          </CardFooter>
        </Card>
      </div>

      <AlertDialog open={!!productToDelete} onOpenChange={(open) => !open && setProductToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('deleteProduct')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('deleteProductConfirmation', { name: productToDelete?.name || '' })}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t('cancel')}</AlertDialogCancel>
            <AlertDialogAction onClick={confirmDeleteProduct}>{t('delete')}</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export { ProductsOverview };
