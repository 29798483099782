import type { Invite } from '@johanniter-offshore/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { payloadClient } from '.';
import type { Where } from './types';

export const useInvites = (params?: {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof Invite | `-${keyof Invite}`;
  disabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['invites', params],
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'invites',
        ...params,
      });
      return response;
    },
  });
};

export const useCreateInvite = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<Invite>) => {
      const response = await payloadClient.create({
        collection: 'invites',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invites'] });
    },
  });
};

export const useUpdateInvite = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<Invite> }) => {
      const response = await payloadClient.updateById({
        collection: 'invites',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invites'] });
    },
  });
};

export const useDeleteInvite = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const response = await payloadClient.deleteById({
        collection: 'invites',
        id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invites'] });
    },
  });
};
