import { Link } from 'react-router-dom';

export const Logo = () => (
  <Link
    to="/"
    className="bg-primary text-primary-foreground group flex size-8 shrink-0 items-center justify-center gap-2 rounded-full text-lg font-semibold md:text-base"
  >
    <img
      src="/johanniter-logo-small.svg"
      alt="johanniter logo"
      className="size-7 transition-all group-hover:scale-110"
    />
  </Link>
);
