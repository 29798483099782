import type { RouteProp } from '@/types/routeProp';

import { SidebarLink } from '../navigation/SidebarLink';
import { Logo } from './Logo';

interface SidebarProps {
  routes: RouteProp[];
}

export const Sidebar = ({ routes }: SidebarProps) => (
  <aside className="bg-background fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r sm:flex">
    <nav className="flex flex-col items-center gap-4 px-2 py-4">
      <Logo />
      {routes.map((route) => (
        <SidebarLink key={route.to} to={route.to} icon={route.icon} label={route.label} />
      ))}
    </nav>
  </aside>
);
