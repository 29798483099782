import { useUpdateUser } from '@/api/users';
import { useAuth } from '@/utils/hooks/useAuth';
import { useTheme } from '@/utils/hooks/useTheme';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { Globe, SunMoon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

const userFormSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  language: z.enum(['de-DE', 'en-US']),
  themeMode: z.enum(['light', 'dark', 'system']),
});

type UserFormValues = z.infer<typeof userFormSchema>;

export function AccountForm() {
  const { t } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const { user: currentUser, updateUser } = useAuth();
  const updateUserMutation = useUpdateUser();
  const { mode: currentThemeMode, setThemeMode } = useTheme();

  const form = useForm<UserFormValues>({
    resolver: zodResolver(userFormSchema),
    defaultValues: {
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      language: currentUser?.language || 'de-DE',
      themeMode: currentThemeMode || 'system',
    },
  });

  useEffect(() => {
    if (currentUser) {
      form.reset({
        firstName: currentUser.firstName || '',
        lastName: currentUser.lastName || '',
        language: currentUser.language || 'de-DE',
        themeMode: currentThemeMode,
      });
    }
  }, [currentUser, form, currentThemeMode]);

  const handleSubmit = async (data: UserFormValues) => {
    if (!currentUser) return;
    setIsLoading(true);
    try {
      const newUser = await updateUserMutation.mutateAsync({
        id: currentUser.id,
        data: { firstName: data.firstName, lastName: data.lastName, language: data.language },
      });
      updateUser(newUser);
      setThemeMode(data.themeMode);
      toast.success(t('userUpdatedSuccessfully'));
    } catch (error) {
      form.setError('root', { type: 'manual', message: t('unknownError') });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('myAccount')}</CardTitle>
        <CardDescription>{t('editAccountDescription')}</CardDescription>
      </CardHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <CardContent>
            <div className="grid gap-4">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('firstName')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('lastName')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="language"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('language')}</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <div className="flex items-center gap-3">
                            <Globe className="size-4 " />
                            <SelectValue placeholder={t('selectLanguage')} />
                          </div>
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="de-DE">Deutsch</SelectItem>
                        <SelectItem value="en-US">English</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="themeMode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('themeMode')}</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <div className="flex items-center gap-3">
                            <SunMoon className="size-4" />
                            <SelectValue placeholder={t('selectThemeMode')} />
                          </div>
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="light">{t('light')}</SelectItem>
                        <SelectItem value="dark">{t('dark')}</SelectItem>
                        <SelectItem value="system">{t('system')}</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </CardContent>
          <CardFooter className="border-t px-6 py-4">
            <Button type="submit" disabled={isLoading}>
              {isLoading ? t('saving') : t('save')}
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
}
