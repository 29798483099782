import { useDeleteProductCategory, useProductCategories } from '@/api/productCategories';
import type { TreeDataItem } from '@/components/juh/trees/Tree';
import { Tree } from '@/components/juh/trees/Tree';
import { useAuth } from '@/utils';
import type { ProductCategory } from '@johanniter-offshore/types';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { PlusCircle } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const transformCategoriesToTreeData = (categories: ProductCategory[]): TreeDataItem[] => {
  const categoryMap = new Map<string, TreeDataItem & { children: TreeDataItem[] }>(
    categories.map((category) => [category.id, { ...category, children: [] }]),
  );

  const rootCategories: TreeDataItem[] = [];

  categoryMap.forEach((category) => {
    if ('parent' in category && category.parent) {
      const parentCategory = categoryMap.get(category.parent as string);
      if (parentCategory) {
        parentCategory.children.push(category as TreeDataItem);
      }
    } else {
      rootCategories.push(category as TreeDataItem);
    }
  });

  return rootCategories;
};

export function ProductCategoriesOverview() {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const isJuhAdmin = currentUser?.role === 'juh-admin';
  const navigate = useNavigate();
  const { data: categoriesData, isLoading } = useProductCategories({
    limit: 0,
  });
  const deleteProductCategory = useDeleteProductCategory();
  const [categoryToDelete, setCategoryToDelete] = useState<TreeDataItem | null>(null);

  const treeData = categoriesData ? transformCategoriesToTreeData(categoriesData.docs) : [];

  const handleEditCategory = (category: TreeDataItem) => {
    navigate(`/juh/product-categories/${category.id}`);
  };
  const handleCreateCategory = () => {
    navigate('/juh/product-categories/new');
  };
  const handleDeleteCategory = (category: TreeDataItem) => {
    setCategoryToDelete(category);
  };

  const confirmDeleteCategory = async () => {
    if (categoryToDelete) {
      try {
        await deleteProductCategory.mutateAsync(categoryToDelete.id);
        toast.success(t('productCategoryDeletedSuccessfully'));
        setCategoryToDelete(null);
      } catch (error) {
        toast.error(t('errorDeletingProductCategory'));
      }
    }
  };

  return (
    <div className="grid gap-6">
      <Card>
        <CardHeader>
          <CardTitle>{t('productCategories')}</CardTitle>
          <CardDescription>{t('productCategoriesDescription')}</CardDescription>
        </CardHeader>
        <CardContent className="border-t p-0">
          {isLoading ? (
            <div className="text-muted-foreground flex flex-col items-center justify-center p-6 text-center text-sm">
              {t('loading')}
            </div>
          ) : treeData.length > 0 ? (
            <Tree
              data={treeData}
              className="w-full"
              onEditTreeItem={handleEditCategory}
              onDeleteTreeItem={handleDeleteCategory}
              disableActions={!isJuhAdmin}
            />
          ) : (
            <div className="flex flex-col items-center justify-center p-6 text-center">
              <p className="text-muted-foreground mb-4 text-sm">{t('noProductCategories')}</p>
              <Button size="sm" className="h-8 gap-1 " onClick={handleCreateCategory}>
                <PlusCircle className="size-3.5" />
                <span>{t('addFirstCategory')}</span>
              </Button>
            </div>
          )}
        </CardContent>
      </Card>

      {!isLoading && treeData.length > 0 && (
        <div className="mx-auto flex items-center gap-2">
          <Button size="sm" className="h-8 gap-1" disabled={!isJuhAdmin} onClick={handleCreateCategory}>
            <PlusCircle className="size-3.5" />
            <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('addCategory')}</span>
          </Button>
        </div>
      )}

      <AlertDialog open={!!categoryToDelete} onOpenChange={(open) => !open && setCategoryToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('deleteProductCategory')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('deleteProductCategoryConfirmation', { name: categoryToDelete?.name || '' })}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t('cancel')}</AlertDialogCancel>
            <AlertDialogAction onClick={confirmDeleteCategory}>{t('delete')}</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
