import { useGlobalMetadata, useUpdateGlobalMetadata } from '@/api/globalMetadata';
import type { FormSchema } from '@/components/shared/metadata/MetadataBuilder';
import {
  MetadataBuilder,
  formFieldsToJsonSchema,
  formSchema,
  handleMetadataValidationErrors,
  jsonSchemaToFormFields,
} from '@/components/shared/metadata/MetadataBuilder';
import { useAuth } from '@/utils/hooks/useAuth';
import { zodResolver } from '@hookform/resolvers/zod';
import type { JsonSchemaType } from '@johanniter-offshore/types';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';

export function GlobalMetadata() {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const { data: globalMetadata, isLoading } = useGlobalMetadata();
  const updateGlobalMetadata = useUpdateGlobalMetadata();

  const isJuhAdmin = currentUser?.role === 'juh-admin';

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      metadata: globalMetadata ? jsonSchemaToFormFields(globalMetadata.metadata as JsonSchemaType) : [],
    },
  });

  useEffect(() => {
    if (globalMetadata && !isLoading) {
      form.reset({
        metadata: jsonSchemaToFormFields(globalMetadata.metadata as JsonSchemaType),
      });
    }
  }, [globalMetadata, isLoading, form]);

  const handleSubmit = async (data: FormSchema) => {
    if (!isJuhAdmin) return;
    const jsonSchema = formFieldsToJsonSchema(data.metadata);
    try {
      await updateGlobalMetadata.mutateAsync({ metadata: jsonSchema });
      toast.success(t('globalMetadataUpdatedSuccessfully'));
    } catch (error) {
      handleMetadataValidationErrors(error, data.metadata, jsonSchema, form, t);
    }
  };

  return (
    <div className="grid gap-6">
      <Card>
        <CardHeader>
          <CardTitle>{t('globalMetadata')}</CardTitle>
          <CardDescription>{t('globalMetadataDescription')}</CardDescription>
        </CardHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <CardContent>
              {isLoading ? <div>{t('loading')}</div> : <MetadataBuilder disabled={!isJuhAdmin} />}
            </CardContent>
            <CardFooter className="border-t px-6 py-4">
              <Button type="submit" disabled={updateGlobalMetadata.isPending || !isJuhAdmin}>
                {updateGlobalMetadata.isPending ? t('saving') : t('save')}
              </Button>
            </CardFooter>
          </form>
        </Form>
      </Card>
    </div>
  );
}
