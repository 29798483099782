import { payloadClient } from '@/api';
import { useCreateInvite, useDeleteInvite, useInvites, useUpdateInvite } from '@/api/invites';
import { useUpdateUser, useUsers } from '@/api/users';
import { SettingsNavPortal } from '@/components/shared/navigation/SettingsNav';
import { useAuth } from '@/utils/hooks/useAuth';
import type { Invite, User } from '@johanniter-offshore/types';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { PlusCircle } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import type { UserFormValues } from './UserForm';
import { UserForm } from './UserForm';
import { UserTable } from './UserTable';

interface UserManagementProps {
  allowedRoles: ('customer-admin' | 'customer-employee' | 'juh-admin' | 'juh-employee')[];
}

export const UserManagement = ({ allowedRoles }: UserManagementProps) => {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const [page] = useState(1);

  const onlyReadPermissions = currentUser?.role === 'customer-employee' || currentUser?.role === 'juh-employee';

  const { data: usersData, isLoading: isLoadingUsers } = useUsers({
    page,
    limit: 0,
    sort: 'createdAt',
    where: {
      role: {
        in: allowedRoles,
      },
    },
  });
  const { data: invitesData, isLoading: isLoadingInvites } = useInvites({
    page,
    limit: 0,
    sort: 'createdAt',
    where: {
      organization: currentUser?.organization ? { equals: currentUser.organization } : { exists: false },
    },
    disabled: onlyReadPermissions,
  });

  const [selectedUser, setSelectedUser] = useState<User | (Invite & { isInvite: true }) | undefined>(undefined);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const updateUser = useUpdateUser();
  const updateInvite = useUpdateInvite();
  const createInvite = useCreateInvite();
  const deleteInvite = useDeleteInvite();

  const handleEditUser = (user: User | (Invite & { isInvite: true })) => {
    if (!onlyReadPermissions) {
      setSelectedUser(user);
      setIsEditDialogOpen(true);
    }
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedUser(undefined);

    setTimeout(() => {
      document.body.style.pointerEvents = '';
    }, 200);
  };

  const handleSubmit = async (data: UserFormValues) => {
    if (!allowedRoles.includes(data.role)) {
      toast.error(t('invalidRole'));
      return;
    }

    if (onlyReadPermissions) return;

    if (selectedUser) {
      if ('isInvite' in selectedUser && selectedUser.isInvite) {
        await updateInvite.mutateAsync({
          id: selectedUser.id,
          data: {
            ...data,
            email: undefined,
          },
        });
        toast.success(t('userUpdatedSuccessfully'));
      } else {
        await updateUser.mutateAsync({
          id: selectedUser.id,
          data: {
            ...data,
            language: undefined,
            email: undefined,
          },
        });
        toast.success(t('userUpdatedSuccessfully'));
      }

      handleCloseEditDialog();
    } else {
      await createInvite.mutateAsync(data);
      setIsInviteDialogOpen(false);
      toast.success(t('invitationSentSuccessfully'));
    }
  };

  const handleDelete = async (user: User | (Invite & { isInvite: true })) => {
    if (onlyReadPermissions) return;

    if ('isInvite' in user && user.isInvite) {
      await deleteInvite.mutateAsync(user.id);
      toast.success(t('invitationDeletedSuccessfully'));
    }
  };

  const handleResend = async (invite: User | (Invite & { isInvite: true })) => {
    if (onlyReadPermissions) return;

    const toastId = toast.loading(t('resendingInvitation'));
    try {
      await payloadClient.customRequest({
        subpath: '/invites/resend-invite',
        method: 'POST',
        data: { inviteId: invite.id },
      });
      toast.success(t('invitationResentSuccessfully'), { id: toastId });
    } catch (error) {
      toast.error(t('errorResendingInvitation'), { id: toastId });
    }
  };

  const isLoading = isLoadingUsers || (!onlyReadPermissions && isLoadingInvites);

  const combinedData = [
    ...(usersData?.docs ?? []),
    ...(!onlyReadPermissions
      ? (invitesData?.docs?.map((invite: Invite) => ({
          ...invite,
          isInvite: true,
        })) ?? [])
      : []),
  ];

  return (
    <div className="grid gap-6">
      {!onlyReadPermissions && (
        <SettingsNavPortal>
          <Dialog open={isInviteDialogOpen} onOpenChange={setIsInviteDialogOpen}>
            <DialogTrigger asChild>
              <Button size="sm" className="h-8 gap-1">
                <PlusCircle className="size-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('inviteUser')}</span>
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>{t('inviteUser')}</DialogTitle>
                <DialogDescription>{t('inviteUserDescription')}</DialogDescription>
              </DialogHeader>
              <UserForm onSubmit={handleSubmit} allowedRoles={allowedRoles} />
            </DialogContent>
          </Dialog>
        </SettingsNavPortal>
      )}
      <Card>
        <CardHeader>
          <CardTitle>{t('users')}</CardTitle>
          <CardDescription>{t('manageUsers')}</CardDescription>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <p>{t('loading')}</p>
          ) : (
            <UserTable users={combinedData} onEdit={handleEditUser} onDelete={handleDelete} onResend={handleResend} />
          )}
        </CardContent>
      </Card>

      {!onlyReadPermissions && (
        <Dialog open={isEditDialogOpen} onOpenChange={handleCloseEditDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{t('editUser')}</DialogTitle>
              <DialogDescription>{t('editUserDescription')}</DialogDescription>
            </DialogHeader>
            <UserForm user={selectedUser} onSubmit={handleSubmit} allowedRoles={allowedRoles} />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};
