import { useCreateStation, useDeleteStation, useStations, useUpdateStation } from '@/api/stations';
import { SettingsNavPortal } from '@/components/shared/navigation/SettingsNav';
import type { Station } from '@johanniter-offshore/types';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { PlusCircle } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import type { StationFormValues } from './StationForm';
import { StationForm } from './StationForm';
import { StationTable } from './StationTable';

export const StationManagement = () => {
  const { t } = useIntl();
  const [page] = useState(1);
  const [selectedStation, setSelectedStation] = useState<Station | undefined>(undefined);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const { data: stationsData, isLoading } = useStations({
    page,
    limit: 0,
    sort: 'name',
    where: {
      organization: {
        exists: false,
      },
    },
  });

  const createStation = useCreateStation();
  const updateStation = useUpdateStation();
  const deleteStation = useDeleteStation();

  const handleEditStation = (station: Station) => {
    setSelectedStation(station);
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedStation(undefined);

    setTimeout(() => {
      document.body.style.pointerEvents = '';
    }, 200);
  };

  const handleSubmit = async (data: StationFormValues) => {
    if (selectedStation) {
      await updateStation.mutateAsync({
        id: selectedStation.id,
        data,
      });
      toast.success(t('stationUpdatedSuccessfully'));
      handleCloseEditDialog();
    } else {
      await createStation.mutateAsync(data);
      setIsCreateDialogOpen(false);
      toast.success(t('stationCreatedSuccessfully'));
    }
  };

  const handleDelete = async (station: Station) => {
    await deleteStation.mutateAsync(station.id);
    toast.success(t('stationDeletedSuccessfully'));
  };

  return (
    <div className="grid gap-6">
      <SettingsNavPortal>
        <Dialog open={isCreateDialogOpen} onOpenChange={setIsCreateDialogOpen}>
          <DialogTrigger asChild>
            <Button size="sm" className="h-8 gap-1">
              <PlusCircle className="size-3.5" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('createStation')}</span>
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{t('createStation')}</DialogTitle>
              <DialogDescription>{t('createStationDescription')}</DialogDescription>
            </DialogHeader>
            <StationForm onSubmit={handleSubmit} />
          </DialogContent>
        </Dialog>
      </SettingsNavPortal>

      <Card>
        <CardHeader>
          <CardTitle>{t('stations')}</CardTitle>
          <CardDescription>{t('manageStations')}</CardDescription>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <p>{t('loading')}</p>
          ) : (
            <StationTable stations={stationsData?.docs ?? []} onEdit={handleEditStation} onDelete={handleDelete} />
          )}
        </CardContent>
      </Card>

      <Dialog open={isEditDialogOpen} onOpenChange={handleCloseEditDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('editStation')}</DialogTitle>
            <DialogDescription>{t('editStationDescription')}</DialogDescription>
          </DialogHeader>
          <StationForm station={selectedStation} onSubmit={handleSubmit} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
