import type { ProductCategory } from '@johanniter-offshore/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { payloadClient } from '.';
import type { Where } from './types';

export const useProductCategories = (params?: {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof ProductCategory | `-${keyof ProductCategory}`;
  disabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['productCategories', params],
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'product-categories',
        ...params,
      });
      return response;
    },
  });
};

export const useCreateProductCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<ProductCategory>) => {
      const response = await payloadClient.create({
        collection: 'product-categories',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['productCategories'] });
    },
  });
};

export const useUpdateProductCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<ProductCategory> }) => {
      const response = await payloadClient.updateById({
        collection: 'product-categories',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['productCategories'] });
    },
  });
};

export const useDeleteProductCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const response = await payloadClient.deleteById({
        collection: 'product-categories',
        id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['productCategories'] });
    },
  });
};
