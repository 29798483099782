import { SettingsNav } from '@/components/shared/navigation/SettingsNav';
import { useIntl } from '@tiny-intl/react';
import { Outlet } from 'react-router-dom';

export function Settings() {
  const { t } = useIntl();

  const tabs = [
    { value: 'users', label: t('users') },
    { value: 'stations', label: t('stations') },
    { value: 'global-metadata', label: t('globalMetadata') },
  ];

  return (
    <SettingsNav tabs={tabs} basePath="/juh/settings">
      <Outlet />
    </SettingsNav>
  );
}

export default Settings;
