import { useUploadMedia } from '@/api/media';
import { useProductCategories } from '@/api/productCategories';
import { useCreateProduct } from '@/api/products';
import { ChildProductsCard } from '@/components/juh/products/ChildProductsCard';
import { ContainerProductCard } from '@/components/juh/products/ContainerProductCard';
import { ProductCategoryCard } from '@/components/juh/products/ProductCategoryCard';
import { ProductDetailsCard } from '@/components/juh/products/ProductDetailsCard';
import { ProductFilesCard } from '@/components/juh/products/ProductFilesCard';
import { ProductInspectionsCard } from '@/components/juh/products/ProductInspectionsCard';
import {
  MetadataBuilder,
  formFieldsToJsonSchema,
  metadataFieldSchema,
} from '@/components/shared/metadata/MetadataBuilder';
import { useAuth } from '@/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import type { Product } from '@johanniter-offshore/types';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Form,
  Step,
  Stepper,
  useStepper,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';

export const detailsSchema = z.object({
  name: z.string().min(1),
  description: z.string().optional(),
});
export type DetailsFormValues = z.infer<typeof detailsSchema>;

export const categorySchema = z.object({
  productCategory: z.string().nullable(),
});
export type CategoryFormValues = z.infer<typeof categorySchema>;

export const productInspectionsSchema = z.object({
  name: z.string().min(1),
  interval: z.string().min(1),
  checklist: z
    .array(
      z.object({
        item: z.string().min(1),
        order: z.number().int().min(0),
        id: z.string().nullable().optional(),
      }),
    )
    .nullable()
    .optional(),
  id: z.string().nullable().optional(),
});
export type ProductInspections = z.infer<typeof productInspectionsSchema>;

export const productInpsectionsFormSchema = z.object({
  productInspections: z.array(productInspectionsSchema),
});
export type ProductInspectionsFormValues = z.infer<typeof productInpsectionsFormSchema>;

export const metadataSchema = z.object({
  metadata: z.array(metadataFieldSchema),
});
export type MetadataFormValues = z.infer<typeof metadataSchema>;

export const containerAndChildProductsSchema = z.object({
  isContainer: z.boolean().default(false),
  childProducts: z.array(
    z.object({
      product: z.string(),
      quantity: z.number().min(1),
      order: z.number().optional(),
    }),
  ),
});
export type ContainerAndChildProductsFormValues = z.infer<typeof containerAndChildProductsSchema>;

export const productFilesSchema = z.object({
  files: z.array(
    z.object({
      name: z.string().min(1),
      description: z.string().optional(),
      file: z.union([z.instanceof(File), z.string()]).optional(),
      id: z.string().optional(),
    }),
  ),
});
export type ProductFilesFormValues = z.infer<typeof productFilesSchema>;

export function CreateProduct() {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const createProduct = useCreateProduct();
  const navigate = useNavigate();
  const { data: categoriesData } = useProductCategories({ limit: 0 });
  const uploadMedia = useUploadMedia();

  const isJuhAdmin = currentUser?.role === 'juh-admin';

  const detailsForm = useForm<DetailsFormValues>({
    resolver: zodResolver(detailsSchema),
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const categoryForm = useForm<CategoryFormValues>({
    resolver: zodResolver(categorySchema),
    defaultValues: {
      productCategory: null,
    },
  });

  const productInspectionsForm = useForm<ProductInspectionsFormValues>({
    resolver: zodResolver(productInpsectionsFormSchema),
    defaultValues: {
      productInspections: [],
    },
  });

  const metadataForm = useForm<MetadataFormValues>({
    resolver: zodResolver(metadataSchema),
    defaultValues: {
      metadata: [],
    },
  });

  const containerAndChildProductsForm = useForm<ContainerAndChildProductsFormValues>({
    resolver: zodResolver(containerAndChildProductsSchema),
    defaultValues: {
      isContainer: false,
      childProducts: [],
    },
  });

  const productFilesForm = useForm<ProductFilesFormValues>({
    resolver: zodResolver(productFilesSchema),
    defaultValues: {
      files: [],
    },
  });

  const detailsSubmitRef = useRef<HTMLButtonElement>(null);
  const categorySubmitRef = useRef<HTMLButtonElement>(null);
  const productInspectionsSubmitRef = useRef<HTMLButtonElement>(null);
  const metadataSubmitRef = useRef<HTMLButtonElement>(null);
  const containerAndChildProductsSubmitRef = useRef<HTMLButtonElement>(null);
  const productFilesSubmitRef = useRef<HTMLButtonElement>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);

    const detailsData = detailsForm.getValues();
    const categoryData = categoryForm.getValues();
    const productInspectionsData = productInspectionsForm.getValues();
    const metadataData = metadataForm.getValues();
    const containerAndChildProductsData = containerAndChildProductsForm.getValues();
    const productFilesData = productFilesForm.getValues();

    const jsonSchema = formFieldsToJsonSchema(metadataData.metadata);
    try {
      // Upload files first
      const uploadedFiles = await Promise.all(
        productFilesData.files.map(async (fileData) => {
          if (!fileData.file || !(fileData.file instanceof File)) {
            throw new Error('File is missing');
          }

          const uploadedFile = await uploadMedia.mutateAsync({
            file: fileData.file,
            data: {},
          });
          return { file: uploadedFile.doc.id, name: fileData.name, description: fileData.description };
        }),
      );

      const payload: Partial<Product> = {
        ...detailsData,
        ...categoryData,
        ...productInspectionsData,
        metadata: jsonSchema,
        isContainer: containerAndChildProductsData.isContainer,
        childProducts: containerAndChildProductsData.isContainer
          ? containerAndChildProductsData.childProducts
              ?.filter((product) => product.product && product.quantity)
              .map((product, index) => ({ ...product, order: index }))
          : undefined,
        productFiles: uploadedFiles,
      };

      await createProduct.mutateAsync(payload);
      toast.success(t('productCreatedDescription'));
      navigate('/juh/products');
    } catch (error) {
      if (error && typeof error === 'object' && 'name' in error && error.name === 'PayloadApiError') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const payloadError = error as { response?: { errors?: Array<{ message: string; data?: any }> } };
        const maxDepthError = payloadError.response?.errors?.find((e) => e.message === 'MaxProductDepthExceeded');

        if (maxDepthError) {
          toast.error(t('maxDepthErrorTitle'), {
            description: t('maxDepthErrorDescription'),
            duration: 25000,
            closeButton: true,
            dismissible: true,
          });

          const errorData = maxDepthError.data;
          const childProductId = errorData?.childProduct;
          const index = containerAndChildProductsData.childProducts?.findIndex((p) => p.product === childProductId);
          if (index !== undefined && index !== -1) {
            containerAndChildProductsForm.setError(`childProducts.${index}.product`, {
              type: 'manual',
              message: t('maxDepthErrorDescription'),
            });
          }
          return;
        }
      }
      toast.error(t('errorSavingProductDescription'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const steps = [
    { label: t('productDetails') },
    { label: t('productCategory') },
    { label: t('productInspections') },
    { label: t('metadata') },
    { label: t('containerProduct') },
    { label: t('productFiles') },
  ];

  return (
    <div
      className={`
        grid gap-6

        md:grid-cols-1
      `}
    >
      <div
        className={`
          mx-auto w-full max-w-[59rem] p-4

          sm:px-6
        `}
      >
        <h1 className="mb-6 text-xl font-semibold tracking-tight">{t('newProduct')}</h1>

        <Stepper initialStep={0} steps={steps} orientation="vertical">
          <Step label={t('productDetails')}>
            <Form {...detailsForm}>
              <form onSubmit={detailsForm.handleSubmit(() => {})}>
                <ProductDetailsCard control={detailsForm.control} />
                <Button type="submit" ref={detailsSubmitRef} className="hidden" />
              </form>
            </Form>
          </Step>

          <Step label={t('productCategory')}>
            <Form {...categoryForm}>
              <form onSubmit={categoryForm.handleSubmit(() => {})}>
                <ProductCategoryCard
                  control={categoryForm.control}
                  setValue={categoryForm.setValue}
                  categoriesData={categoriesData}
                />
                <Button type="submit" ref={categorySubmitRef} className="hidden" />
              </form>
            </Form>
          </Step>

          <Step label={t('productInspections')}>
            <Form {...productInspectionsForm}>
              <form onSubmit={productInspectionsForm.handleSubmit(() => {})}>
                <ProductInspectionsCard control={productInspectionsForm.control} />
                <Button type="submit" ref={productInspectionsSubmitRef} className="hidden" />
              </form>
            </Form>
          </Step>

          <Step label={t('metadata')}>
            <Form {...metadataForm}>
              <form onSubmit={metadataForm.handleSubmit(() => {})}>
                <Card>
                  <CardHeader>
                    <CardTitle>{t('metadata')}</CardTitle>
                    <CardDescription>{t('productMetadataDescription')}</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <MetadataBuilder disabled={!isJuhAdmin} />
                  </CardContent>
                </Card>
                <Button type="submit" ref={metadataSubmitRef} className="hidden" />
              </form>
            </Form>
          </Step>

          <Step label={t('containerProduct')}>
            <Form {...containerAndChildProductsForm}>
              <form onSubmit={containerAndChildProductsForm.handleSubmit(() => {})}>
                <ContainerProductCard control={containerAndChildProductsForm.control} />
                {containerAndChildProductsForm.watch('isContainer') && (
                  <ChildProductsCard control={containerAndChildProductsForm.control} />
                )}
                <Button type="submit" ref={containerAndChildProductsSubmitRef} className="hidden" />
              </form>
            </Form>
          </Step>

          <Step label={t('productFiles')}>
            <Form {...productFilesForm}>
              <form onSubmit={productFilesForm.handleSubmit(() => {})}>
                <ProductFilesCard />
                <Button type="submit" ref={productFilesSubmitRef} className="hidden" />
              </form>
            </Form>
          </Step>

          <Footer
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            detailsForm={detailsForm}
            categoryForm={categoryForm}
            productInspectionsForm={productInspectionsForm}
            metadataForm={metadataForm}
            containerAndChildProductsForm={containerAndChildProductsForm}
            productFilesForm={productFilesForm}
            detailsSubmitRef={detailsSubmitRef}
            categorySubmitRef={categorySubmitRef}
            productInspectionsSubmitRef={productInspectionsSubmitRef}
            metadataSubmitRef={metadataSubmitRef}
            containerAndChildProductsSubmitRef={containerAndChildProductsSubmitRef}
            productFilesSubmitRef={productFilesSubmitRef}
          />
        </Stepper>
      </div>
    </div>
  );
}

const Footer = ({
  onSubmit,
  isSubmitting,
  detailsForm,
  categoryForm,
  productInspectionsForm,
  metadataForm,
  containerAndChildProductsForm,
  productFilesForm,
  detailsSubmitRef,
  categorySubmitRef,
  productInspectionsSubmitRef,
  metadataSubmitRef,
  containerAndChildProductsSubmitRef,
  productFilesSubmitRef,
}: {
  onSubmit: () => Promise<void>;
  isSubmitting: boolean;
  detailsForm: UseFormReturn<DetailsFormValues>;
  categoryForm: UseFormReturn<CategoryFormValues>;
  productInspectionsForm: UseFormReturn<ProductInspectionsFormValues>;
  metadataForm: UseFormReturn<MetadataFormValues>;
  containerAndChildProductsForm: UseFormReturn<ContainerAndChildProductsFormValues>;
  productFilesForm: UseFormReturn<ProductFilesFormValues>;
  detailsSubmitRef: React.RefObject<HTMLButtonElement | null>;
  categorySubmitRef: React.RefObject<HTMLButtonElement | null>;
  productInspectionsSubmitRef: React.RefObject<HTMLButtonElement | null>;
  metadataSubmitRef: React.RefObject<HTMLButtonElement | null>;
  containerAndChildProductsSubmitRef: React.RefObject<HTMLButtonElement | null>;
  productFilesSubmitRef: React.RefObject<HTMLButtonElement | null>;
}) => {
  const {
    nextStep,
    prevStep,
    resetSteps,
    isDisabledStep,
    hasCompletedAllSteps,
    isLastStep,
    isOptionalStep,
    activeStep,
  } = useStepper();
  const { t } = useIntl();

  const handleNextStep = async () => {
    let isValid = false;
    let currentForm;
    let currentSubmitRef;

    switch (activeStep) {
      case 0:
        currentForm = detailsForm;
        currentSubmitRef = detailsSubmitRef;
        break;
      case 1:
        currentForm = categoryForm;
        currentSubmitRef = categorySubmitRef;
        break;
      case 2:
        currentForm = productInspectionsForm;
        currentSubmitRef = productInspectionsSubmitRef;
        break;
      case 3:
        currentForm = metadataForm;
        currentSubmitRef = metadataSubmitRef;
        break;
      case 4:
        currentForm = containerAndChildProductsForm;
        currentSubmitRef = containerAndChildProductsSubmitRef;
        break;
      case 5:
        currentForm = productFilesForm;
        currentSubmitRef = productFilesSubmitRef;
        break;
      default:
        isValid = true;
        break;
    }

    if (currentForm && currentSubmitRef?.current) {
      currentSubmitRef.current.click();
      isValid = await currentForm.trigger();
      const nativeValid = currentSubmitRef.current.form?.checkValidity();
      isValid = isValid && (nativeValid as boolean);
    }

    if (isValid) {
      nextStep();
    }
  };

  const handleSubmit = async () => {
    let isValid = true;
    if (productFilesSubmitRef.current) {
      productFilesSubmitRef.current.click();
      isValid = await productFilesForm.trigger();
      const nativeValid = productFilesSubmitRef.current.form?.checkValidity();
      isValid = isValid && (nativeValid as boolean);
    }

    if (isValid) {
      await onSubmit();
    }
  };

  return (
    <div className="mt-4">
      {hasCompletedAllSteps && (
        <div
          className={`
            bg-secondary text-primary my-2 flex h-40 items-center justify-center rounded-md border
          `}
        >
          <h1 className="text-xl">{t('allStepsCompleted')}</h1>
        </div>
      )}
      <div className="flex w-full justify-end gap-2">
        {hasCompletedAllSteps ? (
          <Button size="sm" onClick={resetSteps}>
            {t('reset')}
          </Button>
        ) : (
          <>
            <Button disabled={isDisabledStep || isSubmitting} onClick={prevStep} size="sm" variant="secondary">
              {t('previous')}
            </Button>
            <Button size="sm" onClick={isLastStep ? handleSubmit : handleNextStep} disabled={isSubmitting}>
              {isLastStep
                ? isSubmitting
                  ? t('creating')
                  : t('createProduct')
                : isOptionalStep
                  ? t('skip')
                  : t('next')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
