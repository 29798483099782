import AutoFormObject from '@johanniter-offshore/ui/components/auto-form/fields/object';
import type { UseFormReturn } from 'react-hook-form';
import type { z } from 'zod';

type MetadataAutoFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: z.ZodObject<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
};

export function MetadataAutoForm({ schema, form }: MetadataAutoFormProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <AutoFormObject schema={schema} form={form} />;
}
