import type { RouteProp } from '@/types/routeProp';
import type React from 'react';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { BreadcrumbNav } from '../navigation/BreadcrumbNav';
import { MobileMenu } from '../navigation/MobileMenu';
import { UserMenu } from '../user/UserMenu';

interface HeaderPortalProps {
  children: ReactNode;
}

export const HeaderPortal: React.FC<HeaderPortalProps> = ({ children }: { children: ReactNode }) => {
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const container = document.getElementById('header-portal-container');
    if (container) {
      setPortalContainer(container);
    }
  }, []);

  if (!portalContainer) return null;

  return createPortal(children, portalContainer);
};

interface HeaderProps {
  routes: RouteProp[];
}

export const Header: React.FC<HeaderProps> = ({ routes }) => (
  <header className="bg-background sticky top-0 z-30 flex h-14 items-center gap-4 border-b px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
    <MobileMenu routes={routes} />
    <BreadcrumbNav />
    <div id="header-portal-container" className="relative ml-auto flex-1 md:grow-0"></div>
    <UserMenu />
  </header>
);
