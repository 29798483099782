import type { TinyIntlDict } from '@tiny-intl/core';

export type I18nSupportedLocales = 'en-US' | 'de-DE';

export const supportedLocales: I18nSupportedLocales[] = ['en-US', 'de-DE'];

export const load = async (locale: I18nSupportedLocales) => {
  let messages: TinyIntlDict = {};

  if (locale === 'en-US') {
    messages = (await import('./entries/en-US')).messages;
  } else if (locale === 'de-DE') {
    messages = (await import('./entries/de-DE')).messages;
  }

  return {
    messages,
  };
};
