import { Button } from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface ArticleFormHeaderProps {
  isEditMode?: boolean;
}

export function ArticleFormHeader({ isEditMode = false }: ArticleFormHeaderProps) {
  const { t } = useIntl();
  const navigate = useNavigate();

  const headerText = isEditMode ? t('editArticle') : t('newArticle');

  return (
    <div className="mb-6 flex items-center gap-4">
      <Button variant="outline" size="icon" className="size-7" onClick={() => navigate('/juh/articles')}>
        <ChevronLeft className="size-4" />
        <span className="sr-only">Back</span>
      </Button>
      <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight">{headerText}</h1>
    </div>
  );
}
