import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from '@tiny-intl/react';
import { toast } from 'sonner';

export function useArticleErrorHandling() {
  const { t } = useIntl();
  const queryClient = useQueryClient();

  const handleArticleError = (error: unknown) => {
    if (error && typeof error === 'object' && 'name' in error && error.name === 'PayloadApiError') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const payloadError = error as { response?: { errors?: Array<{ message: string; data?: any }> } };
      const childArticleAlreadyAssignedError = payloadError.response?.errors?.find(
        (e) => e.message === 'ChildArticleAlreadyAssigned',
      );
      if (childArticleAlreadyAssignedError) {
        toast.error(t('childArticleAlreadyAssignedError'), {
          description: t('childArticleAlreadyAssignedErrorDescription'),
          duration: 25000,
          closeButton: true,
          dismissible: true,
        });

        queryClient.invalidateQueries({ queryKey: ['articles'] });
        return true;
      }
    }
    return false;
  };

  return { handleArticleError };
}
