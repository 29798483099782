import { useCreateProductCategory, useProductCategories, useUpdateProductCategory } from '@/api/productCategories';
import {
  MetadataBuilder,
  formFieldsToJsonSchema,
  handleMetadataValidationErrors,
  jsonSchemaToFormFields,
  metadataFieldSchema,
} from '@/components/shared/metadata/MetadataBuilder';
import { useAuth } from '@/utils/hooks/useAuth';
import { zodResolver } from '@hookform/resolvers/zod';
import type { JsonSchemaType } from '@johanniter-offshore/types';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  cn,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { Check, ChevronLeft, ChevronsUpDown, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';

const productCategoryFormSchema = z.object({
  name: z.string().min(1),
  description: z.string().optional(),
  metadata: z.array(metadataFieldSchema),
  parentId: z.string().optional().nullable(),
});

type ProductCategoryFormValues = z.infer<typeof productCategoryFormSchema>;

export function ManageProductCategory() {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const createProductCategory = useCreateProductCategory();
  const updateProductCategory = useUpdateProductCategory();
  const navigate = useNavigate();
  const { data: categoriesData } = useProductCategories({ limit: 0 });
  const [open, setOpen] = useState(false);
  const { id } = useParams<{ id: string }>();
  const isEditMode = !!id;

  const isJuhAdmin = currentUser?.role === 'juh-admin';

  const form = useForm<ProductCategoryFormValues>({
    resolver: zodResolver(productCategoryFormSchema),
    defaultValues: {
      name: '',
      description: '',
      metadata: [],
      parentId: null,
    },
  });

  useEffect(() => {
    if (isEditMode && categoriesData) {
      const category = categoriesData.docs.find((cat) => cat.id === id);
      if (category) {
        form.reset({
          name: category.name,
          description: category.description || '',
          metadata: jsonSchemaToFormFields(category.metadata as JsonSchemaType),
          parentId: (category.parent as string) || null,
        });
      }
    }
  }, [isEditMode, id, categoriesData, form]);

  const handleSubmit = async (data: ProductCategoryFormValues) => {
    if (!isJuhAdmin) return;
    const jsonSchema = formFieldsToJsonSchema(data.metadata);

    try {
      const payload = {
        name: data.name,
        description: data.description,
        metadata: jsonSchema,
        parent: data.parentId || null,
      };

      if (isEditMode) {
        await updateProductCategory.mutateAsync({ id, data: payload });
        toast.success(t('productCategoryUpdatedSuccessfully'));
      } else {
        await createProductCategory.mutateAsync(payload);
        toast.success(t('productCategoryCreatedSuccessfully'));
      }
      navigate('/juh/product-categories');
    } catch (error: unknown) {
      if (error && typeof error === 'object' && 'name' in error && error.name === 'PayloadApiError') {
        const payloadError = error as { response?: { errors?: Array<{ message: string }> } };
        const errorMessage = payloadError.response?.errors?.[0]?.message;

        switch (errorMessage) {
          case 'MaxCategoryDepthExceeded':
            form.setError('parentId', { type: 'manual', message: t('maxProductCategoryDepthExceeded') });
            break;
          case 'SelfParentCategoryError':
            form.setError('parentId', { type: 'manual', message: t('selfParentProductCategoryError') });
            break;
          case 'CircularReferenceError':
            form.setError('parentId', { type: 'manual', message: t('circularProductCategoryReferenceError') });
            break;
          case 'ParentChangeRequiredFieldsError':
            form.setError('parentId', { type: 'manual', message: t('parentChangeRequiredFieldsError') });
            break;
          case 'MetadataUpdateValidationFailed':
            handleMetadataValidationErrors(error, data.metadata, jsonSchema, form, t);
            break;
          default:
            form.setError('root', { type: 'manual', message: t('unknownError') });
        }
      } else {
        form.setError('root', { type: 'manual', message: t('unknownError') });
      }
    }
  };

  return (
    <div className="mx-auto w-full max-w-[59rem] p-4 sm:px-6">
      <div className="mb-6 flex items-center gap-4">
        <Button variant="outline" size="icon" className="size-7" onClick={() => navigate('/juh/product-categories')}>
          <ChevronLeft className="size-4" />
          <span className="sr-only">Back</span>
        </Button>
        <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight">
          {isEditMode ? t('editProductCategory') : t('newProductCategory')}
        </h1>
        <div className="flex items-center gap-2">
          <Button variant="outline" size="sm" onClick={() => navigate('/juh/product-categories')}>
            {t('discard')}
          </Button>
          <Button
            size="sm"
            onClick={form.handleSubmit(handleSubmit)}
            disabled={(isEditMode ? updateProductCategory.isPending : createProductCategory.isPending) || !isJuhAdmin}
          >
            {isEditMode
              ? updateProductCategory.isPending
                ? t('updating')
                : t('update')
              : createProductCategory.isPending
                ? t('creating')
                : t('create')}
          </Button>
        </div>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="grid gap-6 md:grid-cols-2">
          <Card className="md:col-span-2">
            <CardHeader>
              <CardTitle>{t('productCategoryDetails')}</CardTitle>
              <CardDescription>{t('productCategoryDetailsDescription')}</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('productCategoryName')}</FormLabel>
                      <FormControl>
                        <Input {...field} disabled={!isJuhAdmin} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('productCategoryDescription')}</FormLabel>
                      <FormControl>
                        <Input {...field} disabled={!isJuhAdmin} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </CardContent>
          </Card>
          <Card className="md:col-span-2">
            <CardHeader>
              <CardTitle>{t('metadata')}</CardTitle>
              <CardDescription>{t('productCategoryMetadataDescription')}</CardDescription>
            </CardHeader>
            <CardContent>
              <MetadataBuilder disabled={!isJuhAdmin} />
            </CardContent>
          </Card>
          <Card className="md:col-span-2">
            <CardHeader>
              <CardTitle>{t('parentProductCategory')}</CardTitle>
              <CardDescription>{t('parentCategoryDescription')}</CardDescription>
            </CardHeader>
            <CardContent>
              <FormField
                control={form.control}
                name="parentId"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>{t('parentProductCategory')}</FormLabel>
                    <Popover open={open} onOpenChange={setOpen}>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            className={cn('w-full justify-between', !field.value && 'text-muted-foreground')}
                            disabled={!isJuhAdmin}
                            onClick={() => setOpen(!open)}
                          >
                            {field.value
                              ? categoriesData?.docs.find((category) => category.id === field.value)?.name
                              : t('selectParentProductCategory')}

                            {field.value ? (
                              <X
                                onClick={(e) => {
                                  e.stopPropagation();
                                  form.setValue('parentId', null);
                                }}
                                className="size-4 opacity-50 hover:opacity-100"
                              />
                            ) : (
                              <ChevronsUpDown className="size-4 opacity-50" />
                            )}
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-[300px] p-0">
                        <Command value={field.value || undefined}>
                          <CommandInput placeholder={t('searchProductCategory')} />
                          <CommandList>
                            <CommandEmpty>{t('noProductCategoryFound')}</CommandEmpty>
                            <CommandGroup>
                              {categoriesData?.docs.map((category) => (
                                <CommandItem
                                  value={category.name}
                                  key={category.id}
                                  onSelect={() => {
                                    form.setValue('parentId', category.id);
                                    setOpen(false);
                                  }}
                                >
                                  <Check
                                    className={cn(
                                      'mr-2 h-4 w-4',
                                      category.id === field.value ? 'opacity-100' : 'opacity-0',
                                    )}
                                  />
                                  {category.name}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
          </Card>
        </form>
      </Form>
    </div>
  );
}
