import { useStations } from '@/api/stations';
import { useAuth } from '@/utils';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { useState } from 'react';

export function Stations() {
  const { t } = useIntl();
  const [page] = useState(1);
  const { user } = useAuth();
  const { data: stationsData, isLoading } = useStations({
    page,
    limit: 0,
    sort: 'createdAt',
    where: { organization: { equals: user?.organization } },
    disabled: !user,
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('stations')}</CardTitle>
        <CardDescription>{t('viewStations')}</CardDescription>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <p>{t('loading')}</p>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t('name')}</TableHead>
                <TableHead>{t('description')}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {stationsData?.docs.map((station) => (
                <TableRow key={station.id}>
                  <TableCell>{station.name}</TableCell>
                  <TableCell>{station.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            {stationsData?.docs.length === 0 && <TableCaption>{t('noStationsCreated')}</TableCaption>}
          </Table>
        )}
      </CardContent>
    </Card>
  );
}
