import { AuthProvider, useAuth } from '@/utils/hooks';
import { match } from '@formatjs/intl-localematcher';
import { Toaster, TooltipProvider } from '@johanniter-offshore/ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createTinyIntl } from '@tiny-intl/core';
import { TinyIntlContext, useIntl } from '@tiny-intl/react';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useMount } from 'react-use';

import { AppRouter } from './Router';
import type { I18nSupportedLocales } from './locales';
import { load, supportedLocales } from './locales';
import { ThemeProvider } from './utils/hooks/useTheme';

const queryClient = new QueryClient();

export const intl = createTinyIntl<string>({
  fallbackLocale: 'de-DE',
  supportedLocales,
  loadDict: async (nextLoc) => {
    const dict = await load(nextLoc as I18nSupportedLocales);
    return dict.messages;
  },
  detectLocale: (detection) => {
    const storedLocale = localStorage.getItem('selectedLocale');
    if (storedLocale && detection.supportedLocales.includes(storedLocale)) {
      return storedLocale as I18nSupportedLocales;
    }
    return match(
      [navigator.language, ...navigator.languages],
      [...detection.supportedLocales],
      detection.fallbackLocale,
    ) as I18nSupportedLocales;
  },
});

intl.subscribe(() => {
  const htmlEl = document.querySelector('html');
  if (htmlEl) {
    htmlEl.lang = intl.locale;
  }
  i18next.changeLanguage(intl.locale);
});

function I18nLoader() {
  const { change, locale } = useIntl();
  const { user } = useAuth();

  const [i18nMounted, setI18nMounted] = useState(false);

  useMount(() => {
    setI18nMounted(false);
    intl
      .mount()
      .then(() => {
        setI18nMounted(true);
      })
      .catch(() => {});
  });

  useEffect(() => {
    if (i18nMounted && user) {
      const newLocale = user?.language || localStorage.getItem('selectedLocale') || 'de-DE';
      if (locale !== newLocale) {
        change(newLocale);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18nMounted, user]);

  return <div className={!i18nMounted ? 'fixed inset-0 z-[49]' : ''} />;
}

export function App() {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <TinyIntlContext.Provider value={intl}>
            <TooltipProvider>
              <AppRouter />
            </TooltipProvider>
            <I18nLoader />
            <Toaster duration={2000} />
          </TinyIntlContext.Provider>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
