import { useCreateArticle } from '@/api/articles';
import { useUploadMedia } from '@/api/media';
import { useProduct, useProducts, useResolvedProductMetadata } from '@/api/products';
import { ArticleAssignmentCard } from '@/components/juh/articles/ArticleAssignmentCard';
import { ArticleChildren } from '@/components/juh/articles/ArticleChildren';
import { ArticleDatesCard } from '@/components/juh/articles/ArticleDatesCard';
import { ArticleDetailsCard } from '@/components/juh/articles/ArticleDetailsCard';
import { ArticleFilesCard } from '@/components/juh/articles/ArticleFilesCard';
import { ArticleFormHeader } from '@/components/juh/articles/ArticleFormHeader';
import { ArticleMetadataCard } from '@/components/juh/articles/ArticleMetadataCard';
import { useArticleErrorHandling } from '@/utils/articleErrorHandling';
import { zodResolver } from '@hookform/resolvers/zod';
import type { Article, Product } from '@johanniter-offshore/types';
import { Button, Form, Step, Stepper, useStepper } from '@johanniter-offshore/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from '@tiny-intl/react';
import jsonSchemaToZod from 'json-schema-to-zod';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { UseFormReturn } from 'react-hook-form/dist/types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';

export const detailsSchema = z.object({
  product: z.string().min(1),
});
export type DetailsFormValues = z.infer<typeof detailsSchema>;

export const datesSchema = z.object({
  expiryDate: z.date().nullable(),
  testInterval: z.string().nullable(),
  lastTestDate: z.date().nullable(),
});
export type DatesFormValues = z.infer<typeof datesSchema>;

export const assignmentSchema = z.object({
  organization: z.string().nullable(),
  station: z.string().nullable(),
});
export type AssignmentFormValues = z.infer<typeof assignmentSchema>;

export const articleFilesSchema = z.object({
  articleFiles: z.array(
    z.object({
      name: z.string().min(1),
      description: z.string().optional(),
      file: z.union([z.instanceof(File), z.string()]).optional(),
      id: z.string().optional(),
    }),
  ),
});
export type ArticleFilesFormValues = z.infer<typeof articleFilesSchema>;

export function CreateArticle() {
  const { t } = useIntl();
  const createArticle = useCreateArticle();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { handleArticleError } = useArticleErrorHandling();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [metadataSchema, setMetadataSchema] = useState<z.ZodObject<any, any>>(z.object({}));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [childArticlesSchema, setChildArticlesSchema] = useState<z.ZodObject<any, any>>(z.object({}));

  const detailsForm = useForm<DetailsFormValues>({
    resolver: zodResolver(detailsSchema),
    defaultValues: { product: '' },
  });

  const datesForm = useForm<DatesFormValues>({
    resolver: zodResolver(datesSchema),
    defaultValues: {
      expiryDate: null,
      testInterval: null,
      lastTestDate: null,
    },
  });

  const assignmentForm = useForm<AssignmentFormValues>({
    resolver: zodResolver(assignmentSchema),
    defaultValues: {
      organization: null,
      station: null,
    },
  });

  const metadataForm = useForm({
    resolver: zodResolver(metadataSchema),
  });

  const childArticlesForm = useForm({
    resolver: zodResolver(childArticlesSchema),
  });

  const articleFilesForm = useForm<ArticleFilesFormValues>({
    resolver: zodResolver(articleFilesSchema),
    defaultValues: {
      articleFiles: [],
    },
  });

  const detailsSubmitRef = useRef<HTMLButtonElement>(null);
  const datesSubmitRef = useRef<HTMLButtonElement>(null);
  const assignmentSubmitRef = useRef<HTMLButtonElement>(null);
  const metadataSubmitRef = useRef<HTMLButtonElement>(null);
  const childArticlesSubmitRef = useRef<HTMLButtonElement>(null);
  const articleFilesSubmitRef = useRef<HTMLButtonElement>(null);

  const { data: productData } = useProduct(detailsForm.watch('product'), { disabled: !detailsForm.watch('product') });
  const { data: productMetadata } = useResolvedProductMetadata(productData?.id);

  const { data: childProducts } = useProducts({
    where: {
      id: {
        in: productData?.childProducts?.map((child) => child.product as string) || [],
      },
    },
    limit: 0,
    disabled: !productData || !productData.childProducts,
  });

  const childProductsWithCount = useMemo(() => {
    if (!childProducts || !productData?.childProducts) return [];
    return childProducts.docs.map((childProduct: Product) => {
      const composition = productData.childProducts?.find((comp) => comp.product === childProduct.id);
      return {
        product: childProduct,
        requiredCount: composition ? composition.quantity : 0,
      };
    });
  }, [childProducts, productData?.childProducts]);

  useEffect(() => {
    if (childProductsWithCount) {
      const childProducts: { [key: string]: z.ZodArray<z.ZodString> } = {};
      childProductsWithCount.forEach((childProduct) => {
        childProducts[childProduct.product.id] = z
          .array(z.string())
          .min(childProduct.requiredCount)
          .max(childProduct.requiredCount);
      });

      setChildArticlesSchema(z.object(childProducts));
    }
  }, [childProductsWithCount]);

  useEffect(() => {
    if (productMetadata) {
      const zodSchemaString = jsonSchemaToZod(productMetadata);
      const transformedSchema = zodSchemaString.replace(/z\.string\(\)\.datetime\({ offset: true }\)/g, 'z.date()');

      const zodSchema = eval(transformedSchema);
      setMetadataSchema(zodSchema);
      metadataForm.reset({});
    }
  }, [productMetadata, metadataForm]);

  useEffect(() => {
    if (productData?.isContainer) {
      datesForm.setValue('expiryDate', null);
    }
  }, [productData?.isContainer, datesForm]);

  const uploadMedia = useUploadMedia();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      const detailsData = detailsForm.getValues();
      const datesData = datesForm.getValues();
      const assignmentData = assignmentForm.getValues();
      const metadataData = metadataForm.getValues();
      const articleFilesData = articleFilesForm.getValues();
      const childArticlesData = childArticlesForm.getValues();

      const { data: parsedMetadata } = metadataSchema.safeParse(metadataData);

      // Upload files first
      const uploadedFiles = await Promise.all(
        articleFilesData.articleFiles.map(async (fileData) => {
          if (!fileData.file || !(fileData.file instanceof File)) {
            throw new Error('File is missing');
          }

          const uploadedFile = await uploadMedia.mutateAsync({
            file: fileData.file,
            data: {},
          });
          return { file: uploadedFile.doc.id, name: fileData.name, description: fileData.description };
        }),
      );

      const payload: Partial<Article> = {
        ...detailsData,
        ...datesData,
        expiryDate: datesData.expiryDate ? DateTime.fromJSDate(datesData.expiryDate).toISO() : null,
        lastTestDate: datesData.lastTestDate ? DateTime.fromJSDate(datesData.lastTestDate).toISO() : null,
        ...assignmentData,
        metadata: parsedMetadata,
        children: Object.entries(childArticlesData)
          .map(([productId, articleIds]) => articleIds.map((id: string) => ({ article: id, product: productId })))
          .flat(),
        articleFiles: uploadedFiles,
      };

      await createArticle.mutateAsync(payload);
      toast.success(t('articleCreatedDescription'));
      queryClient.invalidateQueries({ queryKey: ['articles'] });
      navigate('/juh/articles');
    } catch (error) {
      if (!handleArticleError(error)) {
        toast.error(t('errorSavingArticleDescription'));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const steps = useMemo(() => {
    const steps = [
      { label: t('articleDetails') },
      { label: t('articleDates') },
      { label: t('articleAssignment') },
      { label: t('articleMetadata') },
      { label: t('articleFiles') },
    ];

    if (productData?.isContainer) {
      steps.push({ label: t('articleChildren') });
    }

    return steps;
  }, [t, productData?.isContainer]);

  return (
    <div
      className={`
        grid gap-6

        md:grid-cols-1
      `}
    >
      <div
        className={`
          mx-auto w-full max-w-[59rem] p-4

          sm:px-6
        `}
      >
        <ArticleFormHeader />

        <Stepper initialStep={0} steps={steps} orientation="vertical">
          <Step label={t('articleDetails')}>
            <Form {...detailsForm}>
              <form onSubmit={detailsForm.handleSubmit(() => {})}>
                <ArticleDetailsCard control={detailsForm.control} />
                <Button type="submit" ref={detailsSubmitRef} className="hidden" />
              </form>
            </Form>
          </Step>

          <Step label={t('articleDates')}>
            <Form {...datesForm}>
              <form onSubmit={datesForm.handleSubmit(() => {})}>
                <ArticleDatesCard control={datesForm.control} disableExpiryDate={!!productData?.isContainer} />
                <Button type="submit" ref={datesSubmitRef} className="hidden" />
              </form>
            </Form>
          </Step>

          <Step label={t('articleAssignment')}>
            <Form {...assignmentForm}>
              <form onSubmit={assignmentForm.handleSubmit(() => {})}>
                <ArticleAssignmentCard
                  control={assignmentForm.control}
                  watch={assignmentForm.watch}
                  setValue={assignmentForm.setValue}
                />
                <Button type="submit" ref={assignmentSubmitRef} className="hidden" />
              </form>
            </Form>
          </Step>

          <Step label={t('articleMetadata')}>
            <Form {...metadataForm}>
              <form onSubmit={metadataForm.handleSubmit(() => {})}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <ArticleMetadataCard schema={metadataSchema} form={metadataForm} />
                <Button type="submit" ref={metadataSubmitRef} className="hidden" />
              </form>
            </Form>
          </Step>

          <Step label={t('articleFiles')}>
            <Form {...articleFilesForm}>
              <form onSubmit={articleFilesForm.handleSubmit(() => {})}>
                <ArticleFilesCard />
                <Button type="submit" ref={articleFilesSubmitRef} className="hidden" />
              </form>
            </Form>
          </Step>

          {productData?.isContainer && (
            <Step label={t('articleChildren')}>
              <Form {...childArticlesForm}>
                <form onSubmit={childArticlesForm.handleSubmit(() => {})}>
                  <ArticleChildren
                    childProductsWithCount={childProductsWithCount}
                    stationId={assignmentForm.watch('station')}
                  />
                  <Button type="submit" ref={childArticlesSubmitRef} className="hidden" />
                </form>
              </Form>
            </Step>
          )}

          <Footer
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            detailsForm={detailsForm}
            datesForm={datesForm}
            assignmentForm={assignmentForm}
            metadataForm={metadataForm}
            childArticlesForm={childArticlesForm}
            articleFilesForm={articleFilesForm}
            productData={productData}
            detailsSubmitRef={detailsSubmitRef}
            datesSubmitRef={datesSubmitRef}
            assignmentSubmitRef={assignmentSubmitRef}
            metadataSubmitRef={metadataSubmitRef}
            childArticlesSubmitRef={childArticlesSubmitRef}
            articleFilesSubmitRef={articleFilesSubmitRef}
          />
        </Stepper>
      </div>
    </div>
  );
}

const Footer = ({
  onSubmit,
  isSubmitting,
  detailsForm,
  datesForm,
  assignmentForm,
  metadataForm,
  childArticlesForm,
  articleFilesForm,
  productData,
  detailsSubmitRef,
  datesSubmitRef,
  assignmentSubmitRef,
  metadataSubmitRef,
  childArticlesSubmitRef,
  articleFilesSubmitRef,
}: {
  onSubmit: () => Promise<void>;
  isSubmitting: boolean;
  detailsForm: UseFormReturn<DetailsFormValues>;
  datesForm: UseFormReturn<DatesFormValues>;
  assignmentForm: UseFormReturn<AssignmentFormValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadataForm: UseFormReturn<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  childArticlesForm: UseFormReturn<any>;
  articleFilesForm: UseFormReturn<ArticleFilesFormValues>;
  productData?: Product;
  detailsSubmitRef: React.RefObject<HTMLButtonElement | null>;
  datesSubmitRef: React.RefObject<HTMLButtonElement | null>;
  assignmentSubmitRef: React.RefObject<HTMLButtonElement | null>;
  metadataSubmitRef: React.RefObject<HTMLButtonElement | null>;
  childArticlesSubmitRef: React.RefObject<HTMLButtonElement | null>;
  articleFilesSubmitRef: React.RefObject<HTMLButtonElement | null>;
}) => {
  const {
    nextStep,
    prevStep,
    resetSteps,
    isDisabledStep,
    hasCompletedAllSteps,
    isLastStep,
    isOptionalStep,
    activeStep,
  } = useStepper();
  const { t } = useIntl();

  const handleNextStep = async () => {
    let isValid = false;
    let currentForm;
    let currentSubmitRef;

    switch (activeStep) {
      case 0:
        currentForm = detailsForm;
        currentSubmitRef = detailsSubmitRef;
        break;
      case 1:
        currentForm = datesForm;
        currentSubmitRef = datesSubmitRef;
        break;
      case 2:
        currentForm = assignmentForm;
        currentSubmitRef = assignmentSubmitRef;
        break;
      case 3:
        currentForm = metadataForm;
        currentSubmitRef = metadataSubmitRef;
        break;
      case 4:
        currentForm = articleFilesForm;
        currentSubmitRef = articleFilesSubmitRef;
        break;
      case 5:
        if (productData?.isContainer) {
          currentForm = childArticlesForm;
          currentSubmitRef = childArticlesSubmitRef;
        }
        break;
      default:
        isValid = true;
        break;
    }

    if (currentForm && currentSubmitRef?.current) {
      currentSubmitRef.current.click();
      isValid = await currentForm.trigger();
      const nativeValid = currentSubmitRef.current.form?.checkValidity();
      isValid = isValid && (nativeValid as boolean);
    }

    if (isValid) {
      nextStep();
    }
  };

  const handleSubmit = async () => {
    let isValid = true;
    if (productData?.isContainer && childArticlesSubmitRef.current) {
      childArticlesSubmitRef.current.click();
      isValid = await childArticlesForm.trigger();
      const nativeValid = childArticlesSubmitRef.current.form?.checkValidity();
      isValid = isValid && (nativeValid as boolean);
    } else if (articleFilesSubmitRef.current) {
      articleFilesSubmitRef.current.click();
      isValid = await articleFilesForm.trigger();
      const nativeValid = articleFilesSubmitRef.current.form?.checkValidity();
      isValid = isValid && (nativeValid as boolean);
    }

    if (isValid) {
      await onSubmit();
    }
  };

  return (
    <div className="mt-4">
      {hasCompletedAllSteps && (
        <div
          className={`
            bg-secondary text-primary my-2 flex h-40 items-center justify-center rounded-md border
          `}
        >
          <h1 className="text-xl">{t('allStepsCompleted')}</h1>
        </div>
      )}
      <div className="flex w-full justify-end gap-2">
        {hasCompletedAllSteps ? (
          <Button size="sm" onClick={resetSteps}>
            {t('reset')}
          </Button>
        ) : (
          <>
            <Button disabled={isDisabledStep || isSubmitting} onClick={prevStep} size="sm" variant="secondary">
              {t('previous')}
            </Button>
            <Button size="sm" onClick={isLastStep ? handleSubmit : handleNextStep} disabled={isSubmitting}>
              {isLastStep
                ? isSubmitting
                  ? t('creating')
                  : t('createArticle')
                : isOptionalStep
                  ? t('skip')
                  : t('next')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
