import { Header } from '@/components/shared/layout/Header';
import { Sidebar } from '@/components/shared/layout/Sidebar';
import { customerRoutes } from '@/constants';
import { Outlet } from 'react-router-dom';

export function CustomerLayout() {
  return (
    <div className="bg-muted/40 flex min-h-screen w-full flex-col">
      <Sidebar routes={customerRoutes} />
      <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
        <Header routes={customerRoutes} />
        <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <Outlet />
        </main>
      </div>
    </div>
  );
}
