import { Tabs, TabsContent, TabsList, TabsTrigger } from '@johanniter-offshore/ui';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';

interface TabItem {
  value: string;
  label: string;
}

interface SettingsNavProps {
  children: ReactNode;
  tabs: TabItem[];
  basePath: string;
  rightContent?: ReactNode;
}

export const SettingsNavPortal = ({ children }: { children: ReactNode }) => {
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const container = document.getElementById('settings-nav-portal-container');
    if (container) {
      setPortalContainer(container);
    }
  }, []);

  if (!portalContainer) return null;

  return createPortal(children, portalContainer);
};

export function SettingsNav({ children, tabs, basePath, rightContent }: SettingsNavProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPath, setCurrentPath] = useState<string | undefined>(undefined);

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    setCurrentPath(path);
  }, [location]);

  const handleTabChange = (value: string) => {
    navigate(`${basePath}/${value}`);
  };

  return (
    <Tabs value={currentPath} className="w-full" onValueChange={handleTabChange}>
      <div className="flex items-center justify-between">
        <TabsList>
          {tabs.map((tab) => (
            <TabsTrigger key={tab.value} value={tab.value}>
              {tab.label}
            </TabsTrigger>
          ))}
        </TabsList>
        <div id="settings-nav-portal-container" className="ml-auto flex items-center">
          {rightContent && <SettingsNavPortal>{rightContent}</SettingsNavPortal>}
        </div>
      </div>
      {tabs.map((tab) => (
        <TabsContent key={tab.value} value={tab.value}>
          {children}
        </TabsContent>
      ))}
    </Tabs>
  );
}
