import { SettingsNav } from '@/components/shared/navigation/SettingsNav';
import { useIntl } from '@tiny-intl/react';
import { Outlet } from 'react-router-dom';

export function Settings() {
  const { t } = useIntl();

  const tabs = [
    { value: 'organization', label: t('organization') },
    { value: 'users', label: t('users') },
    { value: 'stations', label: t('stations') },
  ];

  return (
    <SettingsNav tabs={tabs} basePath="/c/settings">
      <Outlet />
    </SettingsNav>
  );
}

export default Settings;
