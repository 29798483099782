import { SearchCombobox } from '@/components/juh/inputs/SearchCombobox';
import type { ContainerAndChildProductsFormValues } from '@/routes/application/juh/products/CreateProduct';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  cn,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { ArrowDown, ArrowUp, ExternalLink, Plus, Trash } from 'lucide-react';
import { useState } from 'react';
import type { Control } from 'react-hook-form';
import { useFieldArray, useWatch } from 'react-hook-form';

interface ChildProductsCardProps {
  control: Control<ContainerAndChildProductsFormValues>;
  currentProductId?: string;
  disabled?: boolean;
}

export function ChildProductsCard({ control, currentProductId, disabled = false }: ChildProductsCardProps) {
  const { t } = useIntl();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'childProducts',
    keyName: 'fieldId',
  });

  const [lastAddedIndex, setLastAddedIndex] = useState<number | null>(null);

  const childProducts = useWatch({
    control,
    name: 'childProducts',
  });

  const childProductValues = useWatch({
    control,
    name: 'childProducts',
  });

  const getExcludeIds = (currentIndex: number) => {
    const excludeIds = childProducts
      ?.filter((_, index) => index !== currentIndex)
      .map((product) => product.product)
      .filter((id): id is string => id !== '');

    if (currentProductId) {
      excludeIds?.push(currentProductId);
    }

    return excludeIds;
  };

  return (
    <Card className="mt-2">
      <CardHeader>
        <CardTitle>{t('childProducts')}</CardTitle>
        <CardDescription>{t('childProductsDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[120px]">{t('move')}</TableHead>
              <TableHead>{t('product')}</TableHead>
              <TableHead className="w-[50px]">{t('quantity')}</TableHead>
              <TableHead className="w-[50px]">{t('actions')}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.fieldId}>
                <TableCell>
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => move(index, Math.max(0, index - 1))}
                    disabled={index === 0}
                    className="size-9"
                    type="button"
                    aria-label={t('moveUp')}
                  >
                    <ArrowUp className="size-4" />
                  </Button>
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => move(index, Math.min(fields.length - 1, index + 1))}
                    disabled={index === fields.length - 1}
                    className="size-9"
                    type="button"
                    aria-label={t('moveDown')}
                  >
                    <ArrowDown className="size-4" />
                  </Button>
                </TableCell>
                <TableCell>
                  <FormField
                    control={control}
                    name={`childProducts.${index}.product`}
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormControl>
                          <SearchCombobox
                            className="w-full"
                            value={field.value || ''}
                            onSelect={(selectedValue) => {
                              field.onChange(selectedValue as string);
                            }}
                            collectionKey="products"
                            searchKey="name"
                            excludeIds={getExcludeIds(index)}
                            error={fieldState.error?.message}
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus={index === lastAddedIndex}
                            disabled={disabled}
                          />
                        </FormControl>
                        {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
                      </FormItem>
                    )}
                  />
                </TableCell>
                <TableCell>
                  <FormField
                    control={control}
                    name={`childProducts.${index}.quantity`}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            type="number"
                            {...field}
                            onChange={(e) => field.onChange(parseInt(e.target.value, 10) || undefined)}
                            className="w-20"
                            disabled={disabled}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </TableCell>
                <TableCell>
                  <div className="flex">
                    <Button type="button" variant="ghost" size="icon" onClick={() => remove(index)} disabled={disabled}>
                      <Trash className="size-4" />
                    </Button>

                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      disabled={!childProductValues?.[index]?.product}
                      onClick={() => window.open(`/juh/products/${childProductValues?.[index]?.product}`, '_blank')}
                    >
                      <ExternalLink className="size-4" />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="flex">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => {
              append({
                product: '',
                quantity: 1,
                order: fields.length,
              });
              setLastAddedIndex(fields.length);
            }}
            type="button"
            className={cn('w-full', childProductValues.length <= 0 && 'mt-2')}
            aria-label={t('addChildProduct')}
            disabled={disabled}
          >
            <Plus className="size-4" />
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
