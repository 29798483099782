import { useArticle, useUpdateArticle } from '@/api/articles';
import { useUploadMedia } from '@/api/media';
import { useProduct, useProducts, useResolvedProductMetadata } from '@/api/products';
import { ArticleAssignmentCard } from '@/components/juh/articles/ArticleAssignmentCard';
import { ArticleChildren } from '@/components/juh/articles/ArticleChildren';
import { ArticleDatesCard } from '@/components/juh/articles/ArticleDatesCard';
import { ArticleDetailsCard } from '@/components/juh/articles/ArticleDetailsCard';
import { ArticleFilesCard } from '@/components/juh/articles/ArticleFilesCard';
import { ArticleFormHeader } from '@/components/juh/articles/ArticleFormHeader';
import { ArticleMetadataCard } from '@/components/juh/articles/ArticleMetadataCard';
import { useArticleErrorHandling } from '@/utils/articleErrorHandling';
import { zodResolver } from '@hookform/resolvers/zod';
import type { Article, Product } from '@johanniter-offshore/types';
import { Button, Form, Tabs, TabsContent, TabsList, TabsTrigger } from '@johanniter-offshore/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from '@tiny-intl/react';
import jsonSchemaToZod from 'json-schema-to-zod';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';

import type { ArticleFilesFormValues, AssignmentFormValues, DatesFormValues, DetailsFormValues } from './CreateArticle';
import { articleFilesSchema, assignmentSchema, datesSchema, detailsSchema } from './CreateArticle';

function SubmitButton({ isLoading }: { isLoading: boolean }) {
  const { t } = useIntl();
  return (
    <Button type="submit" className="mt-4" disabled={isLoading}>
      {isLoading ? t('saving') : t('updateArticle')}
    </Button>
  );
}

function parseDate(value: string | null | undefined): Date | null {
  return value ? new Date(value) : null;
}

function parseMetadata(metadata?: Record<string, unknown>): Record<string, unknown> {
  return metadata
    ? Object.fromEntries(
        Object.entries(metadata).map(([key, value]) => {
          if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)) {
            return [key, DateTime.fromISO(value).toJSDate()];
          }
          return [key, value];
        }),
      )
    : {};
}

function parseChildArticles(children: { product: string; id: string }[]): Record<string, string[]> {
  return children.reduce(
    (acc, child) => {
      if (child.product) {
        acc[child.product] = [...(acc[child.product] || []), child.id];
      }
      return acc;
    },
    {} as Record<string, string[]>,
  );
}

export function EditArticle() {
  const { t } = useIntl();
  const { id } = useParams<{ id: string }>();
  const updateArticle = useUpdateArticle();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleArticleError } = useArticleErrorHandling();
  const uploadMedia = useUploadMedia();

  const { data: articleData } = useArticle(id as string);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [metadataSchema, setMetadataSchema] = useState<z.ZodObject<any, any>>(z.object({}));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [childArticlesSchema, setChildArticlesSchema] = useState<z.ZodObject<any, any>>(z.object({}));

  const detailsForm = useForm<DetailsFormValues>({
    resolver: zodResolver(detailsSchema),
    defaultValues: { product: (articleData?.product as string) || '' },
  });

  const datesForm = useForm<DatesFormValues>({
    resolver: zodResolver(datesSchema),
    defaultValues: {
      expiryDate: parseDate(articleData?.expiryDate),
      testInterval: articleData?.testInterval || null,
      lastTestDate: parseDate(articleData?.lastTestDate),
    },
  });

  const assignmentForm = useForm<AssignmentFormValues>({
    resolver: zodResolver(assignmentSchema),
    defaultValues: {
      organization: (articleData?.organization as string) || null,
      station: (articleData?.station as string) || null,
    },
  });

  const metadataForm = useForm({
    resolver: zodResolver(metadataSchema),
    defaultValues: parseMetadata(articleData?.metadata as Record<string, unknown>),
  });

  const childArticlesForm = useForm({
    resolver: zodResolver(childArticlesSchema),
    defaultValues: parseChildArticles((articleData?.children as { product: string; id: string }[]) || []),
  });

  const articleFilesForm = useForm<ArticleFilesFormValues>({
    resolver: zodResolver(articleFilesSchema),
    defaultValues: {
      articleFiles: [],
    },
  });

  const { data: productData } = useProduct(detailsForm.watch('product'), { disabled: !detailsForm.watch('product') });
  const { data: productMetadata } = useResolvedProductMetadata(productData?.id);

  const { data: childProducts } = useProducts({
    where: {
      id: {
        in: productData?.childProducts?.map((child) => child.product as string) || [],
      },
    },
    disabled: !productData || !productData.childProducts,
  });

  const childProductsWithCount = useMemo(() => {
    if (!childProducts || !productData?.childProducts) return [];
    return childProducts.docs.map((childProduct: Product) => {
      const composition = productData.childProducts?.find((comp) => comp.product === childProduct.id);
      return {
        product: childProduct,
        requiredCount: composition ? composition.quantity : 0,
      };
    });
  }, [childProducts, productData?.childProducts]);

  useEffect(() => {
    if (articleData) {
      detailsForm.reset({ product: articleData.product as string });
      datesForm.reset({
        expiryDate: parseDate(articleData.expiryDate),
        lastTestDate: parseDate(articleData.lastTestDate),
        testInterval: articleData.testInterval,
      });
      assignmentForm.reset({
        organization: articleData.organization as string,
        station: articleData.station as string,
      });
      metadataForm.reset(parseMetadata(articleData.metadata as Record<string, unknown>));
      childArticlesForm.reset(
        parseChildArticles(
          articleData.children?.map((child) => ({ id: child.article as string, product: child.product as string })) ||
            [],
        ),
      );
      articleFilesForm.reset({
        articleFiles:
          articleData.articleFiles?.map((file) => ({
            name: file?.name || '',
            description: file.description || '',
            id: file.id || '',
            file: file.file as string,
          })) || [],
      });
    }
  }, [articleData, detailsForm, datesForm, assignmentForm, metadataForm, childArticlesForm, articleFilesForm]);

  useEffect(() => {
    if (childProductsWithCount) {
      const childProducts: { [key: string]: z.ZodArray<z.ZodString> } = {};
      childProductsWithCount.forEach((childProduct) => {
        childProducts[childProduct.product.id] = z
          .array(z.string())
          .min(childProduct.requiredCount)
          .max(childProduct.requiredCount);
      });
      setChildArticlesSchema(z.object(childProducts));
    }
  }, [childProductsWithCount]);

  useEffect(() => {
    if (productMetadata) {
      const zodSchemaString = jsonSchemaToZod(productMetadata);
      const transformedSchema = zodSchemaString.replace(/z\.string\(\)\.datetime\({ offset: true }\)/g, 'z.date()');
      const zodSchema = eval(transformedSchema);
      setMetadataSchema(zodSchema);
    }
  }, [productMetadata]);

  const onSubmit = async (
    formData: Partial<
      ArticleFilesFormValues &
        AssignmentFormValues &
        DatesFormValues &
        DetailsFormValues & {
          children: Record<string, string[]>;
        } & {
          metadata: Record<string, unknown>;
        }
    >,
  ) => {
    setIsSubmitting(true);

    try {
      const payload: Partial<Article> = {};

      if (!productData?.isContainer && formData.expiryDate !== undefined) {
        payload.expiryDate = formData.expiryDate?.toISOString() || null;
      }
      if (formData.lastTestDate !== undefined) {
        payload.lastTestDate = formData.lastTestDate?.toISOString() || null;
      }
      if (formData.testInterval !== undefined) {
        payload.testInterval = formData.testInterval || null;
      }
      if (formData.metadata !== undefined) {
        payload.metadata = formData.metadata || null;
      }
      if (formData.organization !== undefined) {
        payload.organization = formData.organization || null;
      }
      if (formData.station !== undefined) {
        payload.station = formData.station || null;
      }

      if (formData.articleFiles) {
        const existingFiles = articleData?.articleFiles || [];
        const newFiles = formData.articleFiles.filter((file) => file.file instanceof File);
        const updatedFiles = formData.articleFiles.filter((file) => typeof file.id === 'string');

        const uploadedFiles = await Promise.all(
          newFiles.map(async (fileData) => {
            if (!fileData.file || !(fileData.file instanceof File)) {
              throw new Error('File is missing');
            }
            const uploadedFile = await uploadMedia.mutateAsync({
              file: fileData.file,
              data: {},
            });
            return { file: uploadedFile.doc.id, name: fileData.name, description: fileData.description };
          }),
        );

        payload.articleFiles = [
          ...existingFiles
            .filter((file) => updatedFiles.some((updatedFile) => updatedFile.id === file.file))
            .map((file) => ({ ...file, file: file.file as string })),
          ...updatedFiles.map((file) => ({ ...file, file: file.file as string })),
          ...uploadedFiles,
        ];
      }

      if (formData.children) {
        payload.children = Object.entries(formData.children)
          .map(([productId, articleIds]) => articleIds.map((id: string) => ({ article: id, product: productId })))
          .flat();
      }

      await updateArticle.mutateAsync({ id: id as string, data: payload });
      toast.success(t('articleUpdatedDescription'));
      queryClient.invalidateQueries({ queryKey: ['articles'] });
    } catch (error) {
      if (!handleArticleError(error)) {
        toast.error(t('errorUpdatingArticleDescription'));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="grid gap-6 md:grid-cols-1">
      <div className="mx-auto w-full max-w-[59rem] p-4 sm:px-6">
        <ArticleFormHeader isEditMode />

        <Tabs defaultValue="details">
          <TabsList>
            <TabsTrigger value="details">{t('articleDetails')}</TabsTrigger>
            <TabsTrigger value="dates">{t('articleDates')}</TabsTrigger>
            <TabsTrigger value="assignment">{t('articleAssignment')}</TabsTrigger>
            <TabsTrigger value="metadata">{t('articleMetadata')}</TabsTrigger>
            <TabsTrigger value="files">{t('articleFiles')}</TabsTrigger>
            {productData?.isContainer && <TabsTrigger value="children">{t('articleChildren')}</TabsTrigger>}
          </TabsList>

          <TabsContent value="details">
            <Form {...detailsForm}>
              <form onSubmit={detailsForm.handleSubmit((data) => onSubmit(data))}>
                <ArticleDetailsCard article={articleData} control={detailsForm.control} disabled />
                <SubmitButton isLoading={isSubmitting} />
              </form>
            </Form>
          </TabsContent>

          <TabsContent value="dates">
            <Form {...datesForm}>
              <form onSubmit={datesForm.handleSubmit((data) => onSubmit(data))}>
                <ArticleDatesCard control={datesForm.control} disableExpiryDate={!!productData?.isContainer} />
                <SubmitButton isLoading={isSubmitting} />
              </form>
            </Form>
          </TabsContent>

          <TabsContent value="assignment">
            <Form {...assignmentForm}>
              <form onSubmit={assignmentForm.handleSubmit((data) => onSubmit(data))}>
                <ArticleAssignmentCard
                  disabled={Boolean(articleData?.parent)}
                  control={assignmentForm.control}
                  watch={assignmentForm.watch}
                  setValue={assignmentForm.setValue}
                />
                <SubmitButton isLoading={isSubmitting} />
              </form>
            </Form>
          </TabsContent>

          <TabsContent value="metadata">
            <Form {...metadataForm}>
              <form onSubmit={metadataForm.handleSubmit((data) => onSubmit({ metadata: data }))}>
                <ArticleMetadataCard schema={metadataSchema} form={metadataForm} />
                <SubmitButton isLoading={isSubmitting} />
              </form>
            </Form>
          </TabsContent>

          <TabsContent value="files">
            <Form {...articleFilesForm}>
              <form onSubmit={articleFilesForm.handleSubmit((data) => onSubmit(data))}>
                <ArticleFilesCard />
                <SubmitButton isLoading={isSubmitting} />
              </form>
            </Form>
          </TabsContent>

          {productData?.isContainer && (
            <TabsContent value="children">
              <Form {...childArticlesForm}>
                <form onSubmit={childArticlesForm.handleSubmit((data) => onSubmit(data))}>
                  <ArticleChildren
                    childProductsWithCount={childProductsWithCount}
                    stationId={assignmentForm.watch('station')}
                    parentArticle={articleData}
                  />
                  <SubmitButton isLoading={isSubmitting} />
                </form>
              </Form>
            </TabsContent>
          )}
        </Tabs>
      </div>
    </div>
  );
}
