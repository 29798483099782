import { SearchCombobox } from '@/components/juh/inputs/SearchCombobox';
import type { AssignmentFormValues } from '@/routes/application/juh/articles/CreateArticle';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import type { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';

interface ArticleAssignmentCardProps {
  control: Control<AssignmentFormValues>;
  watch: UseFormWatch<AssignmentFormValues>;
  setValue: UseFormSetValue<AssignmentFormValues>;
  disabled?: boolean;
}

export function ArticleAssignmentCard({ control, watch, setValue, disabled = false }: ArticleAssignmentCardProps) {
  const { t } = useIntl();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('articleAssignment')}</CardTitle>
        <CardDescription>{t('articleAssignmentDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <FormField
            control={control}
            name="organization"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>{t('organization')}</FormLabel>
                <FormControl>
                  <SearchCombobox
                    value={field.value || undefined}
                    onSelect={(value) => {
                      field.onChange(value);
                      setValue('station', null);
                    }}
                    collectionKey="organizations"
                    searchKey="name"
                    texts={{
                      selectItemMsg: t('selectOrganization'),
                      searchPlaceholder: t('searchOrganization'),
                    }}
                    disabled={disabled}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="station"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>{t('station')}</FormLabel>
                <FormControl>
                  <SearchCombobox
                    value={field.value || undefined}
                    onSelect={field.onChange}
                    collectionKey="stations"
                    searchKey="name"
                    texts={{
                      selectItemMsg: t('selectStation'),
                      searchPlaceholder: t('searchStation'),
                    }}
                    customWhere={
                      watch('organization')
                        ? { organization: { equals: watch('organization') } }
                        : { organization: { exists: false } }
                    }
                    disabled={disabled}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
