export const fieldType = ['string', 'number', 'boolean', 'date'] as const;
export type FieldType = (typeof fieldType)[number];

export type JsonSchemaType = {
  type: 'object';
  properties: Record<
    string,
    {
      type: FieldType;
      format?: string;
      description: string;
    }
  >;
  required: string[];
  propertyOrder: string[];
};
