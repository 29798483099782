import { zodResolver } from '@hookform/resolvers/zod';
import type { Station } from '@johanniter-offshore/types';
import { Button, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface StationFormProps {
  station?: Station;
  onSubmit: (data: StationFormValues) => Promise<void>;
}

export type StationFormValues = z.infer<typeof stationFormSchema>;

const stationFormSchema = z.object({
  name: z.string().min(1, 'nameRequired'),
  description: z.string().optional(),
});

export const StationForm = ({ station, onSubmit }: StationFormProps) => {
  const { t } = useIntl();

  const form = useForm<StationFormValues>({
    resolver: zodResolver(stationFormSchema),
    defaultValues: {
      name: station?.name ?? '',
      description: station?.description ?? '',
    },
  });

  const handleSubmit = async (data: StationFormValues) => {
    await onSubmit(data);
    form.reset();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('name')}</FormLabel>
              <FormControl>
                <Input placeholder={t('stationName')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('description')}</FormLabel>
              <FormControl>
                <Input placeholder={t('stationDescription')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit">{station ? t('save') : t('create')}</Button>
      </form>
    </Form>
  );
};
