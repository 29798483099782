import { payloadClient } from '@/api';
import { useArticle } from '@/api/articles';
import { useProduct, useResolvedProductMetadata } from '@/api/products';
import { useStation } from '@/api/stations';
import { NextTestDate } from '@/components/shared/NextTestDate';
import { FormatedInterval } from '@/components/shared/format/Intervals';
import type { Article, Media, Product, Station } from '@johanniter-offshore/types';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  /* Separator */
  Skeleton,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import {
  ChevronLeft,
  Download,
  /* ExternalLink */
  Loader2,
} from 'lucide-react';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

/* function ArticleContentsCard({ article }: { article?: Article }) {
  const { t } = useIntl();

  if (!article?.children?.length) return null;

  const groupedChildren = article.children.reduce(
    (acc, child) => {
      if (typeof child === 'object' && child.product) {
        if (!acc[child.product as string]) {
          acc[child.product as string] = [];
        }
        acc[child.product as string].push(child as unknown as Article);
      }
      return acc;
    },
    {} as Record<string, Article[]>,
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('contents')}</CardTitle>
        <CardDescription>{t('contentsDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        {Object.keys(groupedChildren).length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t('expiryDate')}</TableHead>
                <TableHead>{t('testInterval')}</TableHead>
                <TableHead>{t('lastTestDate')}</TableHead>
                <TableHead>{t('nextCheckDate')}</TableHead>
                <TableHead>{t('actions')}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {Object.entries(groupedChildren).map(([childProductId, children]) => (
                <ChildProductGroup key={childProductId} childProductId={childProductId} childArticles={children} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <div>{t('noContents')}</div>
        )}
      </CardContent>
    </Card>
  );
} 

function ChildProductGroup({ childProductId, childArticles }: { childProductId: string; childArticles: Article[] }) {
  const { data: childProduct, isLoading } = useProduct(childProductId);

  if (isLoading) {
    return (
      <TableRow>
        <TableCell colSpan={6}>
          <Skeleton className="h-10 w-full" />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <TableRow>
        <TableCell colSpan={6} className="bg-muted/50 font-semibold">
          {childProduct?.name} ({childArticles.length})
        </TableCell>
      </TableRow>
      {childArticles.map((childArticle) => (
        <ChildProductRow key={childArticle.id} childArticle={childArticle} />
      ))}
    </>
  );
}

function ChildProductRow({ childArticle }: { childArticle: Article }) {
  const handleOpenArticle = () => {
    window.open(`/c/my-backpacks/${childArticle.id}`, '_blank');
  };

  return (
    <TableRow>
      <TableCell>
        {childArticle.expiryDate ? DateTime.fromISO(childArticle.expiryDate).toFormat('dd.MM.yyyy') : '-'}
      </TableCell>
      <TableCell>
        {childArticle.testInterval ? (
          <FormatedInterval interval={childArticle.testInterval} prefixFormat="every" />
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>
        {childArticle.lastTestDate ? DateTime.fromISO(childArticle.lastTestDate).toFormat('dd.MM.yyyy') : '-'}
      </TableCell>
      <TableCell>
        <NextTestDate lastTestDate={childArticle.lastTestDate} testInterval={childArticle.testInterval} />
      </TableCell>
      <TableCell>
        <Button variant="ghost" size="icon" onClick={handleOpenArticle}>
          <ExternalLink className="size-4" />
        </Button>
      </TableCell>
    </TableRow>
  );
} */

function ArticleMetadataCard({ article }: { article?: Article }) {
  const { t } = useIntl();
  const { data: metadataDefinition, isLoading } = useResolvedProductMetadata(article?.product as string);

  const renderMetadataValue = (key: string, value: unknown, type: string) => {
    if (type === 'date' && typeof value === 'string') {
      return DateTime.fromISO(value).toFormat('dd.MM.yyyy');
    }
    return String(value);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('metadata')}</CardTitle>
        <CardDescription>{t('metadataDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        {!article || isLoading ? (
          <Skeleton className="h-[300px] w-full" />
        ) : metadataDefinition && article?.metadata && typeof article.metadata === 'object' ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[200px]">{t('key')}</TableHead>
                <TableHead>{t('value')}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {metadataDefinition.propertyOrder.map((key) => {
                const fieldDefinition = metadataDefinition.properties[key];
                const value = (article.metadata as Record<string, unknown>)[key];
                if (value !== undefined) {
                  return (
                    <TableRow key={key}>
                      <TableCell>{fieldDefinition.description}</TableCell>
                      <TableCell>{renderMetadataValue(key, value, fieldDefinition.type)}</TableCell>
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
            {metadataDefinition.propertyOrder.length === 0 && (
              <TableCaption className="text-muted-foreground py-4 text-center text-sm">{t('noMetadata')}</TableCaption>
            )}
          </Table>
        ) : (
          <Table>
            <TableCaption className="text-muted-foreground py-4 text-center text-sm">{t('noMetadata')}</TableCaption>
          </Table>
        )}
      </CardContent>
    </Card>
  );
}

function ArticleDetailsCard({
  productData,
  stationData,
  isLoading,
}: {
  productData?: Product;
  stationData?: Station;
  isLoading: boolean;
}) {
  const { t } = useIntl();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('details')}</CardTitle>
        <CardDescription>{t('detailsDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="font-semibold">{t('article')}</TableCell>
              <TableCell className="w-full">
                {isLoading ? <Skeleton className="h-4 w-full" /> : productData?.name || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">{t('description')}</TableCell>
              <TableCell className="w-full">
                {isLoading ? <Skeleton className="h-4 w-full" /> : productData?.description || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">{t('station')}</TableCell>
              <TableCell className="w-full">
                {isLoading ? <Skeleton className="h-4 w-full" /> : stationData?.name || '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function ArticleDatesCard({ articleData, isLoading }: { articleData?: Article; isLoading: boolean }) {
  const { t } = useIntl();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('dates')}</CardTitle>
        <CardDescription>{t('datesDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="font-semibold">{t('expiryDate')}</TableCell>
              <TableCell className="w-full">
                {isLoading ? (
                  <Skeleton className="h-4 w-full" />
                ) : articleData?.expiryDate ? (
                  DateTime.fromISO(articleData.expiryDate).toFormat('dd.MM.yyyy')
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">{t('testInterval')}</TableCell>
              <TableCell className="w-full">
                {isLoading ? (
                  <Skeleton className="h-4 w-full" />
                ) : articleData?.testInterval ? (
                  <FormatedInterval interval={articleData.testInterval} prefixFormat="every" />
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">{t('lastTestDate')}</TableCell>
              <TableCell className="w-full">
                {isLoading ? (
                  <Skeleton className="h-4 w-full" />
                ) : articleData?.lastTestDate ? (
                  DateTime.fromISO(articleData.lastTestDate).toFormat('dd.MM.yyyy')
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">{t('nextCheckDate')}</TableCell>
              <TableCell className="w-full">
                {isLoading ? (
                  <Skeleton className="h-4 w-full" />
                ) : (
                  <NextTestDate lastTestDate={articleData?.lastTestDate} testInterval={articleData?.testInterval} />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function ArticleFilesCard({
  articleData,
  productData,
  isLoading,
}: {
  articleData?: Article;
  productData?: Product;
  isLoading: boolean;
}) {
  const { t } = useIntl();
  const [downloadingIndex, setDownloadingIndex] = useState<number | null>(null);

  const allFiles = useMemo(() => {
    const articleFiles = articleData?.articleFiles || [];
    const productFiles = productData?.productFiles || [];
    return [...articleFiles, ...productFiles];
  }, [articleData, productData]);

  const handleDownload = async (file: { file: string | Media; name: string }, index: number) => {
    setDownloadingIndex(index);

    try {
      let url: string;
      let filename: string;

      if (typeof file.file === 'string') {
        const mediaData = await payloadClient.findById({
          collection: 'media',
          id: file.file as string,
        });
        if (!mediaData || !mediaData.url) {
          toast.error(t('fileNotFound'));
          return;
        }
        url = mediaData.url;
        filename = mediaData.filename || file.name;
      } else {
        url = file.file.url || '';
        filename = file.file.filename || file.name;
      }

      const request = await payloadClient.createRequest(url.replace('/api', ''), { method: 'GET' });
      const response = await payloadClient.fetcher(request);
      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      toast.error(t('errorDownloadingFile'));
    } finally {
      setDownloadingIndex(null);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('files')}</CardTitle>
        <CardDescription>{t('filesDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <Skeleton className="h-[300px] w-full" />
        ) : allFiles.length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[200px]">{t('name')}</TableHead>
                <TableHead>{t('description')}</TableHead>
                <TableHead className="w-[100px]">{t('actions')}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {allFiles.map((file, index) => (
                <TableRow key={index}>
                  <TableCell>{file.name}</TableCell>
                  <TableCell>{file.description || '-'}</TableCell>
                  <TableCell>
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      onClick={() => handleDownload(file, index)}
                      disabled={downloadingIndex === index}
                    >
                      {downloadingIndex === index ? (
                        <Loader2 className="size-4 animate-spin" />
                      ) : (
                        <Download className="size-4" />
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className="text-muted-foreground py-4 text-center text-sm">
            <p>{t('noFilesAssigned')}</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export function ArticleDetail() {
  const { t } = useIntl();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data: articleData, isLoading: isArticleLoading } = useArticle(id as string);
  const { data: productData, isLoading: isProductLoading } = useProduct(articleData?.product as string, {
    disabled: isArticleLoading,
  });
  const { data: stationData, isLoading: isStationLoading } = useStation(articleData?.station as string, {
    disabled: isArticleLoading,
  });

  const isLoading = isArticleLoading || isProductLoading || isStationLoading;

  return (
    <main
      className={`
        grid flex-1 items-start gap-4 p-4

        md:gap-8

        sm:px-6 sm:py-0
      `}
    >
      <div className="mx-auto grid w-full max-w-[59rem] flex-1 auto-rows-max gap-4">
        <div className="flex items-center gap-4">
          <Button variant="outline" size="icon" className="size-7" onClick={() => navigate('/juh/articles')}>
            <ChevronLeft className="size-4" />
            <span className="sr-only">Back</span>
          </Button>
          <h1
            className={`
              flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight

              sm:grow-0
            `}
          >
            {t('articleDetails')}
          </h1>
        </div>

        <div
          className={`
            grid grid-cols-1 gap-4

            md:grid-cols-2
          `}
        >
          <ArticleDetailsCard productData={productData} stationData={stationData} isLoading={isLoading} />
          <ArticleDatesCard articleData={articleData} isLoading={isLoading} />

          <div className="col-span-full">
            <ArticleMetadataCard article={articleData} />
          </div>

          <div className="col-span-full">
            <ArticleFilesCard articleData={articleData} productData={productData} isLoading={isLoading} />
          </div>
          {/* {!!articleData?.children?.length && (
            <div className="col-span-full">
              <Separator />
            </div>
          )}

          <div className="col-span-full">
            <ArticleContentsCard article={articleData} />
          </div> */}
        </div>
      </div>
    </main>
  );
}
