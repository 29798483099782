import { IntervalInput } from '@/components/shared/format/Intervals';
import type { DatesFormValues } from '@/routes/application/juh/articles/CreateArticle';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  DatePicker,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import type { Control } from 'react-hook-form';

interface ArticleDatesCardProps {
  control: Control<DatesFormValues>;
  disableExpiryDate: boolean;
}

export function ArticleDatesCard({ control, disableExpiryDate }: ArticleDatesCardProps) {
  const { t } = useIntl();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('articleDates')}</CardTitle>
        <CardDescription>{t('articleDatesDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <FormField
            control={control}
            name="expiryDate"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>{t('expiryDate')}</FormLabel>
                <DatePicker
                  date={field.value}
                  setDate={(date) => field.onChange(date)}
                  placeholder={t('chooseExpiryDate')}
                  disabled={disableExpiryDate}
                />
                {disableExpiryDate && (
                  <FormDescription className="text-xs">({t('containerExpiryDateDescription')})</FormDescription>
                )}
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="testInterval"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('testInterval')}</FormLabel>
                <FormControl>
                  <IntervalInput value={field.value} onChange={(value) => field.onChange(value)} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="lastTestDate"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>{t('lastTestDate')}</FormLabel>
                <DatePicker
                  date={field.value}
                  setDate={(date) => field.onChange(date)}
                  placeholder={t('chooseLastTestDate')}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
