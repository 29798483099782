import type { User } from '@johanniter-offshore/types';
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';

import { CustomerLayout } from './routes/application/c';
import { MyAccount } from './routes/application/c/MyAccount';
import { ArticleDetail } from './routes/application/c/myBackpacks/ArticleDetail';
import { BackpackOverview } from './routes/application/c/myBackpacks/BackpackOverview';
import { Settings as CustomerSettings } from './routes/application/c/settings';
import { Organization } from './routes/application/c/settings/Organization';
import { Stations } from './routes/application/c/settings/Stations';
import { Users } from './routes/application/c/settings/Users';
import { JuhLayout } from './routes/application/juh';
import { MyAccount as JuhMyAccount } from './routes/application/juh/MyAccount';
import { ArticleOverview } from './routes/application/juh/articles/ArticleOverview';
import { CreateArticle } from './routes/application/juh/articles/CreateArticle';
import { EditArticle } from './routes/application/juh/articles/EditArticle';
import { ManageProductCategory } from './routes/application/juh/productCategories/ManageProductCategory';
import { ProductCategoriesOverview } from './routes/application/juh/productCategories/ProductCategoriesOverview';
import { CreateProduct } from './routes/application/juh/products/CreateProduct';
import { EditProduct } from './routes/application/juh/products/EditProduct';
import { ProductsOverview } from './routes/application/juh/products/ProductsOverview';
import { Settings as JuhSettings } from './routes/application/juh/settings';
import { GlobalMetadata } from './routes/application/juh/settings/GlobalMetadata';
import { Stations as JuhStations } from './routes/application/juh/settings/Stations';
import { Users as JuhUsers } from './routes/application/juh/settings/Users';
import { AuthLayout } from './routes/auth';
import { AcceptInvite } from './routes/auth/AcceptInvite';
import { Login } from './routes/auth/Login';
import { RequestPasswordReset } from './routes/auth/RequestPasswordReset';
import { ResetPassword } from './routes/auth/ResetPassword';
import type { CustomRouteObjectUnion } from './types/router';
import { useAuth } from './utils/hooks/useAuth';

export function AppRouter() {
  const auth = useAuth();

  if (auth.initialized) {
    const redirectBasedOnRole = (user: User) => {
      if (user.role === 'customer-admin' || user.role === 'customer-employee') {
        return redirect('/c');
      } else if (user.role === 'juh-admin' || user.role === 'juh-employee') {
        return redirect('/juh');
      }
      return null;
    };

    const publicRoute = () => {
      return auth.user ? redirectBasedOnRole(auth.user) : null;
    };

    const privateRoute = (allowedRoles: string[]) => () => {
      if (!auth.user) {
        return redirect('/auth/login');
      }
      if (!allowedRoles.includes(auth.user.role)) {
        return redirect('/');
      }
      return null;
    };

    const unknownRoute = () => {
      return auth.user ? redirectBasedOnRole(auth.user) : redirect('/auth/login');
    };

    const router = createBrowserRouter([
      {
        path: '*',
        loader: unknownRoute,
        element: <div>Unknown</div>,
      },
      {
        path: '/auth',
        element: <AuthLayout />,
        loader: publicRoute,
        children: [
          {
            path: 'login',
            element: <Login />,
          },
          {
            path: 'forgot-password',
            element: <RequestPasswordReset />,
          },
          {
            path: 'reset-password',
            element: <ResetPassword />,
          },
          {
            path: 'accept-invite',
            element: <AcceptInvite />,
          },
        ],
      },
      {
        path: '/c',
        loader: privateRoute(['customer-admin', 'customer-employee']),
        element: <CustomerLayout />,
        children: [
          {
            index: true,
            loader: () => redirect('/c/my-backpacks'),
          },
          {
            path: 'my-backpacks',

            handle: {
              breadcrumb: () => 'myBackpacks',
            },
            children: [
              {
                index: true,
                element: <BackpackOverview />,
              },
              {
                path: ':id',
                element: <ArticleDetail />,
                handle: {
                  breadcrumb: () => 'backpack',
                },
              },
            ],
          },
          {
            path: 'settings',
            element: <CustomerSettings />,
            handle: {
              breadcrumb: () => 'settings',
            },
            children: [
              {
                index: true,
                loader: () => redirect('organization'),
              },
              {
                path: 'organization',
                element: <Organization />,
              },
              {
                path: 'users',
                element: <Users />,
              },
              {
                path: 'stations',
                element: <Stations />,
              },
            ],
          },
          {
            path: 'my-account',
            element: <MyAccount />,
            handle: {
              breadcrumb: () => 'myAccount',
            },
          },
        ],
      },
      {
        path: '/juh',
        loader: privateRoute(['juh-admin', 'juh-employee']),
        element: <JuhLayout />,
        children: [
          {
            index: true,
            loader: () => redirect('/juh/articles'),
          },
          {
            path: 'product-categories',
            handle: {
              breadcrumb: () => 'productCategories',
            },
            children: [
              {
                index: true,
                element: <ProductCategoriesOverview />,
              },
              {
                path: ':id',
                element: <ManageProductCategory />,
                handle: {
                  breadcrumb: () => 'editCategory',
                },
              },
              {
                path: 'new',
                element: <ManageProductCategory />,
                handle: {
                  breadcrumb: () => 'newCategory',
                },
              },
            ],
          },
          {
            path: 'articles',
            handle: {
              breadcrumb: () => 'stock',
            },
            children: [
              {
                index: true,
                element: <ArticleOverview />,
              },
              {
                path: ':id',
                element: <EditArticle />,
                handle: {
                  breadcrumb: () => 'editArticle',
                },
              },
              {
                path: 'new',
                element: <CreateArticle />,
                handle: {
                  breadcrumb: () => 'newArticle',
                },
              },
            ],
          },
          {
            path: 'products',
            handle: {
              breadcrumb: () => 'products',
            },
            children: [
              {
                index: true,
                element: <ProductsOverview />,
              },
              {
                path: ':id',
                element: <EditProduct />,
                handle: {
                  breadcrumb: () => 'editProduct',
                },
              },
              {
                path: 'new',
                element: <CreateProduct />,
                handle: {
                  breadcrumb: () => 'newProduct',
                },
              },
            ],
          },
          {
            path: 'settings',
            element: <JuhSettings />,
            handle: {
              breadcrumb: () => 'settings',
            },
            children: [
              {
                index: true,
                loader: () => redirect('users'),
              },
              {
                path: 'users',
                element: <JuhUsers />,
              },
              {
                path: 'stations',
                element: <JuhStations />,
              },
              {
                path: 'global-metadata',
                element: <GlobalMetadata />,
              },
            ],
          },
          {
            path: 'my-account',
            element: <JuhMyAccount />,
            handle: {
              breadcrumb: () => 'myAccount',
            },
          },
        ],
      },
    ] as CustomRouteObjectUnion[]);

    return <RouterProvider router={router} />;
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div
        className="text-primary size-4 animate-spin rounded-full border-2 border-current border-t-transparent"
        aria-label="loading"
      ></div>
    </div>
  );
}
