import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { MoreHorizontal } from 'lucide-react';

interface StationTableActionsProps {
  onEdit: () => void;
  onDelete: () => void;
}

export const StationTableActions = ({ onEdit, onDelete }: StationTableActionsProps) => {
  const { t } = useIntl();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button aria-haspopup="true" size="icon" variant="ghost" className="size-8">
          <MoreHorizontal className="size-4" />
          <span className="sr-only">{t('openMenu')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('actions')}</DropdownMenuLabel>
        <DropdownMenuItem onClick={onEdit}>{t('edit')}</DropdownMenuItem>
        <DropdownMenuItem onClick={onDelete}>{t('delete')}</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
