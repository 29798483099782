import { FileUploadCard } from '@/components/shared/FileUploadCard';
import type { ProductFilesFormValues } from '@/routes/application/juh/products/CreateProduct';
import { useIntl } from '@tiny-intl/react';
import { useFormContext } from 'react-hook-form';

interface ProductFilesProps {
  disabled?: boolean;
}

export function ProductFilesCard({ disabled = false }: ProductFilesProps) {
  const { t } = useIntl();
  useFormContext<ProductFilesFormValues>();

  return (
    <FileUploadCard
      disabled={disabled}
      title={t('productFiles')}
      description={t('productFilesDescription')}
      fieldArrayName="files"
    />
  );
}
