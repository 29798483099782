import queryString from 'qs';
import type { DeepPartial } from 'ts-essentials';

import type {
  AuthenticationData,
  BulkOperationResult,
  Config,
  ErrorResponse,
  OperationResult,
  PaginatedDocs,
  PayloadApiClientOptions,
  Where,
} from './types';

export class PayloadApiError extends Error {
  constructor(
    public statusCode: number,
    public response: ErrorResponse,
  ) {
    super('Payload API Error');
    this.name = 'PayloadApiError';
  }
}

/**
 * setTimeout breaks with numbers bigger than 32bits.
 * This ensures that we don't try refreshing for tokens that last > 24 days.
 */
const MAX_INT32 = 2 ** 31 - 1;

export class PayloadApiClient<C extends Config> {
  apiURL: string;
  fetcher: typeof fetch;
  token: string | null;
  private refreshPromise: Promise<AuthenticationData> | null = null;
  private refreshTimeout: ReturnType<typeof setTimeout> | null = null;
  private autoRefresh: boolean;
  private msRefreshBeforeExpires: number;
  private authCollection: 'users';

  constructor({
    apiURL,
    fetcher = fetch,
    autoRefresh = true,
    msRefreshBeforeExpires = 30000,
  }: PayloadApiClientOptions) {
    this.fetcher = fetcher;
    this.apiURL = apiURL;
    this.token = localStorage.getItem('jwtToken');
    this.autoRefresh = autoRefresh;
    this.msRefreshBeforeExpires = msRefreshBeforeExpires;

    this.authCollection = 'users';

    if (this.token) {
      this.scheduleTokenRefresh();
    }
  }

  async createRequest(path: string, init?: RequestInit & { file?: File }): Promise<Request> {
    const headers = new Headers(init?.headers);
    if (this.token) {
      headers.set('Authorization', `JWT ${this.token}`);
    }

    let body: BodyInit | null = null;
    if (init?.file) {
      const formData = new FormData();
      formData.append('file', init.file);
      if (init.body && typeof init.body === 'string') {
        formData.append('_payload', init.body);
      }
      body = formData;
    } else if (init?.body) {
      headers.append('Content-Type', 'application/json');
      body = init.body;
    }

    // Cookies are disabled to prevent automatic login after password reset.
    // This ensures that the user must explicitly log in after resetting their password.
    return new Request(`${this.apiURL}${path}`, { ...init, headers, body, credentials: 'omit' });
  }

  async handleResponse(response: Response) {
    if (!response.ok) {
      const errorData: ErrorResponse = await response.json();
      throw new PayloadApiError(response.status, errorData);
    }
    return response;
  }

  async getToken(): Promise<string | null> {
    if (this.token) {
      const payload = this.parseToken(this.token);
      if (this.isTokenExpiringSoon(payload.exp)) {
        await this.refreshToken();
      }
    }
    return this.token;
  }

  private parseToken(token: string): { exp: number } {
    return JSON.parse(atob(token.split('.')[1]));
  }

  private isTokenExpiringSoon(expTime: number): boolean {
    return expTime * 1000 < Date.now() + this.msRefreshBeforeExpires;
  }

  stopRefreshing() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = null;
    }
  }

  private scheduleTokenRefresh() {
    if (!this.autoRefresh || !this.token) return;

    const payload = this.parseToken(this.token);
    const expiresIn = payload.exp * 1000 - Date.now() - this.msRefreshBeforeExpires;

    if (expiresIn > 0 && expiresIn < MAX_INT32) {
      this.refreshTimeout = setTimeout(() => this.refreshToken(), expiresIn);
    } else {
      this.refreshToken();
    }
  }

  async refreshToken(): Promise<AuthenticationData> {
    if (this.refreshPromise) return this.refreshPromise;

    this.refreshPromise = this.performTokenRefresh();
    return this.refreshPromise;
  }

  private async performTokenRefresh(): Promise<AuthenticationData> {
    try {
      const request = await this.createRequest(`/${String(this.authCollection)}/refresh-token`, {
        method: 'POST',
      });
      const response = await this.handleResponse(await this.fetcher(request));

      const data = await response.json();
      this.handleRefreshResponse(data);
      return data;
    } catch (error) {
      this.handleRefreshError();
      throw error;
    } finally {
      this.refreshPromise = null;
    }
  }

  private handleRefreshResponse(data: { refreshedToken?: string }) {
    if (data.refreshedToken) {
      this.token = data.refreshedToken;
      localStorage.setItem('jwtToken', data.refreshedToken);
      this.scheduleTokenRefresh();
    } else {
      this.clearAuthData();
    }
  }

  private handleRefreshError() {
    this.clearAuthData();
  }

  private clearAuthData() {
    this.token = null;
    localStorage.removeItem('jwtToken');
    this.stopRefreshing();
  }

  async login({ email, password }: { email: string; password: string }): Promise<{
    user: C['collections']['users'];
    token: string;
    exp: number;
  }> {
    const request = await this.createRequest(`/${String(this.authCollection)}/login`, {
      method: 'POST',
      body: JSON.stringify({ email, password }),
    });
    const response = await this.handleResponse(await this.fetcher(request));

    const data = await response.json();
    this.handleLoginResponse(data);
    return data;
  }

  private handleLoginResponse(data: { token?: string }) {
    if (data.token) {
      this.token = data.token;
      localStorage.setItem('jwtToken', data.token);
      this.scheduleTokenRefresh();
    }
  }

  async logout(): Promise<void> {
    const request = await this.createRequest(`/${String(this.authCollection)}/logout`, {
      method: 'POST',
    });
    await this.handleResponse(await this.fetcher(request));
    this.clearAuthData();
  }

  async forgotPassword({ email }: { email: string }): Promise<void> {
    const request = await this.createRequest(`/${String(this.authCollection)}/forgot-password`, {
      method: 'POST',
      body: JSON.stringify({ email }),
    });
    await this.handleResponse(await this.fetcher(request));
  }

  async resetPassword({ token, password }: { token: string; password: string }): Promise<{
    user: C['collections'][];
    token: string;
    exp: number;
  }> {
    const request = await this.createRequest(`/${String(this.authCollection)}/reset-password`, {
      method: 'POST',
      body: JSON.stringify({ token, password }),
    });
    const response = await this.handleResponse(await this.fetcher(request));

    return response.json();
  }

  async verifyEmail({ token }: { token: string }): Promise<void> {
    const request = await this.createRequest(`/${String(this.authCollection)}/verify/${token}`, {
      method: 'POST',
    });
    await this.handleResponse(await this.fetcher(request));
  }

  async unlock(): Promise<void> {
    const request = await this.createRequest(`/${String(this.authCollection)}/unlock`, {
      method: 'POST',
    });
    await this.handleResponse(await this.fetcher(request));
  }

  async access(): Promise<{
    canAccessAdmin: boolean;
    collections: Record<
      string,
      {
        create: { permission: boolean };
        read: { permission: boolean };
        update: { permission: boolean };
        delete: { permission: boolean };
        fields: Record<
          string,
          {
            create: { permission: boolean };
            read: { permission: boolean };
            update: { permission: boolean };
          }
        >;
      }
    >;
  }> {
    const request = await this.createRequest('/access', {
      method: 'GET',
    });
    const response = await this.handleResponse(await this.fetcher(request));
    return response.json();
  }

  async me(): Promise<{
    user: C['collections']['users'];
    token: string;
    exp: number;
  } | null> {
    const request = await this.createRequest(`/${String(this.authCollection)}/me`, {
      method: 'GET',
    });
    const response = await this.handleResponse(await this.fetcher(request));

    const data = await response.json();
    this.handleLoginResponse(data);

    return data;
  }

  async create<T extends keyof C['collections']>({
    collection,
    data,
    file,
    ...toQs
  }: {
    collection: T;
    data: Partial<C['collections'][T]>;
    file?: File;
    depth?: number;
    draft?: boolean;
    fallbackLocale?: C['locale'];
    locale?: C['locale'];
  }): Promise<OperationResult<C['collections'][T]>> {
    const qs = buildQueryString(toQs);

    const request = await this.createRequest(`/${collection.toString()}${qs}`, {
      method: 'POST',
      body: JSON.stringify(data),
      file,
    });
    const response = await this.handleResponse(await this.fetcher(request));

    return response.json();
  }

  async delete<T extends keyof C['collections']>({
    collection,
    ...toQs
  }: {
    collection: T;
    depth?: number;
    draft?: boolean;
    fallbackLocale?: C['locale'];
    locale?: C['locale'];
    where: Where;
  }): Promise<BulkOperationResult<C['collections'][T]>> {
    const qs = buildQueryString(toQs);

    const request = await this.createRequest(`/${collection.toString()}${qs}`, {
      method: 'DELETE',
    });
    const response = await this.handleResponse(await this.fetcher(request));

    return response.json();
  }

  async deleteById<T extends keyof C['collections']>({
    collection,
    id,
    ...toQs
  }: {
    collection: T;
    depth?: number;
    draft?: boolean;
    fallbackLocale?: C['locale'];
    id: C['collections'][T]['id'];
    locale?: C['locale'];
  }): Promise<C['collections'][T]> {
    const qs = buildQueryString(toQs);

    const request = await this.createRequest(`/${collection.toString()}/${id}${qs}`, {
      method: 'DELETE',
    });
    const response = await this.handleResponse(await this.fetcher(request));

    const result = (await response.json()) as OperationResult<C['collections'][T]>;
    return result.doc;
  }

  async find<T extends keyof C['collections'], K extends (keyof C['collections'][T])[]>({
    collection,
    ...toQs
  }: {
    collection: T;
    depth?: number;
    draft?: boolean;
    fallbackLocale?: C['locale'];
    limit?: number;
    locale?: 'all' | C['locale'];
    page?: number;
    select?: K;
    sort?: `-${Exclude<keyof C['collections'][T], symbol>}` | keyof C['collections'][T];
    where?: Where;
  }): Promise<PaginatedDocs<K extends undefined ? C['collections'][T] : Pick<C['collections'][T], K[0]>>> {
    const qs = buildQueryString(toQs);

    const request = await this.createRequest(`/${collection.toString()}${qs}`);
    const response = await this.handleResponse(await this.fetcher(request));

    return response.json();
  }

  async findById<T extends keyof C['collections'], K extends (keyof C['collections'][T])[]>({
    collection,
    id,
    ...toQs
  }: {
    collection: T;
    depth?: number;
    draft?: boolean;
    fallbackLocale?: C['locale'];
    id: C['collections'][T]['id'];
    locale?: 'all' | C['locale'];
    select?: K;
  }): Promise<K extends undefined ? C['collections'][T] : Pick<C['collections'][T], K[0]>> {
    const qs = buildQueryString(toQs);

    const request = await this.createRequest(`/${collection.toString()}/${id}${qs}`);
    const response = await this.handleResponse(await this.fetcher(request));

    return response.json();
  }

  async findGlobal<T extends keyof C['globals'], K extends (keyof C['globals'][T])[]>({
    slug,
    ...toQs
  }: {
    depth?: number;
    fallbackLocale?: C['locale'];
    locale?: 'all' | C['locale'];
    select?: K;
    slug: T;
  }): Promise<K extends undefined ? C['globals'][T] : Pick<C['globals'][T], K[0]>> {
    const qs = buildQueryString(toQs);

    const request = await this.createRequest(`/globals/${slug.toString()}${qs}`);
    const response = await this.handleResponse(await this.fetcher(request));

    return response.json();
  }

  getApiURL() {
    return this.apiURL;
  }

  getFetcher() {
    return this.fetcher;
  }

  async update<T extends keyof C['collections']>({
    collection,
    data,
    file,
    ...toQs
  }: {
    collection: T;
    data: DeepPartial<C['collections'][T]>;
    file?: File;
    depth?: number;
    draft?: boolean;
    fallbackLocale?: C['locale'];
    id: C['collections'][T]['id'];
    locale?: C['locale'];
    where?: Where;
  }): Promise<BulkOperationResult<C['collections'][T]>> {
    const qs = buildQueryString(toQs);

    const request = await this.createRequest(`/${collection.toString()}${qs}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      file,
    });
    const response = await this.handleResponse(await this.fetcher(request));

    return response.json();
  }

  async updateById<T extends keyof C['collections']>({
    collection,
    id,
    data,
    file,
    ...toQs
  }: {
    collection: T;
    id: string;
    data: DeepPartial<C['collections'][T]>;
    file?: File;
    depth?: number;
    draft?: boolean;
    fallbackLocale?: C['locale'];
    locale?: C['locale'];
  }): Promise<C['collections'][T]> {
    const qs = buildQueryString(toQs);

    const request = await this.createRequest(`/${collection.toString()}/${id}${qs}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      file,
    });
    const response = await this.handleResponse(await this.fetcher(request));

    const result = (await response.json()) as OperationResult<C['collections'][T]>;
    return result.doc;
  }

  async updateGlobal<T extends keyof C['globals']>({
    data,
    slug,
    ...toQs
  }: {
    data: DeepPartial<C['globals'][T]>;
    depth?: number;
    fallbackLocale?: C['locale'];
    locale?: C['locale'];
    slug: T;
  }): Promise<C['globals'][T]> {
    const qs = buildQueryString(toQs);

    const request = await this.createRequest(`/globals/${slug.toString()}${qs}`, {
      body: JSON.stringify(data),
      method: 'POST',
    });
    const response = await this.handleResponse(await this.fetcher(request));

    return response.json();
  }

  async customRequest<T>({
    subpath,
    method,
    data,
    ...toQs
  }: {
    subpath: string;
    method: 'GET' | 'POST' | 'PATCH' | 'DELETE';
    data?: Record<string, unknown>;
    depth?: number;
    draft?: boolean;
    fallbackLocale?: C['locale'];
    locale?: C['locale'];
  }): Promise<T> {
    const qs = buildQueryString(toQs);

    const requestInit: RequestInit = {
      method,
    };

    if (data && (method === 'POST' || method === 'PATCH')) {
      requestInit.body = JSON.stringify(data);
    }

    const request = await this.createRequest(`${subpath}${qs}`, requestInit);
    const response = await this.handleResponse(await this.fetcher(request));

    return response.json();
  }
}

export function buildQueryString(args: Record<string, unknown> | undefined) {
  if (!args) return '';

  if (args['fallbackLocale']) {
    args['fallback-locale'] = args['fallbackLocale'];
    delete args['fallbackLocale'];
  }

  return queryString.stringify(args, { addQueryPrefix: true });
}
