import { type FieldType, type JsonSchemaType, fieldType } from '@johanniter-offshore/types';
import {
  Button,
  Checkbox,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  cn,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { ArrowDown, ArrowUp, Plus, Trash } from 'lucide-react';
import { nanoid } from 'nanoid';
import React from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

export const metadataFieldSchema = z.object({
  key: z.string().min(1),
  label: z.string().min(1),
  type: z.enum(fieldType),
  required: z.boolean(),
});

export type MetadataFormField = z.infer<typeof metadataFieldSchema>;

export const formSchema = z.object({
  metadata: z.array(metadataFieldSchema),
});

export type FormSchema = z.infer<typeof formSchema>;

const newField = (type: FieldType = 'string'): MetadataFormField => ({
  key: `tempkey-${Date.now()}`,
  label: '',
  type,
  required: false,
});

const toCamelCase = (str: string): string => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '')
    .replace(/[^a-zA-Z0-9]/g, '');
};

interface MetadataBuilderProps {
  disabled?: boolean;
}

export const jsonSchemaToFormFields = (jsonSchema: JsonSchemaType): MetadataFormField[] => {
  return jsonSchema.propertyOrder.map((key) => ({
    key,
    label: jsonSchema.properties[key].description,
    type:
      jsonSchema.properties[key].type === 'string' && jsonSchema.properties[key].format === 'date-time'
        ? 'date'
        : jsonSchema.properties[key].type,
    required: jsonSchema.required.includes(key),
  }));
};

export const formFieldsToJsonSchema = (fields: MetadataFormField[]): JsonSchemaType => {
  const properties: JsonSchemaType['properties'] = {};
  const required: string[] = [];
  const propertyOrder: string[] = [];

  fields.forEach((field) => {
    let key = field.key;
    if (key.startsWith('tempkey-')) {
      key = `${toCamelCase(field.label)}-${nanoid()}`;
    }
    properties[key] =
      field.type === 'date'
        ? {
            type: 'string',
            format: 'date-time',
            description: field.label,
          }
        : {
            type: field.type,
            description: field.label,
          };
    if (field.required) {
      required.push(key);
    }
    propertyOrder.push(key);
  });

  return {
    type: 'object',
    properties,
    required,
    propertyOrder,
  };
};

export function handleMetadataValidationErrors(
  error: unknown,
  metadata: FormSchema['metadata'],
  jsonSchema: JsonSchemaType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>,
  t: (key: string) => string,
) {
  const payloadError = error as {
    statusCode?: number;
    response?: {
      errors?: Array<{
        name: string;
        data?: {
          errors?: Array<{
            code: string;
            field: string;
            message: string;
            details?: { fields: string[] };
          }>;
        };
        message: string;
      }>;
    };
    name: string;
  };

  if (payloadError.response?.errors) {
    payloadError.response.errors.forEach((err) => {
      if (err.name === 'APIError' && err.data?.errors) {
        err.data.errors.forEach((apiError) => {
          switch (apiError.code) {
            case 'NewRequiredFieldAdditionNotAllowed':
            case 'ExistingFieldMadeRequiredWithMissingValues':
              apiError.details?.fields.forEach((field) => {
                const fieldIndex = metadata.findIndex(
                  (metadataField) =>
                    metadataField.key === field ||
                    (metadataField.key.startsWith('tempkey-') &&
                      jsonSchema.propertyOrder.includes(field) &&
                      jsonSchema.properties[field].description === metadataField.label),
                );
                if (fieldIndex !== -1) {
                  form.setError(`metadata.${fieldIndex}.required`, {
                    type: 'manual',
                    message: t('fieldMustBeOptional'),
                  });
                }
              });
              break;
            case 'FieldTypeChangeNotAllowed': {
              const fieldIndex = metadata.findIndex(
                (metadataField) =>
                  metadataField.key === apiError.field ||
                  (metadataField.key.startsWith('tempkey-') &&
                    jsonSchema.propertyOrder.includes(apiError.field) &&
                    jsonSchema.properties[apiError.field].description === metadataField.label),
              );
              if (fieldIndex !== -1) {
                form.setError(`metadata.${fieldIndex}.type`, {
                  type: 'manual',
                  message: t('fieldTypeChangeNotAllowed'),
                });
              }
              break;
            }
            default:
              toast.error(t('metadataUpdateFailed'), {
                description: apiError.message,
              });
          }
        });
      } else {
        toast.error(t('unknownError'), {
          description: err.message,
        });
      }
    });
  } else if (payloadError.name === 'PayloadApiError') {
    toast.error(t('metadataUpdateFailed'), {
      description: payloadError.response?.errors?.[0]?.message || t('unknownError'),
    });
  } else {
    toast.error(t('unknownError'));
  }
}

export function MetadataBuilder({ disabled = false }: MetadataBuilderProps) {
  const { t } = useIntl();
  const { control } = useFormContext<FormSchema>();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'metadata',
  });

  const fieldTypes: { value: FieldType; label: string }[] = [
    { value: 'string', label: t('string') },
    { value: 'number', label: t('number') },
    { value: 'boolean', label: t('boolean') },
    { value: 'date', label: t('date') },
  ];

  const metadata = useWatch({ control, name: 'metadata' });

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[120px]">{t('move')}</TableHead>
            <TableHead>{t('label')}</TableHead>
            <TableHead className="w-[185px]">{t('type')}</TableHead>
            <TableHead className="w-[50px]">{t('required')}</TableHead>
            <TableHead className="w-[50px]">{t('delete')}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <TableRow>
                <TableCell className="w-[120px] align-top">
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => move(index, Math.max(0, index - 1))}
                    disabled={disabled || index === 0}
                    className="size-9"
                    type="button"
                    aria-label={t('moveUp')}
                  >
                    <ArrowUp className="size-4" />
                  </Button>
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => move(index, Math.min(fields.length - 1, index + 1))}
                    disabled={disabled || index === fields.length - 1}
                    className="size-9"
                    type="button"
                    aria-label={t('moveDown')}
                  >
                    <ArrowDown className="size-4" />
                  </Button>
                </TableCell>
                <TableCell className="align-top">
                  <FormField
                    control={control}
                    name={`metadata.${index}.label`}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input {...field} className="h-9" disabled={disabled} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TableCell>
                <TableCell className="w-[150px] align-top">
                  <FormField
                    control={control}
                    name={`metadata.${index}.type`}
                    render={({ field }) => (
                      <FormItem>
                        <Select onValueChange={field.onChange} defaultValue={field.value} disabled={disabled}>
                          <FormControl>
                            <SelectTrigger className="h-9 w-[150px]">
                              <SelectValue placeholder={t('selectFieldType')} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {fieldTypes.map((type) => (
                              <SelectItem key={type.value} value={type.value}>
                                {type.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TableCell>
                <TableCell className="w-[150px] align-top">
                  <FormField
                    control={control}
                    name={`metadata.${index}.required`}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="flex size-9 items-center justify-center">
                            <Checkbox
                              className="data-[state=checked]:text-background data-[state=checked]:bg-foreground  border-inherit"
                              checked={field.value}
                              onCheckedChange={field.onChange}
                              disabled={disabled}
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TableCell>
                <TableCell className=" align-top">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => remove(index)}
                    className="size-9"
                    type="button"
                    aria-label={t('delete')}
                    disabled={disabled}
                  >
                    <Trash className="size-4 " />
                  </Button>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <div className="flex">
        <Button
          variant="ghost"
          size="icon"
          onClick={() => append(newField())}
          type="button"
          className={cn('w-full', metadata.length <= 0 && 'mt-2')}
          aria-label={t('addField')}
          disabled={disabled}
        >
          <Plus className="size-4" />
        </Button>
      </div>
    </>
  );
}
