import type { RouteProp } from '@/types/routeProp';
import { Button, Sheet, SheetContent, SheetTrigger } from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { PanelLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Logo } from '../layout/Logo';

interface MobileMenuProps {
  routes: RouteProp[];
}

export const MobileMenu = ({ routes }: MobileMenuProps) => {
  const { t } = useIntl();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button size="icon" variant="outline" className="sm:hidden">
          <PanelLeft className="size-5" />
          <span className="sr-only">{t('toggleNavigationMenu')}</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="sm:max-w-xs">
        <nav className="grid gap-6 text-lg font-medium">
          <Logo />
          {routes.map((route) => {
            const isActive = location.pathname === route.to;
            return (
              <Link
                key={route.to}
                to={route.to}
                className={`flex items-center gap-4 px-2.5 ${
                  isActive
                    ? 'text-accent-foreground hover:text-foreground'
                    : 'text-muted-foreground hover:text-foreground'
                }`}
              >
                <route.icon className="size-5" />
                {t(route.label)}
              </Link>
            );
          })}
        </nav>
      </SheetContent>
    </Sheet>
  );
};
