import { FileUploadCard } from '@/components/shared/FileUploadCard';
import type { ArticleFilesFormValues } from '@/routes/application/juh/articles/CreateArticle';
import { useIntl } from '@tiny-intl/react';
import { useFormContext } from 'react-hook-form';

interface ArticleFilesProps {
  disabled?: boolean;
}

export function ArticleFilesCard({ disabled = false }: ArticleFilesProps) {
  const { t } = useIntl();
  useFormContext<ArticleFilesFormValues>();

  return (
    <FileUploadCard
      disabled={disabled}
      title={t('articleFiles')}
      description={t('articleFilesDescription')}
      fieldArrayName="articleFiles"
    />
  );
}
