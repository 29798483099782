import { useAuth } from '@/utils/hooks/useAuth';
import type { Invite, User } from '@johanniter-offshore/types';
import { Badge, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';

import { UserTableActions } from './UserTableActions';

interface UserTableProps {
  users: (User | (Invite & { isInvite: true }))[];
  onEdit: (user: User | (Invite & { isInvite: true })) => void;
  onDelete: (user: User | (Invite & { isInvite: true })) => void;
  onResend: (user: User | (Invite & { isInvite: true })) => void;
}

export const UserTable = ({ users, onEdit, onDelete, onResend }: UserTableProps) => {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();

  const onlyReadPermissions = currentUser?.role === 'customer-employee' || currentUser?.role === 'juh-employee';

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t('name')}</TableHead>
          <TableHead>{t('email')}</TableHead>
          <TableHead>{t('role')}</TableHead>
          <TableHead>{t('status')}</TableHead>
          {!onlyReadPermissions && (
            <TableHead className="text-right">
              <span className="sr-only">{t('actions')}</span>
            </TableHead>
          )}
        </TableRow>
      </TableHeader>
      <TableBody>
        {users.map((user) => (
          <TableRow key={user.id}>
            <TableCell className="truncate">{`${user.firstName} ${user.lastName}`}</TableCell>
            <TableCell className="max-w-[200px] truncate">{user.email}</TableCell>
            <TableCell className="">
              <Badge variant={user.role === 'customer-admin' ? 'default' : 'secondary'}>
                {t(`portalRoles.${user.role}`)}
              </Badge>
            </TableCell>
            <TableCell>
              {'isInvite' in user && user.isInvite ? (
                <Badge variant="outline">{t('invited')}</Badge>
              ) : (
                <Badge>{t('active')}</Badge>
              )}
            </TableCell>
            {!onlyReadPermissions && (
              <TableCell className="text-right">
                <UserTableActions
                  onEdit={() => onEdit(user)}
                  onDelete={() => onDelete(user)}
                  onResend={() => onResend(user)}
                  isInvite={'isInvite' in user && user.isInvite}
                />
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
