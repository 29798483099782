import { useArticles, useDeleteArticle } from '@/api/articles';
import { useOrganization } from '@/api/organizations';
import { useProduct } from '@/api/products';
import { useStation } from '@/api/stations';
import { SearchCombobox } from '@/components/juh/inputs/SearchCombobox';
import { FormatedInterval } from '@/components/shared/format/Intervals';
import { SearchField } from '@/components/shared/input/SearchField';
import { HeaderPortal } from '@/components/shared/layout/Header';
import { DataTablePagination } from '@/components/shared/table/DataTableNavigation';
import { useAuth } from '@/utils';
import { useDebounce } from '@/utils/hooks/useDebounce';
import type { Article } from '@johanniter-offshore/types';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tabs,
  TabsList,
  TabsTrigger,
} from '@johanniter-offshore/ui';
import type { ColumnDef, ColumnFiltersState, SortingState, VisibilityState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useIntl } from '@tiny-intl/react';
import { ListFilter, MoreHorizontal, PlusCircle } from 'lucide-react';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const ProductCell = ({ productId }: { productId: string }) => {
  const { data: product, isLoading } = useProduct(productId);
  if (isLoading) return <Skeleton className="h-4 w-24" />;
  return <div>{product?.name || '-'}</div>;
};

const OrganizationCell = ({ organizationId }: { organizationId: string }) => {
  const { data: organization, isLoading } = useOrganization(organizationId);
  if (isLoading) return <Skeleton className="h-4 w-24" />;
  return <div>{organization?.name || '-'}</div>;
};

const StationCell = ({ stationId }: { stationId: string }) => {
  const { data: station, isLoading } = useStation(stationId);
  if (isLoading) return <Skeleton className="h-4 w-24" />;
  return <div>{station?.name || '-'}</div>;
};

const ActionCell = ({ article, onDelete }: { article: Article; onDelete: (article: Article) => void }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useIntl();
  const isJuhAdmin = user?.role === 'juh-admin';

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="size-8 p-0">
          <span className="sr-only">{t('openMenu')}</span>
          <MoreHorizontal className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('actions')}</DropdownMenuLabel>
        <DropdownMenuItem onClick={() => navigate(`/juh/articles/${article.id}`)}>{t('edit')}</DropdownMenuItem>
        <DropdownMenuItem disabled={!isJuhAdmin} onClick={() => onDelete(article)}>
          {t('delete')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const ArticleOverview = () => {
  const { t } = useIntl();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'all' | 'container' | 'single'>('all');
  const [selectedProductId, setSelectedProductId] = useState<string | undefined>(undefined);

  const columns: ColumnDef<Article>[] = [
    {
      accessorKey: 'product',
      header: t('product'),
      cell: ({ row }) => <ProductCell productId={row.getValue('product') as string} />,
    },
    {
      accessorKey: 'expiryDate',
      header: t('expiryDate'),
      cell: ({ row }) => {
        const expiryDate = row.getValue('expiryDate') as string | null;
        if (!expiryDate) return <div>-</div>;
        const date = DateTime.fromISO(expiryDate);
        return <div>{date.toFormat('dd.MM.yyyy')}</div>;
      },
    },
    {
      accessorKey: 'testInterval',
      header: t('testInterval'),
      cell: ({ row }) => {
        const testInterval = row.getValue('testInterval') as string;
        if (!testInterval) return <div>-</div>;
        return <FormatedInterval interval={testInterval} prefixFormat="every" />;
      },
    },
    {
      accessorKey: 'lastTestDate',
      header: t('lastTestDate'),
      cell: ({ row }) => {
        const lastTestDate = row.getValue('lastTestDate') as string | null;
        if (!lastTestDate) return <div>-</div>;
        const date = DateTime.fromISO(lastTestDate);
        return <div>{date.toFormat('dd.MM.yyyy')}</div>;
      },
    },
    {
      accessorKey: 'organization',
      header: t('organization'),
      cell: ({ row }) => <OrganizationCell organizationId={row.getValue('organization') as string} />,
    },
    {
      accessorKey: 'station',
      header: t('station'),
      cell: ({ row }) => <StationCell stationId={row.getValue('station') as string} />,
    },
    {
      id: 'actions',
      cell: ({ row }) => <ActionCell article={row.original} onDelete={setArticleToDelete} />,
    },
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const handleSearchChange = useCallback((value: string) => {
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, pageIndex: 0 })); // Reset to first page on search
  }, []);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: articlesData, isLoading } = useArticles({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    where: {
      ...(activeTab === 'all'
        ? {}
        : {
            'product.isContainer': {
              equals: activeTab === 'container',
            },
          }),
      ...(selectedProductId
        ? {
            product: {
              equals: selectedProductId,
            },
          }
        : {}),
      ...(debouncedSearchTerm
        ? {
            or: [
              { 'product.name': { contains: debouncedSearchTerm } },
              { 'product.productCategory.name': { contains: debouncedSearchTerm } },
              { 'organization.name': { contains: debouncedSearchTerm } },
              { 'station.name': { contains: debouncedSearchTerm } },
            ],
          }
        : {}),
    },
  });

  const articles = articlesData?.docs || [];
  const totalPages = articlesData?.totalPages || 0;

  const table = useReactTable({
    data: articles,
    columns,
    pageCount: totalPages,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const deleteArticle = useDeleteArticle();
  const [articleToDelete, setArticleToDelete] = useState<Article | null>(null);

  const confirmDeleteArticle = async () => {
    if (articleToDelete) {
      try {
        await deleteArticle.mutateAsync(articleToDelete.id);
        toast.success(t('articleDeletedSuccessfully'));
        setArticleToDelete(null);
      } catch (error) {
        toast.error(t('errorDeletingArticle'));
      }
    }
  };

  return (
    <>
      <HeaderPortal>
        <SearchField searchTerm={searchTerm} setSearchTerm={handleSearchChange} />
      </HeaderPortal>
      <div>
        <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value as 'all' | 'container' | 'single')}>
          <div className="flex items-center">
            <TabsList>
              <TabsTrigger value="all">{t('allArticles')}</TabsTrigger>
              <TabsTrigger value="container">{t('containerArticles')}</TabsTrigger>
              <TabsTrigger value="single">{t('singleArticles')}</TabsTrigger>
            </TabsList>
            <div className="ml-auto flex items-center gap-2">
              <SearchCombobox
                value={selectedProductId}
                onSelect={(value) => setSelectedProductId(value || undefined)}
                collectionKey="products"
                searchKey="name"
                className="h-8 min-w-[280px]"
                texts={{ selectItemMsg: t('filterByProduct') }}
                icon={ListFilter}
              />

              <Button size="sm" className="h-8 gap-1" onClick={() => navigate('/juh/articles/new')}>
                <PlusCircle className="size-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('addArticle')}</span>
              </Button>
            </div>
          </div>
        </Tabs>
        <Card className="mt-2">
          <CardHeader>
            <CardTitle>{t('stock')}</CardTitle>
            <CardDescription>{t('manageStock')}</CardDescription>
          </CardHeader>
          <CardContent>
            <div>
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableHead key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHead>
                      ))}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={columns.length} className="h-24 text-center">
                        {t('loading')}
                      </TableCell>
                    </TableRow>
                  ) : table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} className="h-24 text-center">
                        {t('noResults')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </CardContent>
          <CardFooter>
            <DataTablePagination table={table} />
          </CardFooter>
        </Card>
      </div>

      <AlertDialog open={!!articleToDelete} onOpenChange={(open) => !open && setArticleToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('deleteArticle')}</AlertDialogTitle>
            <AlertDialogDescription>{t('deleteArticleConfirmation')}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t('cancel')}</AlertDialogCancel>
            <AlertDialogAction onClick={confirmDeleteArticle}>{t('delete')}</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export { ArticleOverview };
