import type { Station } from '@johanniter-offshore/types';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';

import { StationTableActions } from './StationTableActions';

interface StationTableProps {
  stations: Station[];
  onEdit: (station: Station) => void;
  onDelete: (station: Station) => void;
}

export const StationTable = ({ stations, onEdit, onDelete }: StationTableProps) => {
  const { t } = useIntl();

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t('name')}</TableHead>
          <TableHead>{t('description')}</TableHead>
          <TableHead className="text-right">
            <span className="sr-only">{t('actions')}</span>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {stations.map((station) => (
          <TableRow key={station.id}>
            <TableCell className="truncate">{station.name}</TableCell>
            <TableCell className="max-w-[200px] truncate">{station.description}</TableCell>
            <TableCell className="text-right">
              <StationTableActions onEdit={() => onEdit(station)} onDelete={() => onDelete(station)} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
