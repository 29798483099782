import { Calendar as CalendarIcon, X } from 'lucide-react';
import { DateTime } from 'luxon';
import { forwardRef } from 'react';

import { cn } from '../lib/utils';
import { Button } from './button';
import { Calendar } from './calendar';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

export const DatePicker = forwardRef<
  HTMLDivElement,
  {
    date?: Date | null;
    setDate: (date?: Date | null) => void;
    placeholder?: string;
    disabled?: boolean;
  }
>(function DatePickerCmp({ date, setDate, placeholder = 'Pick a date', disabled = false }, ref) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          // eslint-disable-next-line readable-tailwind/multiline
          className={cn('w-full justify-start px-3 text-left font-normal', !date && 'text-muted-foreground')}
          disabled={disabled}
        >
          <CalendarIcon className="mr-2 size-4" />
          {date ? DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED) : <span>{placeholder}</span>}
          {date && (
            <X
              className={`
                ml-auto size-4 opacity-50

                hover:opacity-100
              `}
              onClick={(e) => {
                e.stopPropagation();
                setDate(null);
              }}
            />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" ref={ref}>
        <Calendar mode="single" selected={date || undefined} onSelect={setDate} initialFocus />
      </PopoverContent>
    </Popover>
  );
});
