import { useCurrentUserOrganization, useUpdateOrganization } from '@/api/organizations';
import { useAuth } from '@/utils/hooks/useAuth';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

const organizationFormSchema = z.object({
  name: z.string().min(1),
});

type OrganizationFormValues = z.infer<typeof organizationFormSchema>;

export function Organization() {
  const { t } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const { data: organization } = useCurrentUserOrganization();
  const updateOrganization = useUpdateOrganization();
  const { user: currentUser } = useAuth();

  const form = useForm<OrganizationFormValues>({
    resolver: zodResolver(organizationFormSchema),
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    if (organization) {
      form.reset({
        name: organization.name,
      });
    }
  }, [organization, form]);

  const handleSubmit = async (data: OrganizationFormValues) => {
    if (!organization || currentUser?.role === 'customer-employee') return;
    setIsLoading(true);
    try {
      await updateOrganization.mutateAsync({ id: organization.id, data });
      toast.success(t('organizationUpdatedSuccessfully'));
    } catch (error) {
      form.setError('root', { type: 'manual', message: t('unknownError') });
    } finally {
      setIsLoading(false);
    }
  };

  const isCustomerEmployee = currentUser?.role === 'customer-employee';

  return (
    <div className="grid gap-6">
      <Card>
        <CardHeader>
          <CardTitle>{t('organization')}</CardTitle>
          <CardDescription>{t('editOrganizationDescription')}</CardDescription>
        </CardHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <CardContent>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('name')}</FormLabel>
                    <FormControl>
                      <Input {...field} disabled={isCustomerEmployee} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
            <CardFooter className="border-t px-6 py-4">
              <Button type="submit" disabled={isLoading || isCustomerEmployee}>
                {isLoading ? t('saving') : t('save')}
              </Button>
            </CardFooter>
          </form>
        </Form>
      </Card>
    </div>
  );
}
