import { Tooltip, TooltipContent, TooltipTrigger } from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import type { LucideIcon } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

interface SidebarLinkProps {
  to: string;
  icon: LucideIcon;
  label: string;
}

export const SidebarLink = ({ to, icon: Icon, label }: SidebarLinkProps) => {
  const { t } = useIntl();
  const location = useLocation();

  const isActive = location.pathname.startsWith(to);

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Link
          to={to}
          className={`flex size-9 items-center justify-center rounded-lg transition-colors md:size-8 ${
            isActive
              ? 'bg-accent text-accent-foreground hover:text-foreground'
              : 'text-muted-foreground hover:text-foreground'
          }`}
        >
          <Icon className="size-5" />
          <span className="sr-only">{t(label)}</span>
        </Link>
      </TooltipTrigger>
      <TooltipContent side="right">{t(label)}</TooltipContent>
    </Tooltip>
  );
};
