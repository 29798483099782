import type { JsonSchemaType, Product } from '@johanniter-offshore/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { payloadClient } from '.';
import type { Where } from './types';

export const useProducts = (params?: {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof Product | `-${keyof Product}`;
  disabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['products', params],
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'products',
        ...params,
      });
      return response;
    },
  });
};

export const useProduct = (id: string, params?: { disabled?: boolean }) => {
  return useQuery({
    queryKey: ['product', id],
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.findById({
        collection: 'products',
        id,
      });
      return response;
    },
  });
};

export const useCreateProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<Product>) => {
      const response = await payloadClient.create({
        collection: 'products',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['products'] });
    },
  });
};

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<Product> }) => {
      const response = await payloadClient.updateById({
        collection: 'products',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['products'] });
    },
  });
};

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const response = await payloadClient.deleteById({
        collection: 'products',
        id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['products'] });
    },
  });
};

export const useResolvedProductMetadata = (productId?: string) => {
  return useQuery({
    queryKey: ['resolvedProductMetadata', productId],
    queryFn: async () => {
      const response = await payloadClient.customRequest<{ metadata: JsonSchemaType }>({
        subpath: `/products/${productId}/resolved-metadata`,
        method: 'GET',
      });
      return response.metadata;
    },
    enabled: !!productId,
  });
};
