import { MetadataAutoForm } from '@/components/shared/metadata/MetadataAutoForm';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@johanniter-offshore/ui';
import { useIntl } from '@tiny-intl/react';
import type { UseFormReturn } from 'react-hook-form';
import type { z } from 'zod';

type ArticleMetadataCardProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: z.ZodObject<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
};

export const ArticleMetadataCard = ({ schema, form }: ArticleMetadataCardProps) => {
  const { t } = useIntl();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('articleMetadata')}</CardTitle>
        <CardDescription>{t('articleMetadataDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <MetadataAutoForm schema={schema} form={form} />
      </CardContent>
    </Card>
  );
};
